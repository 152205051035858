export interface IGraphqlState {
  errorVideoUpdate: string
  doUpdateVideo: boolean
  file: File | null
}

export enum GraphqlType {
  CHANGE_ERROR_VIDEO_UPDATE = 'CHANGE_ERROR_VIDEO_UPDATE',
  CHANGE_DO_UPDATE_VIDEO = 'CHANGE_DO_UPDATE_VIDEO',
  CHANGE_FILE_UPLOAD = 'CHANGE_FILE_UPLOAD',
}

export interface IChangeErrorVideoUpdate {
  type: GraphqlType.CHANGE_ERROR_VIDEO_UPDATE
  errorVideoUpdate: string
}

export interface IChangeDoUpdateVideo {
  type: GraphqlType.CHANGE_DO_UPDATE_VIDEO
  doUpdateVideo: boolean
}

export interface IChangeFileUpload {
  type: GraphqlType.CHANGE_FILE_UPLOAD
  file: File
}

export type GraphqlAction =
  | IChangeErrorVideoUpdate
  | IChangeDoUpdateVideo
  | IChangeFileUpload
