import { useAppSelector } from '../../Store/hooks'
import ChapterSaved from './ChapterSaved'
import styles from './index.module.scss'
import React from 'react'
import { useLocation } from 'react-router-dom'

interface IChapterScrollerProps {
  setShowChapterModal?: (openChapterModal: boolean) => void
  aspectVideo?: number
  isEditable?: boolean
}

const ChapterScroller: React.FC<IChapterScrollerProps> = ({
  aspectVideo,
  isEditable,
}) => {
  const chapters = useAppSelector((state) => state.chapters.chapters)

  const location = useLocation()

  return (
    <div
      className={
        //<--
        //Условие переделать в переменную + добавить норм название чтобы понимать что делает,
        // возможно сделать и 2 переменные
        aspectVideo && aspectVideo < 1
          ? location.pathname.match('watch')
            ? `${styles.chapterScroller} ${styles.chapterScroller_watch}`
            : `${styles.chapterScroller}`
          : `${styles.chapterScroller} ${styles.chapterScroller_horizon}`
        //-->
      }
    >
      {chapters.map((chapter) => (
        <ChapterSaved
          state={chapter.state}
          key={chapter.key}
          name={chapter.name}
          time={chapter.time}
          imgURL={chapter.imgURL}
          url={chapter.url}
          id={chapter.id}
          chapterKey={chapter.key}
          aspectVideo={aspectVideo || 1}
          isEditable={isEditable}
        />
      ))}
    </div>
  )
}

export default React.memo(ChapterScroller)
