import styles from './index.module.scss'
import React from 'react'

interface ProgressBarProps {
  persent: string
}
// Не фича а просто UI => вынести в Components
const ProgressBar: React.FC<ProgressBarProps> = ({ persent }) => {
  const filler = {
    width: `${persent}%`,
  }

  return (
    <div className={styles.container}>
      <div className={`bg-primary ${styles.filter}`} style={filler}>
        <span className={styles.label}>{`${persent}%`}</span>
      </div>
    </div>
  )
}

export default ProgressBar
