import React from 'react'
import DropZone from '../../Feature/DropZone'
import ModalButton from '../../Feature/ModalButton'
import styles from './index.module.scss'

interface IUploadInputProps {
  onChange?: () => void
}

const UploadPage: React.FC<IUploadInputProps> = () => {
  const onChange = (event: { target: { value: any } }) => {
    console.log('onChange')
    console.log(event.target.value)
  }

  return (
    <div className={styles.uploadPage}>
      <DropZone />
      <input type='file' onChange={onChange} />
      <ModalButton />
    </div>
  )
}

export default UploadPage
