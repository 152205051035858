import idSceneScroller from '../../Constants/idSceneScroller'
import checkIntoView from '../../GlobalFunctions/checkIntoView'
import {
  addScreens,
  changeCanAddedCutting,
  changeIntervalCuttingScreens,
  clearScreens,
} from '../../Store/Screens/actions'
import { IScreen } from '../../Store/Screens/types'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import placeholder from '../../images/placeholder.jpg'
import styles from './index.module.scss'
import React, { useEffect, useRef } from 'react'

interface ICuttingVideoContainerProps {
  url: string
}

const CuttingVideoContainer: React.FC<ICuttingVideoContainerProps> = ({
  url,
}) => {
  const dispatch = useAppDispatch()

  const isCuttingLowerTimeLine = useAppSelector(
    (state) => state.common.isCuttingLowerTimeLine
  )

  const isPressToCutCenterTimeLine = useAppSelector(
    (state) => state.common.isPressToCutCenterTimeLine
  )

  const nodeSceneScroller = useAppSelector(
    (state) => state.common.nodeSceneScroller
  )

  const ref = useRef<HTMLVideoElement>(null)

  // const screens = useCuttingVideoScenes(ref?.current || null, 0.25);
  // в кастомный хук засунуть весть функционал этот, чтобы было читабельней
  useEffect(() => {
    if (!isCuttingLowerTimeLine && isPressToCutCenterTimeLine) {
      const video: any = document.getElementById('videoHidden')

      const videoNoHide = document.getElementById(url)
      video.load()
      let duration: any
      video.onloadedmetadata = () => (duration = video.duration)

      // не более 500 скринов за первых проход
      //<--

      //@ts-ignore
      const firstInterval = Number(videoNoHide?.duration) / 500

      const interval = firstInterval > 0.25 ? firstInterval : 0.25

      dispatch(changeIntervalCuttingScreens(interval))

      // -->

      const { height: heightVideo, width: widthVideo } =
        videoNoHide?.getBoundingClientRect() || {}

      let int: any

      // const screens = newCutting(int, video || null, interval, height, width)

      if (!video) {
        return () => {
          clearInterval(int)

          console.log('Not found video')
        }
      } else {
        const queue: number[] = []

        let step = 0
        let stepIndex = 0

        const canvas = document.createElement('canvas')

        const { height, width } = video.getBoundingClientRect()

        console.log('size', height, width, video)
        console.log(heightVideo, widthVideo)

        // Для скрытого видео чтобы были скрины такого же размера как и у видимого видео
        if (heightVideo && widthVideo) {
          canvas.width = widthVideo
          canvas.height = heightVideo
        } else {
          canvas.width = width
          canvas.height = height
        }

        const imgs: IScreen[] = []

        console.log(imgs, video)

        const doScreen = (time: number, stepIndex: number) => {
          const context = canvas.getContext('2d')

          // Логгер для теста на остановку нарезания видео
          // console.log(
          //   'step: ' + step,
          //   'currentTime: ' + video.currentTime,
          //   'duration: ' + video.duration,
          //   'context: ',
          //   canvas,
          //   'imgs:',
          //   imgs
          // )

          // Для скрытого видео чтобы были скрины такого же размера как и у видимого видео
          if (heightVideo && widthVideo) {
            context?.drawImage(video, 0, 0, widthVideo, heightVideo)
          } else {
            context?.drawImage(video, 0, 0, width, height)
          }

          imgs[stepIndex] = {
            url: canvas.toDataURL(undefined, 0.5),
            id: Math.random().toString(32).substr(2, 12),
            time,
          }
        }

        const seeked = (e: any) => {
          doScreen(video.currentTime, stepIndex)
          step = queue.shift() || step + interval

          stepIndex = imgs.findIndex((img) => img.time === step)
          // console.log(
          //   'seeked',
          //   'step: ' + step,
          //   'duration: ' + video.duration,
          //   'step <= video.duration=' + (step <= video.duration)
          // )

          if (step <= video.duration + interval / 10) {
            video.currentTime = step
            console.log(
              'step: ',
              step,
              video.duration - (9 * interval) / 10,
              video.duration
            )
          } else {
            console.log('end newCutting')
            video.removeEventListener('seeked', seeked)
            dispatch(addScreens([...imgs]))
            clearInterval(int)
            console.log(imgs.length)
            dispatch(changeCanAddedCutting(true))

            sceneScroller.removeEventListener('scroll', scrollerListener)
          }
        }
        video.addEventListener('seeked', seeked)

        const context = canvas.getContext('2d')
        const img = document.createElement('img')
        img.src = placeholder

        if (heightVideo && widthVideo) {
          context?.drawImage(img, 0, 0, widthVideo, heightVideo)
        } else {
          context?.drawImage(img, 0, 0, width, height)
        }

        const standartImg = canvas.toDataURL(undefined, 1)

        video.addEventListener('loadeddata', function () {
          for (let i = 0; i < Math.floor(video.duration / interval); i++) {
            imgs.push({
              url: standartImg,
              id: Math.random().toString(32).substr(2, 12),
              time: i * interval,
            })
            queue.push(i * interval)
          }
          step = queue.shift() || 0
          video.currentTime = step
        })

        //@ts-ignore
        const sceneScroller: HTMLDivElement =
          document.getElementById(idSceneScroller)

        let cached: any = null
        const scrollerListener = (event: any) => {
          console.log('cached:', cached)

          if (!cached) {
            setTimeout(() => {
              //let test = [];
              sceneScroller.querySelectorAll('div').forEach((div) => {
                if (checkIntoView(div, sceneScroller)) {
                  const wholeSceneImg = div.querySelector('img')
                  console.log(wholeSceneImg, wholeSceneImg?.src === placeholder)

                  if (wholeSceneImg?.src === standartImg) {
                    // console.log(div.getAttribute('time'));
                    //test.push(div.getAttribute('time'))
                    // queueAdder(div.getAttribute('time'))
                    ;(() => {
                      const index = queue.indexOf(
                        //@ts-ignore
                        Number(wholeSceneImg.getAttribute('_time'))
                      )

                      queue.splice(index, 1)
                      //@ts-ignore
                      queue.unshift(Number(wholeSceneImg.getAttribute('_time')))
                    })()
                  }
                }
                //console.log(test)
              })
              cached = null
              console.log('cached null!')
            }, 500)
          }
          cached = event
          console.log(queue)
        }

        sceneScroller.addEventListener('scroll', scrollerListener)

        // return {
        //   imgs: imgs,
        //   removeLisner: () => {
        //     console.log('removed Seeked')

        //     video.removeEventListener('seeked', seeked)
        //   },
        // }

        int = setInterval(() => {
          dispatch(addScreens([...imgs]))
          console.log('tick')
          console.log(
            'imgs length: ',
            imgs.length,
            Math.ceil(duration / interval)
          )

          console.log(Math.round(duration / interval), imgs.length)
        }, 1000)
        return () => {
          video.removeEventListener('seeked', seeked)
          sceneScroller.removeEventListener('scroll', scrollerListener)
          dispatch(clearScreens())
          clearInterval(int)
          dispatch(changeCanAddedCutting(true))
        }
      }
    }
  }, [
    url,
    isCuttingLowerTimeLine,
    ref,
    isPressToCutCenterTimeLine,
    nodeSceneScroller,
  ])

  console.log('render cutting')

  return (
    <div className={styles.hidedVideo}>
      <video
        ref={ref}
        id='videoHidden'
        crossOrigin='anonymous'
        style={{
          width: 'auto',
          height: '100%',
        }}
        controls
      >
        <source src={url} />
      </video>
    </div>
  )
}

export default React.memo(CuttingVideoContainer)
