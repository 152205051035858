import Header from '../../Feature/Header'
import { useAppSelector } from '../../Store/hooks'
import styles from './PrivacyPolicy.module.scss'
import clsx from 'clsx'
import React from 'react'

const PrivacyPolicy = () => {
  const currentTheme = useAppSelector((state) => state.common.currentTheme)

  return (
    <div data-theme={currentTheme} className={styles.privacyPolicy}>
      <Header />

      <section className={clsx('container mx-auto px-4')}>
        <h1>Privacy Policy for FTUE.video</h1>
        <p>
          At FTUE.video, we are committed to protecting the privacy and security
          of our users. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          <a href='https://ftue.video/'>www.ftue.video</a>a. Please read this
          policy carefully to understand our practices regarding your
          information and how we will treat it.
        </p>
      </section>

      <section className={clsx('container mx-auto px-4', styles.content)}>
        <h2>Information Collection</h2>
        <p>
          We collect information from and about users of our service in various
          ways, including:
        </p>

        <ul>
          <li>
            Information You Provide: This includes information you provide when
            you register an account, update your profile, upload content, or
            communicate with us.
          </li>
          <li>
            Automatically Collected Information: When you access our website, we
            automatically collect certain information about your device and your
            use of our site, including web browser details, IP addresses, and
            usage details.
          </li>
          <li>
            Cookies and Tracking Technologies: We use cookies and similar
            tracking technologies to track activity on our site and hold certain
            information to improve and analyze our service.
          </li>
        </ul>

        <h2>Use of Information</h2>
        <p>The information we collect is used in various ways, including:</p>

        <ul>
          <li>To provide, maintain, and improve our service</li>
          <li>To manage user accounts and provide customer support</li>
          <li>To analyze how users interact with our website</li>
          <li>For research and development purposes</li>
          <li>To communicate with you about services, offers, and updates</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>
          We may share information collected from you in the following
          situations:
        </p>

        <ul>
          <li>With service providers performing services for us</li>
          <li>When required by law or to respond to legal processes</li>
          <li>
            To protect our rights, privacy, safety, or property, and/or that of
            our affiliates, you, or others
          </li>
          <li>
            In connection with a merger, sale of company assets, financing, or
            acquisition of all or a portion of our business to another company
          </li>
        </ul>

        <h2>Security of Your Information</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information but remember that no method of transmission
          over the Internet, or method of electronic storage, is 100% secure.
        </p>

        <h2>Your Privacy Rights</h2>
        <p>
          Depending on your location, you may have certain rights under
          applicable data protection laws, including the right to access,
          correct, or delete the personal information we have about you.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at
          <a className='ml-1' href='mailto:info@ftue.video'>
            info@ftue.video
          </a>
          .
        </p>
      </section>
    </div>
  )
}

export default PrivacyPolicy
