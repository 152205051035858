import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../__generated__/types'

const defaultOptions = {}
export type IVerifyTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']
}>

export type IVerifyTokenMutation = {
  __typename?: 'Mutation'
  verifyToken?: { __typename?: 'Verify'; payload: any } | null | undefined
}

export const VerifyTokenDocument = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`
export type IVerifyTokenMutationFn = Apollo.MutationFunction<
  IVerifyTokenMutation,
  IVerifyTokenMutationVariables
>

/**
 * __useVerifyTokenMutation__
 *
 * To run a mutation, you first call `useVerifyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTokenMutation, { data, loading, error }] = useVerifyTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IVerifyTokenMutation,
    IVerifyTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IVerifyTokenMutation,
    IVerifyTokenMutationVariables
  >(VerifyTokenDocument, options)
}
export type VerifyTokenMutationHookResult = ReturnType<
  typeof useVerifyTokenMutation
>
export type VerifyTokenMutationResult =
  Apollo.MutationResult<IVerifyTokenMutation>
export type VerifyTokenMutationOptions = Apollo.BaseMutationOptions<
  IVerifyTokenMutation,
  IVerifyTokenMutationVariables
>
