import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../../__generated__/types'

const defaultOptions = {}
export type ICreateUserMutationVariables = Types.Exact<{
  dryRun?: Types.Maybe<Types.Scalars['Boolean']>
  email: Types.Scalars['String']
  password: Types.Scalars['String']
}>

export type ICreateUserMutation = {
  __typename?: 'Mutation'
  createUser?:
    | { __typename?: 'CreateUserExists'; message?: string | null | undefined }
    | {
        __typename?: 'CreateUserSuccess'
        user?:
          | {
              __typename?: 'User'
              email?: string | null | undefined
              name?: string | null | undefined
              plan?: string | null | undefined
              uid?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export const CreateUserDocument = gql`
  mutation createUser(
    $dryRun: Boolean = true
    $email: String!
    $password: String!
  ) {
    createUser(dryRun: $dryRun, email: $email, password: $password) {
      ... on CreateUserSuccess {
        user {
          email
          name
          plan
          uid
        }
      }
      ... on CreateUserExists {
        message
      }
    }
  }
`
export type ICreateUserMutationFn = Apollo.MutationFunction<
  ICreateUserMutation,
  ICreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      dryRun: // value for 'dryRun'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateUserMutation,
    ICreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateUserMutation, ICreateUserMutationVariables>(
    CreateUserDocument,
    options
  )
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationResult =
  Apollo.MutationResult<ICreateUserMutation>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  ICreateUserMutation,
  ICreateUserMutationVariables
>
