export type TakeStatePlaces = 'local' | 'network'

export interface ICommonState {
  isCuttingLowerTimeLine: boolean
  isPressToCutCenterTimeLine: boolean
  prevURLFromLogin: string | null
  funcFromLogin: (() => void) | null
  whenTakeState: TakeStatePlaces
  currentTheme: string
  watchPageView: string

  //test
  nodeSceneScroller: HTMLElement | null
}

export enum CommonType {
  CHANGE_IS_CUTTING_LOWER_TIME_LINE = 'CHANGE_IS_CUTTING_LOWER_TIME_LINE',
  CHANGE_PREV_URL_FROM_LOGIN = 'CHANGE_PREV_URL_FROM_LOGIN',
  CHANGE_WHEN_TAKE_STATE = 'CHANGE_WHEN_TAKE_STATE',
  CHANGE_FUNC_FROM_LOGIN = 'CHANGE_FUNK_FROM_LOGIN',
  CHANGE_IS_PRESS_TO_CUT_CENTER_TIME_LINE = 'CHANGE_IS_PRESS_TO_CUT_CENTER_TIME_LINE',
  CHANGE_CURRENT_THEME = 'CHANGE_CURRENT_THEME',
  CHANGE_WATCH_PAGE_VIEW = 'CHANGE_WATCH_PAGE_VIEW',
  CHANGE_NODE_SCENE_SCROLLER = 'CHANGE_NODE_SCENE_SCROLLER',
}

export interface IChangeCurrentTheme {
  type: CommonType.CHANGE_CURRENT_THEME
  currentTheme: string
}

export interface IChangeWatchPageView {
  type: CommonType.CHANGE_WATCH_PAGE_VIEW
  watchPageView: string
}

export interface IChangeIsCuttingLowerTimeLine {
  type: CommonType.CHANGE_IS_CUTTING_LOWER_TIME_LINE
  isCuttingLowerTimeLine: boolean
}

export interface IChangePrevURLFromLogin {
  type: CommonType.CHANGE_PREV_URL_FROM_LOGIN
  prevURLFromLogin: string
}

export interface IChangeWhenTakeState {
  type: CommonType.CHANGE_WHEN_TAKE_STATE
  whenTakeState: TakeStatePlaces
}

export interface IChangeFunkFromLogin {
  type: CommonType.CHANGE_FUNC_FROM_LOGIN
  funcFromLogin: () => void
}

export interface IChangeIsPressToCutCenterTimeLine {
  type: CommonType.CHANGE_IS_PRESS_TO_CUT_CENTER_TIME_LINE
  isPressToCutCenterTimeLine: boolean
}

export interface IChangeNumberSceneScrollerDie {
  type: CommonType.CHANGE_NODE_SCENE_SCROLLER
  nodeSceneScroller: HTMLElement
}

export type CommonAction =
  | IChangeIsCuttingLowerTimeLine
  | IChangePrevURLFromLogin
  | IChangeWhenTakeState
  | IChangeFunkFromLogin
  | IChangeIsPressToCutCenterTimeLine
  | IChangeCurrentTheme
  | IChangeWatchPageView
  | IChangeNumberSceneScrollerDie
