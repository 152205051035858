import { CommonAction, CommonType, ICommonState } from './types'

const initialState: ICommonState = {
  isCuttingLowerTimeLine: true,
  isPressToCutCenterTimeLine: false,
  prevURLFromLogin: null,
  whenTakeState: 'network',
  funcFromLogin: null,
  currentTheme: 'light',
  watchPageView: 'first',
  nodeSceneScroller: null,
}

const commonReduser = (
  state = initialState,
  action: CommonAction
): ICommonState => {
  switch (action.type) {
    case CommonType.CHANGE_CURRENT_THEME:
      return {
        ...state,
        currentTheme: action.currentTheme,
      }
    case CommonType.CHANGE_WATCH_PAGE_VIEW:
      return {
        ...state,
        watchPageView: action.watchPageView,
      }
    case CommonType.CHANGE_IS_CUTTING_LOWER_TIME_LINE:
      return {
        ...state,
        isCuttingLowerTimeLine: action.isCuttingLowerTimeLine,
      }

    case CommonType.CHANGE_PREV_URL_FROM_LOGIN:
      return {
        ...state,
        prevURLFromLogin: action.prevURLFromLogin,
      }
    case CommonType.CHANGE_WHEN_TAKE_STATE:
      return {
        ...state,
        whenTakeState: action.whenTakeState,
      }
    case CommonType.CHANGE_FUNC_FROM_LOGIN:
      return {
        ...state,
        funcFromLogin: action.funcFromLogin,
      }
    case CommonType.CHANGE_IS_PRESS_TO_CUT_CENTER_TIME_LINE:
      return {
        ...state,
        isPressToCutCenterTimeLine: action.isPressToCutCenterTimeLine,
      }
    case CommonType.CHANGE_NODE_SCENE_SCROLLER:
      return {
        ...state,
        nodeSceneScroller: action.nodeSceneScroller,
      }
    default:
      return state
  }
}

export default commonReduser
