import inaccurateSceneWidth from '../../Constants/inaccurateSceneWidth'
import cuttingVideoScreens from '../../GlobalFunctions/cuttingVideoScreens'
import { changeIsCuttingLowerTimeLine } from '../../Store/Common/actions'
import { addMobileScreens } from '../../Store/Mobile/actions'
import { IScreen } from '../../Store/Screens/types'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import InaccurateScene from './InaccurateScene'
import styles from './index.module.scss'
import React, { useEffect, useMemo, useRef, useState } from 'react'

interface NavigationInnacurateProps {
  videoPath?: string
  isMobile?: boolean
}
// Вынести логику в кастомный хук
const NavigationInnacurate: React.FC<NavigationInnacurateProps> = ({
  videoPath,
  isMobile,
}) => {
  const dispatch = useAppDispatch()

  const screensMobile = useAppSelector((state) => state.mobile.screens)
  const durationTime = useAppSelector((state) => state.video.durationTime)

  const [scenes, setScenes] = useState<IScreen[]>(() =>
    isMobile ? screensMobile : []
  )

  const [screenWidth, setScreenWidth] = useState(window.innerWidth - 20)

  const interval = useMemo(() => {
    const value = isMobile
      ? Math.round(durationTime / 20)
      : Math.round(
          durationTime / ((window.innerWidth - 20) / inaccurateSceneWidth)
        )

    return value < 1 ? 1 : value
  }, [durationTime, inaccurateSceneWidth, window.screen.width])

  // const interval = 1

  const amountScreens = useMemo(
    () => Math.ceil(durationTime / interval),
    [durationTime, interval]
  )

  useEffect(() => {
    const windowResize = () => {
      setScreenWidth(window.innerWidth - 20)
    }
    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [screenWidth])

  useEffect(() => {
    if (videoPath && (!isMobile || screensMobile.length === 0) && !!interval) {
      const video: any = document.getElementById(videoPath || '')
      video.load()

      let forDelete: any

      const handleLoadedData = (e: Event) => {
        video.currentTime = 0.001
        const screens = cuttingVideoScreens(video || null, interval)
        const int = setInterval(() => {
          if (screens.imgs.length === amountScreens) {
            if (isMobile) dispatch(addMobileScreens(screens.imgs))
            dispatch(changeIsCuttingLowerTimeLine(false))

            console.log('clear innacurate', screens.imgs.length, amountScreens)
            video.currentTime = 0
            setScenes([...screens.imgs])
            screens.removeLisner && screens.removeLisner()
            screens.imgs = []
            clearInterval(int)
          } else {
            setScenes([...screens.imgs])
            // console.log(interval, durationTime, screens.imgs)
            // console.log(
            //   'Шаг нарезки пройден,',
            //   'Длина шага между сценами',
            //   interval,
            //   'сек',
            //   durationTime,
            //   'сек'
            // )
          }
        }, 200)
        return () => {
          screens.removeLisner && screens.removeLisner()
          screens.imgs = []
          clearInterval(int)
        }
      }

      const handleAll = (e: Event) => {
        forDelete = handleLoadedData(e)
      }

      video.addEventListener('loadeddata', handleAll)
      return () => {
        video.removeEventListener('loadeddata', handleAll)
        forDelete && forDelete()
        dispatch(changeIsCuttingLowerTimeLine(true))
      }
    }
  }, [amountScreens, videoPath])

  const sceneWidth = useMemo(() => {
    const value = screenWidth / Math.ceil(durationTime / interval)
    return value > 100 ? 100 : value
  }, [screenWidth, interval])

  return (
    <div className={styles.navigationInnacurate}>
      {scenes.map((scene, index) => (
        <InaccurateScene
          key={scene.id}
          time={index === 0 ? 0 : scene.time}
          url={scene.url}
          width={!isMobile ? sceneWidth : undefined}
        />
      ))}
    </div>
  )
}

export default React.memo(NavigationInnacurate)
