export interface IBlur {
  id: number
  startTime: number
  endTime: number
  point: {
    x: number
    y: number
  }
  width: number
  heigth: number
  // что еще?
}

export interface IVideoState {
  id: string
  url: string
  blurs: IBlur[]
  name: string
  icon: string
  editable: boolean
  website: string
  description: string
  changedTime: {
    time: number
    place?: 'WholeScene'
  }
  durationTime: number
  currentTime: number
  paused: boolean
}

export enum VideoType {
  ADD_BLUR = 'ADD_BLUR',
  DELETE_BLUR = 'DELETE_BLUR',
  ADD_VIDEO = 'ADD_VIDEO',
  CLEAR_VIDEO = 'CLEAR_VIDEO',
  CHANGED_TIME = 'CHANGED_TIME',
  CHANGE_DURATION_TIME = 'CHANGE_DURATION_TIME',
  CHANGE_CURRENT_TIME_AND_PAUSED = 'CHANGE_CURRENT_TIME_AND_PAUSED',
  CHANGE_DURATION_BLUR = 'CHANGE_DURATION_BLUR',
  CHANGE_URL_VIDEO_GQL = 'CHANGE_URL_VIDEO_GQL',
  CHANGE_ID_VIDEO = 'CHANGE_ID_VIDEO',
  CHANGE_VIDEO_NAME = 'CHANGE_VIDEO_NAME',
}

export interface IAddBlur {
  type: VideoType.ADD_BLUR
  blur: IBlur
}

export interface IDeleteBlur {
  type: VideoType.DELETE_BLUR
  id: number
}

export interface IAddVideo {
  type: VideoType.ADD_VIDEO
  video: IVideoState
}

export interface IClearVideo {
  type: VideoType.CLEAR_VIDEO
}

export interface IChangedTime {
  type: VideoType.CHANGED_TIME
  changedTime: {
    time: number
    place?: 'WholeScene'
  }
}

export interface IDurationTime {
  type: VideoType.CHANGE_DURATION_TIME
  durationTime: number
}

export interface IChangeCurrentTimeAndPaused {
  type: VideoType.CHANGE_CURRENT_TIME_AND_PAUSED
  currentTime: number
  paused: boolean
}

export interface IChangeDurationBlur {
  type: VideoType.CHANGE_DURATION_BLUR
  id: number
  time: number
}

export interface IChangeURLVideo {
  type: VideoType.CHANGE_URL_VIDEO_GQL
  url: string
}

export interface IChangeIDVideo {
  type: VideoType.CHANGE_ID_VIDEO
  id: string
}

export interface IChangeVideoName {
  type: VideoType.CHANGE_VIDEO_NAME
  name: string
}

export type VideoAction =
  | IAddBlur
  | IDeleteBlur
  | IAddVideo
  | IClearVideo
  | IChangedTime
  | IDurationTime
  | IChangeCurrentTimeAndPaused
  | IChangeDurationBlur
  | IChangeURLVideo
  | IChangeIDVideo
  | IChangeVideoName
