import { IUserState, UserAction, UserType } from './types'

const initialState: IUserState = {
  isAuth: false,
  // token: localStorage.getItem('token') || null,
  token: null,
}

export const userReduser = (
  state = initialState,
  action: UserAction
): IUserState => {
  switch (action.type) {
    case UserType.USER_SIGN_IN:
      return {
        ...state,
        isAuth: true,
        user: action.user,
      }
    // case UserType.USER_LOG_OUT:
    //   return { ...initialState }
    case UserType.USER_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    case UserType.CHANGE_USER_IS_AUTH:
      return {
        ...state,
        isAuth: true,
      }
    default:
      return state
  }
}
