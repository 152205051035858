import Button from '../../UI/Button'
import Modal from './Modal/Modal'
import styles from './index.module.scss'
import React, { useMemo } from 'react'

interface IModalButtonProps {}

const ShareModalButton: React.FC<IModalButtonProps> = ({}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const openModal = (e: React.MouseEvent) => {
    setIsModalOpen(true)
  }

  return (
    <div className={styles.modalButton}>
      <Button
        className='text-center inline-flex items-center px-6 py-2 mr-5  border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        title='Share'
        onClick={openModal}
      />
      <Modal isDisplay={isModalOpen} setOpen={setIsModalOpen} />
    </div>
  )
}

export default React.memo(ShareModalButton)
