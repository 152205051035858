import { useAppSelector } from '../../Store/hooks'
import NavigationBlur from './NavigationBlur'
import React from 'react'

const NavigationBlursContainer: React.FC = () => {
  const blurs = useAppSelector((state) => state.video.blurs)

  return (
    <>
      {blurs.map((blur) => (
        <NavigationBlur
          key={blur.id}
          id={blur.id}
          startTime={blur.startTime}
          endTime={blur.endTime}
        />
      ))}
    </>
  )
}

export default React.memo(NavigationBlursContainer)
