import { IMobileState, MobileAction, MobileType } from './types'

const initialState: IMobileState = {
  screens: [],
}

const mobileReduser = (
  state = initialState,
  action: MobileAction
): IMobileState => {
  switch (action.type) {
    case MobileType.ADD_MOBILE_SCREENS:
      return {
        screens: action.screens,
      }
    case MobileType.DELETE_MOBILE_SCREENS:
      return initialState
    default:
      return state
  }
}

export default mobileReduser
