import blurEndTimeAdd from '../../Constants/blurEndTimeAdd'
import useCreateRectangle from '../../Hooks/useCreateRectangle'
import { addBlur } from '../../Store/Video/actions'
import { useAppDispatch } from '../../Store/hooks'
import styles from './index.module.scss'
import React, { RefObject, useEffect, useRef } from 'react'

interface BlurRectangleProps {
  videoRef: RefObject<HTMLVideoElement>
}
// Компонент который ставит квадрат поверх видео, тоесть ставит блюр
const BlurRectangle: React.FC<BlurRectangleProps> = ({ videoRef }) => {
  const dispatch = useAppDispatch()

  const blurRef = useRef<HTMLDivElement>(null)

  const { startTimeBlur, size, reset, isMouseUp } = useCreateRectangle(
    blurRef.current,
    videoRef.current
  )

  useEffect(() => {
    if (isMouseUp) {
      dispatch(
        addBlur({
          id: Date.now(),
          startTime: startTimeBlur || 0,
          endTime: blurEndTimeAdd + (startTimeBlur || 0),
          point: size.startPoint,
          width: size.width,
          heigth: size.height,
        })
      )
      reset()
    }
  }, [isMouseUp])

  return <div ref={blurRef} className={styles.blur}></div>
}

export default React.memo(BlurRectangle)
