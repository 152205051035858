import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { store } from './Store/store'

// Create xhr fetch to backend, for upload file(example video or img)
// <--
const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers()
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':')
    const key = parts.shift().trim()
    if (key) {
      const value = parts.join(':').trim()
      headers.append(key, value)
    }
  })
  return headers
}

const customFetch = (url: any, options: any = {}) =>
  new Promise((resolve, reject) => {
    const xhr: any = new XMLHttpRequest()
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      }
      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL')
      const body = 'response' in xhr ? xhr.response : xhr.responseText
      resolve(new Response(body, opts))
    }

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'))
    }

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'))
    }

    xhr.open(options.method, url, true)

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key])
    })
    const token = localStorage.getItem('token')
    console.log(token)

    xhr.setRequestHeader('authorization', token ? `Bearer ${token}` : '')

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress
    }
    xhr.send(options.body)
  })

// -->

// Handling graphqlErrors and writting to console
// <--
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// -->

const client = new ApolloClient({
  link: from([
    errorLink,
    createUploadLink({
      uri: 'https://graph.ftue.video/graphql',
      fetch: (typeof window === 'undefined'
        ? global.fetch
        : customFetch) as any,
    }),
  ]),
  cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

reportWebVitals()
