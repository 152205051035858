import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../../__generated__/types'

const defaultOptions = {}
export type ISocialAuthMutationVariables = Types.Exact<{
  provider: Types.Scalars['String']
  accessToken: Types.Scalars['String']
}>

export type ISocialAuthMutation = {
  __typename?: 'Mutation'
  socialAuth?:
    | { __typename?: 'SocialAuth'; token: string; refreshExpiresIn: number }
    | null
    | undefined
}

export const SocialAuthDocument = gql`
  mutation socialAuth($provider: String!, $accessToken: String!) {
    socialAuth(provider: $provider, accessToken: $accessToken) {
      token
      refreshExpiresIn
    }
  }
`
export type ISocialAuthMutationFn = Apollo.MutationFunction<
  ISocialAuthMutation,
  ISocialAuthMutationVariables
>

/**
 * __useSocialAuthMutation__
 *
 * To run a mutation, you first call `useSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialAuthMutation, { data, loading, error }] = useSocialAuthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSocialAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISocialAuthMutation,
    ISocialAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISocialAuthMutation, ISocialAuthMutationVariables>(
    SocialAuthDocument,
    options
  )
}
export type SocialAuthMutationHookResult = ReturnType<
  typeof useSocialAuthMutation
>
export type SocialAuthMutationResult =
  Apollo.MutationResult<ISocialAuthMutation>
export type SocialAuthMutationOptions = Apollo.BaseMutationOptions<
  ISocialAuthMutation,
  ISocialAuthMutationVariables
>
