import { IScreen } from '../Screens/types'

export interface IMobileState {
  screens: IScreen[]
}

export enum MobileType {
  ADD_MOBILE_SCREENS = 'ADD_MOBILE_SCREENS',
  DELETE_MOBILE_SCREENS = 'DELETE_MOBILE_SCREENS',
}

export interface IAddMobileScreens {
  type: MobileType.ADD_MOBILE_SCREENS
  screens: IScreen[]
}

export interface IDeleteMobileScreens {
  type: MobileType.DELETE_MOBILE_SCREENS
}

export type MobileAction = IAddMobileScreens | IDeleteMobileScreens
