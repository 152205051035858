import styles from './index.module.scss'
import clsx from 'clsx'
import React from 'react'

interface IButtonProps {
  title: string
  onClick?: (e: React.MouseEvent) => void
  isError?: object
  isActive?: boolean
  isMobile?: boolean
  isHeight?: string
  className?: string
}

const Button: React.FC<IButtonProps> = ({
  title,
  onClick,
  isError,
  isActive,
  isHeight,
  className,
}) => {
  const classes = clsx(
    'btn',
    {
      'btn-error': isError,
      'btn-primary btn-block': !isError,
      [styles.btn_disable]: !isActive && !isError,
    },
    isHeight && isHeight
  )

  return (
    <button onClick={onClick} className={className ?? classes}>
      {title}
    </button>
  )
}

export default React.memo(Button)
