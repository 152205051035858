import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../../__generated__/types'

const defaultOptions = {}
export type ICreateVideoMutationVariables = Types.Exact<{
  file?: Types.Maybe<Types.Scalars['Upload']>
  name?: Types.Maybe<Types.Scalars['String']>
}>

export type ICreateVideoMutation = {
  __typename?: 'Mutation'
  createVideo?:
    | {
        __typename?: 'CreateVideoSuccess'
        video?:
          | { __typename?: 'Video'; id?: string | null | undefined }
          | null
          | undefined
      }
    | null
    | undefined
}

export const CreateVideoDocument = gql`
  mutation createVideo($file: Upload, $name: String) {
    createVideo(file: $file, name: $name) {
      video {
        id
      }
    }
  }
`
export type ICreateVideoMutationFn = Apollo.MutationFunction<
  ICreateVideoMutation,
  ICreateVideoMutationVariables
>

/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      file: // value for 'file'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateVideoMutation,
    ICreateVideoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ICreateVideoMutation,
    ICreateVideoMutationVariables
  >(CreateVideoDocument, options)
}
export type CreateVideoMutationHookResult = ReturnType<
  typeof useCreateVideoMutation
>
export type CreateVideoMutationResult =
  Apollo.MutationResult<ICreateVideoMutation>
export type CreateVideoMutationOptions = Apollo.BaseMutationOptions<
  ICreateVideoMutation,
  ICreateVideoMutationVariables
>
