const fixTextSeconds = (time: number): string => {
  if (time < 10) return '0'.concat(time.toFixed(2))
  else return time.toFixed(2)
}

const fixTextMinuts = (time: number): string => {
  if (time < 10) return '0'.concat(time.toFixed(0))
  else return time.toFixed(0)
}

// Example: 64.423<number> => 01:04.42<string>
const timeNumberToString = (time: number): string => {
  return `${fixTextMinuts(Math.floor(time / 60))}:${fixTextSeconds(time % 60)}`
}

export default timeNumberToString
