import Footer from '../../Feature/Footer'
import Header from '../../Feature/Header'
import { changeCurrentTheme } from '../../Store/Common/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { useProjectsQuery } from './__generated__/projects'
import {
  DotsVerticalIcon,
  PencilAltIcon,
  PlayIcon,
} from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const ProjectsPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { data, loading, error } = useProjectsQuery({ fetchPolicy: 'no-cache' })
  const [checked, setChecked] = useState(false)

  const isAuth = localStorage.getItem('token')
  if (!isAuth) {
    history.push('/login')
  }

  const currentTheme = useAppSelector((state) => state.common.currentTheme)
  //const currentTheme = localStorage.getItem('theme');

  useEffect(() => {
    const storageTheme = localStorage.getItem('theme')
    if (storageTheme) {
      dispatch(changeCurrentTheme(storageTheme))
    }
  }, [])

  if (loading) return <p>'Loading...'</p>
  if (error) return <p>`Error! ${error.message}`</p>

  console.log(data?.projects?.video)

  return (
    <div data-theme={currentTheme} className='bg-base-100'>
      <Header />

      <div className='flex flex-col text-left mt-3 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
        <div className='shadow border-b border-gray-200 sm:rounded-lg min-w-full divide-y divide-gray-200'>
          <div className='bg-gray-50 flex items-center'>
            <div className='relative px-2 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
              <input
                id='comments'
                aria-describedby='comments-description'
                name='comments'
                type='checkbox'
                className='hidden focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded'
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
              />
            </div>
            <div className='flex-grow py-2 w-2/5 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Project
            </div>
            <div className='w-2/12 py-2 text-left text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              ID
            </div>
            <div className='hidden w-2/12 py-2 text-left text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Created
            </div>
            <div className='hidden w-2/12 py-2 text-left text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Last Update
            </div>
            <div className='hidden w-2/12 py-2 text-left text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
              Members
            </div>
            {/*<div*/}
            {/*    className='w-3/12 relative py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>*/}

            {/*    <span className='sr-only'>Edit</span>*/}
            {/*</div>*/}
          </div>

          <div className='bg-white divide-y divide-gray-200'>
            {data?.projects?.video?.map((video) => (
              <div className='flex align-middle' key={video?.id}>
                <div className='flex items-center px-2 py-4 whitespace-nowrap text-center text-sm font-medium relative'>
                  <input
                    id='comments'
                    aria-describedby='comments-description'
                    name='comments'
                    type='checkbox'
                    className='hidden focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded'
                  />
                </div>
                <div className=' whitespace-nowrap w-2/5 flex-grow'>
                  <div className='flex items-center'>
                    <a
                      href={`watch/${video?.id}`}
                      onClick={(e) => {
                        e.preventDefault()
                        history.push(`watch/${video?.id}`)
                      }}
                      className='flex-shrink-0 h-20 w-20 bg-gray-300 flex-shrink-0 flex items-center justify-center w-20 text-white text-sm font-medium rounded-md '
                    >
                      {video?.icon ? (
                        <img
                          className='h-20 w-20 rounded-md'
                          src={video?.icon}
                          alt=''
                        />
                      ) : (
                        video?.id?.slice(0, 2).toUpperCase()
                      )}
                    </a>
                    <div className='ml-4'>
                      <a
                        href={`watch/${video?.id}`}
                        onClick={(e) => {
                          e.preventDefault()
                          history.push(`watch/${video?.id}`)
                        }}
                        className='text-sm font-medium text-gray-900'
                      >
                        {video?.name}
                      </a>
                      {/*<div className='text-sm text-gray-500'>{video?.name}</div>*/}
                    </div>
                    <div className='ml-4'></div>
                  </div>
                </div>

                <div className='flex justify-start items-center w-2/12 py-4 whitespace-nowrap text-sm text-gray-500'>
                  <a
                    href={`watch/${video?.id}`}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push(`watch/${video?.id}`)
                    }}
                    type='button'
                    className='inline-flex items-center px-2 py-2 border border-transparent rounded-md text-sm text-gray-500 bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    <PlayIcon className='block h-6 w-6' aria-hidden='true' />
                  </a>
                  <a
                    href={`edit/${video?.id}`}
                    onClick={(e) => {
                      e.preventDefault()
                      history.push(`edit/${video?.id}`)
                    }}
                    type='button'
                    className='inline-flex items-center px-2 py-2 border border-transparent rounded-md text-sm text-gray-500 bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    <PencilAltIcon
                      className='block h-6 w-6'
                      aria-hidden='true'
                    />
                  </a>
                  <a
                    href={`more/${video?.id}`}
                    onClick={(e) => {
                      e.preventDefault()
                      //history.push(`more/${video?.id}`)
                    }}
                    type='button'
                    className='inline-flex items-center px-2 py-2 border border-transparent rounded-md text-sm text-gray-500 bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    <DotsVerticalIcon
                      className='block h-6 w-6'
                      aria-hidden='true'
                    />
                  </a>
                </div>

                <div className='flex justify-start items-center w-2/12 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {video?.id}
                </div>
                <div className='hidden flex justify-start items-center w-2/12 py-4 whitespace-nowrap text-sm text-gray-500'>
                  test
                </div>
                <div className='hidden flex justify-start items-center w-2/12 py-4 whitespace-nowrap text-sm text-gray-500'>
                  test
                </div>
                <div className='hidden flex justify-start items-center w-2/12 py-4 whitespace-nowrap text-sm text-gray-500'>
                  {video?.id}
                </div>
                {/*<div*/}
                {/*    className='w-3/12 py-4 whitespace-nowrap text-center text-sm font-medium flex items-center justify-center'>*/}
                {/*    <a*/}
                {/*        type="button"*/}
                {/*        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                {/*        href={`edit/${video?.id}`}*/}
                {/*        onClick={(e) => {*/}
                {/*            e.preventDefault()*/}
                {/*            history.push(`edit/${video?.id}`)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Edit*/}
                {/*    </a>*/}
                {/*    <a*/}
                {/*        href={`watch/${video?.id}`}*/}
                {/*        onClick={(e) => {*/}
                {/*            e.preventDefault()*/}
                {/*            history.push(`watch/${video?.id}`)*/}
                {/*        }}*/}
                {/*        type='button'*/}
                {/*        className='ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'*/}
                {/*    >*/}
                {/*        Watch*/}
                {/*    </a>*/}
                {/*</div>*/}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/*<Footer />*/}
    </div>
  )
}

export default ProjectsPage
