import timeNumberToString from '../../../GlobalFunctions/timeNumberToString'
import useInput from '../../../Hooks/useInput'
import {
  changeChapterName,
  deleteChapter,
} from '../../../Store/Chapters/actions'
import { changedTime } from '../../../Store/Video/actions'
import { useAppDispatch } from '../../../Store/hooks'
import Input from '../../../UI/Input'
import DeleteButton from '../../DeleteButton'
import styles from './index.module.scss'
import React, { useEffect } from 'react'

interface IChapterProps {
  time: number
  name: string
  id: string
  isEditable?: boolean
}

const Chapter: React.FC<IChapterProps> = ({ time, name, id, isEditable }) => {
  const dispatch = useAppDispatch()

  const input = useInput(name, 'Input chapter name', false)
  //<--
  // исправить
  useEffect(() => {
    dispatch(changeChapterName(input.inputValue, id))
  }, [input.inputValue])
  //-->
  return (
    <div className={`${styles.chapter} text-base-content`}>
      {isEditable && (
        <div className={styles.chapter__deleteContainer}>
          <DeleteButton onClick={() => dispatch(deleteChapter(id))} />
        </div>
      )}

      <label className={styles.chapter__label}>
        <span
          className={styles.chapter__time}
          onClick={() => dispatch(changedTime({ time }))}
        >
          {timeNumberToString(time || 0)}
        </span>
        <div className={styles.chapter__inputContainer}>
          <Input {...input.default} height='input-sm' isEditable={isEditable} />
        </div>
      </label>
    </div>
  )
}

export default React.memo(Chapter)
