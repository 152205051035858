import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../__generated__/types'

const defaultOptions = {}
export type IRefreshTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']
}>

export type IRefreshTokenMutation = {
  __typename?: 'Mutation'
  refreshToken?:
    | {
        __typename?: 'Refresh'
        payload: any
        refreshExpiresIn: number
        token: string
      }
    | null
    | undefined
}

export const RefreshTokenDocument = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      payload
      refreshExpiresIn
      token
    }
  }
`
export type IRefreshTokenMutationFn = Apollo.MutationFunction<
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables
>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRefreshTokenMutation,
    IRefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IRefreshTokenMutation,
    IRefreshTokenMutationVariables
  >(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>
export type RefreshTokenMutationResult =
  Apollo.MutationResult<IRefreshTokenMutation>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  IRefreshTokenMutation,
  IRefreshTokenMutationVariables
>
