export interface IPayloadUser {
  plan?: string | null | undefined
  uid?: string | null | undefined
  name?: string | null | undefined
  email?: string | null | undefined
}

export interface IUserState {
  user?: IPayloadUser
  token?: string | null
  isAuth: boolean
}

export enum UserType {
  USER_SIGN_IN = 'USER_SIGN_IN',
  USER_LOG_OUT = 'USER_LOG_OUT',
  USER_TOKEN = 'USER_TOKEN',
  CHANGE_USER_IS_AUTH = 'CHANGE_USER_IS_AUTH',
}

export interface IUserSignIn {
  type: UserType.USER_SIGN_IN
  user: IPayloadUser
}

// export interface IUserLogOut {
//   type: UserType.USER_LOG_OUT
// }

export interface IUserToken {
  type: UserType.USER_TOKEN
  token: string
}

export interface IChangeUserIsAuth {
  type: UserType.CHANGE_USER_IS_AUTH
  isAuth: boolean
}

export type UserAction =
  | IUserSignIn
  // | IUserLogOut
  | IUserToken
  | IChangeUserIsAuth
