import maxAddedScreensFromScope from '../../Constants/maxAddedScreensFromScope'
import { IScreensState, ScreensAction, ScreensType } from './types'

const initialState: IScreensState = {
  screens: [],
  size: 15,
  addedScreensFromScope: [],
  intervalCuttingScreens: 0,
  canAddCutting: false,
}

export const screensReduser = (
  state = initialState,
  action: ScreensAction
): IScreensState => {
  switch (action.type) {
    case ScreensType.ADD_SCREENS:
      return {
        ...state,
        screens: action.screens,
      }
    case ScreensType.CHANGE_SCREENS_SIZE:
      return {
        ...state,
        size: action.size,
      }
    case ScreensType.ADD_SCOPE_SCREENS:
      if (
        state.addedScreensFromScope.length + action.addedScreens.length >
        maxAddedScreensFromScope
      ) {
        const deleteScreens = state.addedScreensFromScope.slice(
          0,
          state.addedScreensFromScope.length +
            action.addedScreens.length -
            maxAddedScreensFromScope
        )

        const deleteScreensID = deleteScreens.map((screen) => screen.id)

        const newScreens = state.screens
          .filter(
            (screen) =>
              deleteScreensID.findIndex((id) => id === screen.id) === -1
          )
          .map((screen) => {
            if (
              deleteScreens.findIndex(
                (delScreen) => delScreen.idCuttingScreen === screen.id
              ) !== -1
            )
              return {
                ...screen,
                canCutting: true,
              }
            else return screen
          })
          .concat(action.addedScreens)
          .sort((a, b) => a.time - b.time)

        const newAddedScreensFromScope = state.addedScreensFromScope
          .slice(
            state.addedScreensFromScope.length +
              action.addedScreens.length -
              maxAddedScreensFromScope
          )
          .concat(action.addedScreens)

        return {
          ...state,
          addedScreensFromScope: newAddedScreensFromScope,
          screens: newScreens,
        }
      } else {
        const newAddedScreensFromScope = state.addedScreensFromScope.concat(
          action.addedScreens
        )

        const newScreens = state.screens
          .concat(action.addedScreens)
          .sort((a, b) => a.time - b.time)

        return {
          ...state,
          addedScreensFromScope: newAddedScreensFromScope,
          screens: newScreens,
        }
      }
    case ScreensType.CHANGE_INTERVAL_CUTTING_SCREENS:
      return {
        ...state,
        intervalCuttingScreens: action.intervalCuttingScreens,
      }
    case ScreensType.CHANGE_CAN_CUTTING:
      const newScreen = state.screens.find(
        (screen) => screen.id === action.idScreen
      )

      if (newScreen) {
        newScreen.canCutting = action.canCutting
        const newScreens = state.screens
          .filter((screen) => screen.id !== action.idScreen)
          .concat(newScreen)
          .sort((a, b) => a.time - b.time)

        return {
          ...state,
          screens: newScreens,
        }
      }

      return {
        ...state,
      }
    case ScreensType.CHANGE_CAN_ADD_CUTTING:
      return {
        ...state,
        canAddCutting: action.canAddCutting,
      }
    case ScreensType.CLEAR_SCREENS:
      return initialState
    default:
      return state
  }
}
