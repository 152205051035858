import React from 'react'
import ChapterScroller from '../../../../Feature/ChapterScroller'
import ChapterSelector from '../../../../Feature/ChapterSelector'
import SmallButton from '../../../../UI/SmallButton'
import styles from './index.module.scss'

interface ISecondMainScreenProps {
  SetShowSecondPage: (SetShowSecondPage: boolean) => void
}

const SecondMainScreen: React.FC<ISecondMainScreenProps> = ({
  SetShowSecondPage,
}) => {
  return (
    <div className={styles.screen}>
      <div className={styles.screen__smallButtonContainer}>
        <SmallButton
          onClick={() => SetShowSecondPage(false)}
          title='Back to video'
        />
      </div>
      <div className={styles.navigation}>
        <div className={styles.navigation__chapters}>
          <ChapterSelector />
          <ChapterScroller />
        </div>
      </div>
    </div>
  )
}

export default React.memo(SecondMainScreen)
