const cutSceneOutVideo = (video: HTMLVideoElement | null): string => {
  if (!video) return ''
  const canvas = document.createElement('canvas')
  const { height, width } = video.getBoundingClientRect()
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  context?.drawImage(video, 0, 0, width, height)

  return canvas.toDataURL(undefined, 1)
}

export default cutSceneOutVideo
