import { ChaptersAction, ChaptersType, IChaptersState } from './types'

const initialState: IChaptersState = {
  chapters: [],
}

export const chaptersReduser = (
  state = initialState,
  action: ChaptersAction
): IChaptersState => {
  switch (action.type) {
    case ChaptersType.CHANGE_CHAPTER_NAME:
      const newChapter = state.chapters.find(
        (chapter) => chapter.key === action.key
      )

      if (newChapter) {
        newChapter.name = action.name
        return {
          ...state,
          chapters: [
            ...state.chapters.filter((chapter) => chapter.key !== action.key),
            newChapter,
          ].sort((a, b) => Number(a.time) - Number(b.time)),
        }
      }
      return {
        ...state,
      }

    case ChaptersType.ADD_CHAPTER:
      console.log(state.chapters, action.chapter)

      return {
        ...state,
        chapters: state.chapters
          .concat(action.chapter)
          .sort((a, b) => Number(a.time) - Number(b.time)),
      }
    case ChaptersType.DELETE_CHAPTER:
      return {
        ...state,
        chapters: state.chapters.filter(
          (chapter) => chapter.key !== action.key
        ),
      }
    case ChaptersType.ADD_CHAPTERS:
      return {
        ...state,
        chapters: action.chapters,
      }
    case ChaptersType.CLEAR_CHAPTERS:
      return initialState
    default:
      return state
  }
}
