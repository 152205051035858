export interface IScreen {
  id: string
  time: number
  url: string

  // for screens from add cutting
  intervalCutting?: number
  canCutting?: boolean
  idCuttingScreen?: string
}

export interface IScreensState {
  screens: IScreen[]
  size: number
  addedScreensFromScope: IScreen[]
  intervalCuttingScreens: number
  canAddCutting: boolean
}

export enum ScreensType {
  ADD_SCREENS = 'ADD_SCREENS',
  CLEAR_SCREENS = 'CLEAR_SCREENS',
  CHANGE_SCREENS_SIZE = 'CHANGE_SCREENS_SIZE',
  ADD_SCOPE_SCREENS = 'ADD_SCOPE_SCREENS',
  CHANGE_INTERVAL_CUTTING_SCREENS = 'CHANGE_INTERVAL_CUTTING_SCREENS',
  CHANGE_CAN_ADD_CUTTING = 'CHANGE_CAN_ADD_CUTTING',
  CHANGE_CAN_CUTTING = 'CHANGE_CAN_CUTTING',
}

export interface IAddScreens {
  type: ScreensType.ADD_SCREENS
  screens: IScreen[]
}

export interface IClearScreens {
  type: ScreensType.CLEAR_SCREENS
}

export interface IChangeScreenSize {
  type: ScreensType.CHANGE_SCREENS_SIZE
  size: number
}

export interface IAddScopeScreens {
  type: ScreensType.ADD_SCOPE_SCREENS
  addedScreens: IScreen[]
}

export interface IChangeIntervalCuttingScreens {
  type: ScreensType.CHANGE_INTERVAL_CUTTING_SCREENS
  intervalCuttingScreens: number
}

export interface IChangeCanAddCutting {
  type: ScreensType.CHANGE_CAN_ADD_CUTTING
  canAddCutting: boolean
}

export interface IChangeCanCutting {
  type: ScreensType.CHANGE_CAN_CUTTING
  idScreen: string
  canCutting: boolean
}

export type ScreensAction =
  | IAddScreens
  | IClearScreens
  | IChangeScreenSize
  | IAddScopeScreens
  | IChangeIntervalCuttingScreens
  | IChangeCanAddCutting
  | IChangeCanCutting
