import { GraphqlAction, GraphqlType, IGraphqlState } from './types'

const initialState: IGraphqlState = {
  errorVideoUpdate: '',
  doUpdateVideo: false,
  file: null,
}

export const graphqlReduser = (
  state = initialState,
  action: GraphqlAction
): IGraphqlState => {
  switch (action.type) {
    case GraphqlType.CHANGE_ERROR_VIDEO_UPDATE:
      return {
        ...state,
        errorVideoUpdate: action.errorVideoUpdate,
      }
    case GraphqlType.CHANGE_DO_UPDATE_VIDEO:
      return {
        ...state,
        doUpdateVideo: action.doUpdateVideo,
      }
    case GraphqlType.CHANGE_FILE_UPLOAD:
      return {
        ...state,
        file: action.file,
      }
    default:
      return state
  }
}
