import { chaptersReduser } from './Chapters/reduser'
import commonReduser from './Common/reduser'
import { graphqlReduser } from './Graphql/reduser'
import mobileReduser from './Mobile/reduser'
import { screensReduser } from './Screens/reduser'
import { userReduser } from './User/reduser'
import { videoReduser } from './Video/reduser'
import { combineReducers } from 'redux'

const appReduser = combineReducers({
  user: userReduser,
  video: videoReduser,
  chapters: chaptersReduser,
  screens: screensReduser,
  mobile: mobileReduser,
  common: commonReduser,
  graphql: graphqlReduser,
})

//<--
// Глобальный экшен для сброса стора когда человек разлогинился

export const rootReduser = (state: any, action: any) => {
  if (action.type === 'USER_LOG_OUT') {
    return appReduser(undefined, action)
  }
  return appReduser(state, action)
}
//-->
