import {
  IAddScopeScreens,
  IAddScreens,
  IChangeCanAddCutting,
  IChangeCanCutting,
  IChangeIntervalCuttingScreens,
  IChangeScreenSize,
  IClearScreens,
  IScreen,
  ScreensType,
} from './types'

export const addScreens = (screens: IScreen[]): IAddScreens => ({
  type: ScreensType.ADD_SCREENS,
  screens,
})

export const clearScreens = (): IClearScreens => ({
  type: ScreensType.CLEAR_SCREENS,
})

export const changeScreenSize = (size: number): IChangeScreenSize => ({
  type: ScreensType.CHANGE_SCREENS_SIZE,
  size,
})

export const addScreensFromScope = (
  addedScreens: IScreen[]
): IAddScopeScreens => ({
  type: ScreensType.ADD_SCOPE_SCREENS,
  addedScreens,
})

export const changeIntervalCuttingScreens = (
  intervalCuttingScreens: number
): IChangeIntervalCuttingScreens => ({
  type: ScreensType.CHANGE_INTERVAL_CUTTING_SCREENS,
  intervalCuttingScreens,
})

export const changeCanAddedCutting = (
  canAddCutting: boolean
): IChangeCanAddCutting => ({
  type: ScreensType.CHANGE_CAN_ADD_CUTTING,
  canAddCutting,
})

export const changeCanCutting = (
  idScreen: string,
  canCutting: boolean
): IChangeCanCutting => ({
  type: ScreensType.CHANGE_CAN_CUTTING,
  idScreen,
  canCutting,
})
