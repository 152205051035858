import { useAppSelector } from '../../Store/hooks'
import Chapter from './Chapter/Chapter'
import ChapterWatch from './ChapterWatch'
import styles from './index.module.scss'
import React, { useEffect, useRef } from 'react'

interface IChapterSelector {
  setChapterSelectorHeight?: (setChapterSelectorHeight: number) => void
  isEditable?: boolean
}

const ChapterSelector: React.FC<IChapterSelector> = ({
  setChapterSelectorHeight,
  isEditable,
}) => {
  const chapters = useAppSelector((state) => state.chapters.chapters)

  const chapterSelectorHeight = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (chapterSelectorHeight.current) {
      const chapterSelectorResize = () => {
        if (setChapterSelectorHeight) {
          setChapterSelectorHeight(
            chapterSelectorHeight.current?.getBoundingClientRect().height || 0
          )
        }
      }
      chapterSelectorResize()
    }
  }, [chapters.length])

  return (
    <div ref={chapterSelectorHeight} className={styles.chapterSelector}>
      {isEditable
      // Вынести все это условие в переменную
        ? chapters.map((chapter) => (
            <Chapter
              key={chapter.key}
              time={Number(chapter.time)}
              id={chapter.id}
              name={chapter.name}
              isEditable={isEditable}
            />
          ))
        : chapters.map((chapter) => (
            <ChapterWatch
              key={chapter.key}
              time={Number(chapter.time)}
              name={chapter.name}
            />
          ))}
    </div>
  )
}

export default React.memo(ChapterSelector)
