import deleteButtonImage from '../../images/delete_button_2.png'
import styles from './index.module.scss'
import React from 'react'

interface IDeleteButton {
  onClick?: () => void
}
// Я думаю всем понятно что это не feature и даже не component - вставить туда где используется.
const DeleteButton: React.FC<IDeleteButton> = ({ onClick }) => {
  return (
    <div onClick={onClick} className={styles.container}>
      <img className={styles.container__img} src={deleteButtonImage} />
    </div>
  )
}

export default React.memo(DeleteButton)
