import React from 'react'
import AddNewChapter from '../AddNewChapter'
import styles from './index.module.scss'

interface IChaptersButtonsBlock {
  videoRef: HTMLVideoElement | null
  setShowChapterModal?: (openChapterModal: boolean) => void
}
// Это должно находится явно не в Feature + если используется в одном месте, то просто там и оставить.
const ChaptersButtonsBlock: React.FC<IChaptersButtonsBlock> = ({
  setShowChapterModal,
  videoRef,
}) => {
  return (
    <div className={styles.ChapterButtonsBlock}>
      <AddNewChapter
        videoRef={videoRef}
        setShowChapterModal={setShowChapterModal}
        isChapter={true}
      />
    </div>
  )
}

export default ChaptersButtonsBlock
