import React from 'react'
import styles from './index.module.scss'

interface ISmallButtonProps {
  title: string
  onClick?: (e: React.MouseEvent) => void
  isError?: object
}

const SmallButton: React.FC<ISmallButtonProps> = ({
  title,
  onClick,
  isError,
}) => {
  return (
    <button
      onClick={onClick}
      className={
        isError
          ? `${styles.smallButton} ${styles.smallButton_error} `
          : styles.smallButton
      }
    >
      {title}
    </button>
  )
}

export default React.memo(SmallButton)
