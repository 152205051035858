import Button from '../../../UI/Button'
import Input from '../../../UI/Input/Input'
import styles from './index.module.scss'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router'

interface IModalProps {
  setOpen: (setOpen: boolean) => void
  isDisplay: boolean
}

const Modal: React.FC<IModalProps> = ({ setOpen, isDisplay }) => {
  const link = window.location.href

  const copyTextHandler = async () => {
    setOpen(false)

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(link)
    } else {
      return document.execCommand('copy', true, link)
    }
  }

  return (
    <div className={isDisplay ? styles.modalContainer : styles.off}>
      <div
        className={styles.modalBackground}
        onClick={() => setOpen(false)}
      ></div>
      <div className={styles.modal}>
        <p className={styles.modal__title}>Share Link</p>
        <p className={styles.modal__subTitle}>Your link on current page</p>
        <input className={clsx('input w-full', styles.input)} value={link} />
        <div className='mt-6 flex justify-end gap-2'>
          <Button
            className='text-center inline-flex items-center px-6 py-2 border border-gray-200 text-sm font-medium rounded-md shadow-sm  bg-transparent hover:bg-base-200 focus:outline-none  '
            title='Cancel'
            onClick={() => setOpen(false)}
          />
          <Button
            className='text-center inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none '
            title='Copy'
            onClick={copyTextHandler}
          />
        </div>
      </div>
    </div>
  )
}

export default Modal
