import React, { useMemo } from 'react'
import createAndPushArray from '../../GlobalFunctions/createAndPushArray'
import { useAppSelector } from '../../Store/hooks'
import styles from './index.module.scss'

// исправить условие
const Ruler: React.FC = () => {
  const durationTime = useAppSelector((state) => state.video.durationTime)

  const arr = useMemo(
    () => createAndPushArray(Math.round(durationTime), 1),
    [durationTime]
  )

  return (
    <div className={styles.ruler}>
      {arr.map((el) => (
        <div className={styles.ruler__element} key={el}>
          {el % 5 === 0 ? (
            <div className={styles.ruler__line}>{el}s</div>
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default React.memo(Ruler)
