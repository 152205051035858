import { FormEvent } from 'react'

export default function Comments() {
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <form className='mt-10' onSubmit={onSubmit}>
      <textarea
        rows={4}
        name='comment'
        id='comment'
        className='p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md'
        defaultValue={''}
      />
      <div className='mt-2 flex justify-start'>
        <button
          type='submit'
          className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          disabled
        >
          Send
        </button>
      </div>
    </form>
  )
}
