import React, { RefObject, useEffect } from 'react'
import { useAppSelector } from '../../../Store/hooks'
import styles from './index.module.scss'

interface VideoElementProps {
  videoRef: RefObject<HTMLVideoElement>
  videoPath: string
  isMobile: boolean
  aspectVideo: number
}

// Непонятные условия вынести в переменную
const VideoElement: React.FC<VideoElementProps> = ({
  videoRef,
  videoPath,
  isMobile,
  aspectVideo,
}) => {
  const changedTime = useAppSelector((state) => state.video.changedTime.time)

  useEffect(() => {
    if (videoPath) {
      const video: any = document.getElementById(videoPath)
      video.currentTime = changedTime
    }
  }, [changedTime])

  return (
    <video
      ref={videoRef}
      className={
        isMobile
          ? aspectVideo < 1
            ? `${styles.mobile} ${styles.mobile_vertical}`
            : `${styles.mobile} ${styles.mobile_horizontal}`
          : aspectVideo < 1
          ? `${styles.desktop} ${styles.desktop_vertical}`
          : `${styles.desktop} ${styles.desktop_horizontal}`
      }
      id={videoPath}
      controls
      crossOrigin='anonymous'
      src={videoPath}
    ></video>
  )
}

export default React.memo(VideoElement)
