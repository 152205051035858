import { useCreateVideoMutation } from './__generated__/createVideo'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

const NewPage: React.FC = () => {
  const history = useHistory()

  const [createVideo, { error }] = useCreateVideoMutation({
    onCompleted: (data) => {
      // dispatch(changePrevURLFromLogin('/new'));
      data.createVideo?.video &&
        history.replace('/edit/' + data.createVideo.video.id)
    },
  })

  useEffect(() => {
    createVideo()
  }, [])

  if (error) return <>Error: {error.message}</>
  return <>Create New Project...</>
}

export default NewPage
