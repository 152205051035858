import { useUpdateVideoMutation } from '../../GraphqlQuery/__generated__/updateVideo'
import { addChapters } from '../../Store/Chapters/actions'
import {
  changeFuncFromLogin,
  changePrevURLFromLogin,
} from '../../Store/Common/actions'
import { changeDoUpdateVideo } from '../../Store/Graphql/actions'
import { changeIDVideo } from '../../Store/Video/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import typenameUpdateVideoWithoutVideo from '../../Types/updateVideoMutation/typenameUpdateVIdeoWithoutVideo'
import typenameUpdateVideoWithVideo from '../../Types/updateVideoMutation/typenameUpdateVideoWithVideo'
import Button from '../../UI/Button'
import ProgressBar from '../ProgressBar'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'

// Вынести логику в хук
const UpdateVideoButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const id = useAppSelector((state) => state.video.id)
  const name = useAppSelector((state) => state.video.name)
  const url = useAppSelector((state) => state.video.url)
  const chapters = useAppSelector((state) => state.chapters.chapters)
  const file = useAppSelector((state) => state.graphql.file)

  const isAuth = useAppSelector((state) => state.user.isAuth)

  const [message, setMessage] = useState<string>('')

  const [progress, setProgress] = useState<number | null>(null)

  const [updateVideo] = useUpdateVideoMutation({
    onCompleted: (data) => {
      if (data && data.updateVideo) {
        if (data.updateVideo.__typename === 'UserNotAuthenticated')
          history.push('/login')
        dispatch(changeDoUpdateVideo(false))
        if (
          typenameUpdateVideoWithVideo.find(
            (el) => el === data.updateVideo?.__typename
          )
        ) {
          //@ts-ignore
          history.push(`/edit/${data.updateVideo.video.id}`)
          //@ts-ignore
          dispatch(changeIDVideo(data.updateVideo.video?.id || ''))
          dispatch(
            addChapters(
              //@ts-ignore
              data.updateVideo.video?.chapters?.map((chapter) => ({
                id: chapter?.id || '',
                time: chapter?.time || '0',
                name: chapter?.name || '',
                url: chapter?.url || '',
                imgURL: '',
                key: chapter?.id || String(Date.now()),
              })) || []
            )
          )
        } else if (
          typenameUpdateVideoWithoutVideo.find(
            (el) => el === data.updateVideo?.__typename
          )
        ) {
          // console.log('error')

          setMessage(
            data?.updateVideo?.__typename
              ?.split(/([A-Z])/)
              .map((el) => {
                if (el === el.toUpperCase()) return ' ' + el.toLowerCase()
                else return el
              })
              .join('') || ''
          )
        }
      }
    },
    onError: (e) => {
      setMessage(
        e.message
          .split(/([A-Z])/)
          .map((el) => {
            if (el === el.toUpperCase()) return ' ' + el.toLowerCase()
            else return el
          })
          .join('')
      )
      // console.log('error:', e)
    },
  })

  // useEffect(() => {
  //   const video: any = document.getElementById(videoPath)
  //   if (video)
  //     video.onloadeddata = () =>
  //       console.log(
  //         video?.getBoundingClientRect().left,
  //         video?.getBoundingClientRect().top
  //       )
  //   // dispatch(changePointVideo({x: video.}))
  // }, [videoRef, videoPath, aspectVideo])

  const uploadObj = useMemo(
    () => ({
      file: url.includes('blob') ? file : null,
      video: {
        id: id !== '' ? id : '',
        name,
        chapters: chapters.map((chapter) => ({
          id: chapter.id,
          name: chapter.name,
          time: String(chapter.time),
          file: chapter.imgURL,
        })),
        // blurs: (() => {
        //   const video: any = document.getElementById(url)
        //   const {
        //     left: Videoleft,
        //     top: VideoTop,
        //     width: VideoWidth,
        //     height: VideoHeight,
        //   } = video?.getBoundingClientRect() || { Videolef: 0, VideoTop: 0 }

        //   const returnBlurs = blurs.map((blur) => ({
        //     startTime: blur.startTime,
        //     endTime: blur.endTime,
        //     width: blur.width / VideoHeight,
        //     height: blur.heigth / VideoHeight,
        //     x: (blur.point.x - Videoleft) / VideoWidth,
        //     y: (blur.point.y - VideoTop) / VideoHeight,
        //   }))
        //   return returnBlurs
        // })(),
      },
    }),
    [id, name, chapters, file]
  )

  return (
    <>
      <Button
        title='Save'
        onClick={() => {
          if (!isAuth) {
            dispatch(changePrevURLFromLogin(history.location.pathname))
            dispatch(
              changeFuncFromLogin(() =>
                updateVideo({
                  variables: uploadObj,
                  context: {
                    fetchOptions: {
                      useUpload: true,
                      onProgress: (ev: any) => {
                        console.log(ev.loaded / ev.total)
                        setProgress((ev.loaded / ev.total) * 100)
                      },
                    },
                  },
                })
              )
            )
            history.push('/login')
          } else
            updateVideo({
              variables: uploadObj,
              context: {
                fetchOptions: {
                  useUpload: true,
                  onProgress: (ev: any) => {
                    console.log(ev.loaded / ev.total)
                    setProgress((ev.loaded / ev.total) * 100)
                  },
                },
              },
            })
        }}
      />
      {message && <div>Error message:{message}</div>}
      {progress !== null && progress !== 100 && (
        <ProgressBar persent={progress.toFixed(2)} />
      )}
    </>
  )
}

export default React.memo(UpdateVideoButton)
