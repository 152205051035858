import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../__generated__/types'

const defaultOptions = {}
export type IVideoQueryVariables = Types.Exact<{
  id: Types.Scalars['String']
  edit?: Types.Maybe<Types.Scalars['Boolean']>
}>

export type IVideoQuery = {
  __typename?: 'Query'
  video?:
    | {
        __typename?: 'Video'
        id?: string | null | undefined
        icon?: string | null | undefined
        website?: string | null | undefined
        description?: string | null | undefined
        name: string
        state: Types.IVideosVideoStateChoices
        editable?: boolean | null | undefined
        url?: string | null | undefined
        user?:
          | {
              __typename?: 'User'
              email?: string | null | undefined
              name?: string | null | undefined
              plan?: string | null | undefined
              uid?: string | null | undefined
            }
          | null
          | undefined
        chapters?:
          | Array<
              | {
                  __typename?: 'Chapter'
                  id: string
                  time: string
                  name: string
                  url?: string | null | undefined
                  state: Types.IVideosChapterStateChoices
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export const VideoDocument = gql`
  query video($id: String!, $edit: Boolean = null) {
    video(id: $id, edit: $edit) {
      id
      icon
      website
      description
      name
      state
      editable
      user {
        email
        name
        plan
        uid
      }
      chapters {
        id
        time
        name
        url
        state
      }
      url
    }
  }
`

/**
 * __useVideoQuery__
 *
 * To run a query within a React component, call `useVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      edit: // value for 'edit'
 *   },
 * });
 */
export function useVideoQuery(
  baseOptions: Apollo.QueryHookOptions<IVideoQuery, IVideoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IVideoQuery, IVideoQueryVariables>(
    VideoDocument,
    options
  )
}
export function useVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IVideoQuery, IVideoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IVideoQuery, IVideoQueryVariables>(
    VideoDocument,
    options
  )
}
export type VideoQueryHookResult = ReturnType<typeof useVideoQuery>
export type VideoLazyQueryHookResult = ReturnType<typeof useVideoLazyQuery>
export type VideoQueryResult = Apollo.QueryResult<
  IVideoQuery,
  IVideoQueryVariables
>
