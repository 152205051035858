import { IChapter } from '../../__generated__/types'

export interface IChapterMy extends IChapter {
  key: string
  imgURL: string
  // что еще?
}
export interface IChaptersState {
  chapters: IChapterMy[]
}

export enum ChaptersType {
  ADD_CHAPTER = 'ADD_CHAPTER',
  DELETE_CHAPTER = 'DELETE_CHAPTER',
  CHANGE_CHAPTER_NAME = 'CHANGE_CHAPTER_NAME',
  CLEAR_CHAPTERS = 'CLEAR_CHAPTERS',

  // backend

  ADD_CHAPTERS = 'ADD_CHAPTERS',
}

export interface IAddChapter {
  type: ChaptersType.ADD_CHAPTER
  chapter: IChapterMy
}

export interface IDeleteChapter {
  type: ChaptersType.DELETE_CHAPTER
  key: string
}

export interface IChangeChapterName {
  type: ChaptersType.CHANGE_CHAPTER_NAME
  name: string
  key: string
}

export interface IClearChapters {
  type: ChaptersType.CLEAR_CHAPTERS
}

export interface IAddChapters {
  type: ChaptersType.ADD_CHAPTERS
  chapters: IChapterMy[]
}

export type ChaptersAction =
  | IAddChapter
  | IDeleteChapter
  | IChangeChapterName
  | IClearChapters
  | IAddChapters
