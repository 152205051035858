import React from 'react'
import DropZone from '../../DropZone'
import styles from './index.module.scss'

interface IModalProps {
  setOpen: (setOpen: boolean) => void
  setVideoPath?: (setVideoPath: string) => void
  setVideoName?: (setVideoName: string) => void
  loadRefVideo?: () => void
  isDisplay: boolean
}

const Modal: React.FC<IModalProps> = ({
  setOpen,
  setVideoPath,
  setVideoName,
  loadRefVideo,
  isDisplay,
}) => {
  return (
    <div className={isDisplay ? styles.modalContainer : styles.off}>
      <div
        className={styles.modalBackground}
        onClick={() => setOpen(false)}
      ></div>
      <div className={styles.modal}>
        <p className={styles.modal__title}>Click or Drag</p>
        <DropZone
          loadRefVideo={loadRefVideo}
          setVideoPath={setVideoPath}
          setOpen={setOpen}
          setVideoName={setVideoName}
        />
      </div>
    </div>
  )
}

export default Modal
