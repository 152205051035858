import timeNumberToString from '../../../GlobalFunctions/timeNumberToString'
import { changedTime } from '../../../Store/Video/actions'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import AddScreensButtons from './AddScreensButton/AddScreensButton'
import styles from './index.module.scss'
import React from 'react'

interface IWholeScene {
  canCutting: boolean | undefined
  id: string
  title?: string
  url: string
  time: number
  current: boolean
  index: number
  addScreens: (
    idScreen: string,
    time: number,
    intervalTime: number | undefined
  ) => void
  intervalTime: number | undefined
}

const WholeScene: React.FC<IWholeScene> = ({
  title,
  canCutting,
  url,
  id,
  time,
  current,
  intervalTime,
  addScreens,
}) => {
  const dispatch = useAppDispatch()

  const currentTime = useAppSelector((state) => state.video.currentTime)

  return (
    <div className={`${styles.wholeScene} ${current && styles.current}`}>
      <AddScreensButtons
        canCutting={canCutting}
        addScreens={() => addScreens(id, time, intervalTime)}
      />
      <img
        onClick={() => {
          console.log(
            time,
            timeNumberToString(time),
            time === currentTime,
            time == currentTime,
            currentTime
          )
          dispatch(changedTime({ time, place: 'WholeScene' }))
        }}
        className={styles.wholeScene__image}
        src={url}
        alt='testImage'
        draggable={false}
        title={timeNumberToString(time)}
        //@ts-ignore
        _time={time}
      />
    </div>
  )
}

export default React.memo(WholeScene)
