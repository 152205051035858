import React, { RefObject } from 'react'
import cutSceneOutVideo from '../../../../GlobalFunctions/cutSceneOutVideo'
import timeNumberToString from '../../../../GlobalFunctions/timeNumberToString'
import useInput from '../../../../Hooks/useInput'
import { addChapter } from '../../../../Store/Chapters/actions'
import { useAppDispatch } from '../../../../Store/hooks'
import Button from '../../../../UI/Button'
import Input from '../../../../UI/Input'
import { IVideosChapterStateChoices } from '../../../../__generated__/types'
import styles from './index.module.scss'

interface IChapterModalProps {
  setShowChapterModal: (setShowChapterModal: boolean) => void

  childVideoRef: RefObject<HTMLVideoElement>
}

const ChapterModal: React.FC<IChapterModalProps> = ({
  setShowChapterModal,
  childVideoRef,
}) => {
  const input = useInput('', 'Type chapter name', true, 'name', undefined)
  const dispatch = useAppDispatch()
  return (
    <div className={styles.chapterModalContainer}>
      <div
        className={styles.background}
        onClick={() => setShowChapterModal(false)}
      ></div>
      <div className={styles.modalContainer}>
        <p className={styles.modalContainer__title}>Input Chapter Name</p>
        <p className={styles.modalContainer__title}>
          time {timeNumberToString(childVideoRef.current?.currentTime || 0)} s.
        </p>
        <div className={styles.modalContainer__buttonContainer}>
          <Input {...input.default} />
          <Button
            onClick={() => {
              console.log({
                id: Date.now(),
                name: input.inputValue,
                imgURL: cutSceneOutVideo(childVideoRef.current),
                test: childVideoRef.current,
                time: childVideoRef.current?.currentTime || 0,
              })
              dispatch(
                addChapter({
                  state: IVideosChapterStateChoices.New,
                  id: '',
                  key: String(Date.now()),
                  name: input.inputValue,
                  imgURL: cutSceneOutVideo(childVideoRef.current),
                  time: String(childVideoRef.current?.currentTime || 0),
                })
              )
              setShowChapterModal(false)
            }}
            title='Save chapter name'
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ChapterModal)
