import inaccurateSceneWidth from '../../Constants/inaccurateSceneWidth'
import checkInterval from '../../GlobalFunctions/checkInterval'
import { useAppSelector } from '../../Store/hooks'
import AccurateScene from './AccurateScene'
import styles from './index.module.scss'
import React, { useEffect, useMemo, useState } from 'react'

interface INavigationAccurateProps {
  isMobile: boolean
}

const NavigationAccurate: React.FC<INavigationAccurateProps> = ({
  isMobile,
}) => {
  const durationTime = useAppSelector((state) => state.video.durationTime)
  const currentTime = useAppSelector((state) => state.video.currentTime)

  const [screenWidth, setScreenWidth] = useState(() => window.innerWidth - 20)
  // Убрать говно код
  let arr = []
  for (let i = 0; i < durationTime; i += 0.25) {
    arr.push(i)
  }

  const interval = useMemo(
    () =>
      Math.round(
        durationTime / ((window.innerWidth - 20) / inaccurateSceneWidth)
      ),
    [durationTime]
  )

  const width = useMemo(
    () => screenWidth / Math.ceil(durationTime / interval) / (interval / 0.25),
    [durationTime, interval, screenWidth]
  )
  console.log(width * 8)

  // console.log(screenWidth, 'начальная ширина аккурат')

  useEffect(() => {
    console.log('trigger')

    const windowResize = () => {
      setScreenWidth(window.innerWidth - 20)
    }
    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [screenWidth])

  return (
    <div className={styles.navigationAccurate}>
      {arr.map((el) => (
        <AccurateScene
          key={el}
          time={el}
          isMobile={isMobile}
          isActive={checkInterval(0.25, currentTime, el)}
          width={width}
          interval={interval}
        />
      ))}
    </div>
  )
}

export default React.memo(NavigationAccurate)
