import { changePrevURLFromLogin } from '../Store/Common/actions'
import { changeUserIsAuth, userSignIn } from '../Store/User/actions'
import { useAppDispatch, useAppSelector } from '../Store/hooks'
import EditPageCommon from './EditPageCommon/EditPageCommon'
import EditPageMobile from './EditPageCommon/EditPageMobile'
import HomePage from './HomePage/HomePage'
import HowToUse from './HowToUse/HowToUse'
import Library from './Library/Library'
import LoginPage from './LoginPage'
import NewPage from './NewPage'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import ProjectsPage from './ProjectsPage'
import RegisterPage from './RegisterPage'
import TermsOfUse from './TermsOfUse/TermsOfUse'
import UploadPage from './UploadPage'
import WatchPageCommon from './WatchPageCommon/WatchPageCommon'
import { useMeQuery } from './__generated__/me'
import { useRefreshTokenMutation } from './__generated__/refreshToken'
import { useVerifyTokenMutation } from './__generated__/verifyToken'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { Route, Switch, useHistory } from 'react-router-dom'

// Компонент который контролирует роутинг+отвечает за авторизацию(по сохраненному токену)
const Routers: React.FC = () => {
  const dispatch = useAppDispatch()
  const isUserLogged = useAppSelector((state) => state.user.isAuth)
  const history = useHistory()

  const [skipQuery, setSkipQuery] = useState<boolean>(true)

  const { data, loading } = useMeQuery({
    skip: skipQuery,
  })

  const [verify] = useVerifyTokenMutation({
    onCompleted: (data) => {
      console.log(data)
      setSkipQuery(false)
      // localStorage.setItem('token', data.verifyToken.)
    },
    // onError: (data) => console.log("error:", data),
  })

  const [refresh] = useRefreshTokenMutation({
    onCompleted: (data) => {
      console.log(data)
    },
    // onError: (data) => console.log("error:", data),
  })

  useEffect(() => {
    const token = localStorage.getItem('token')
    console.log('token!', token)

    if (token)
      verify({ variables: { token } })
        .catch((e) => {
          console.log(e)
          refresh({
            variables: { token },
          }).catch((e) => {
            console.log(e)
            localStorage.removeItem('token')
            history.push('/login')
          })
        })
        .then(() => {
          setSkipQuery(false)
          dispatch(changeUserIsAuth(true))
        })
  }, [])

  useEffect(() => {
    if (!loading && data?.me) {
      console.log(data)
      dispatch(userSignIn(data.me))
    }
  }, [data, loading])

  const doRedirectForNoAuth = (component: ReactElement, url: string) => {
    if (isUserLogged) return component
    else {
      dispatch(changePrevURLFromLogin(url))
      return <Redirect to='/login' />
    }
  }

  return (
    <Switch>
      <Route exact path='/'>
        <HomePage />
      </Route>
      <Route exact path='/demo'>
        <EditPageCommon />
      </Route>
      <Route path='/login'>
        <LoginPage />
      </Route>
      <Route path='/signup'>
        <RegisterPage />
      </Route>
      <Route path='/upload'>
        <UploadPage />
      </Route>
      <Route path='/mobile'>
        <EditPageMobile id={''} />
      </Route>
      <Route path='/123'>
        <EditPageCommon />
      </Route>
      <Route path='/edit/:id'>
        <EditPageCommon />{' '}
      </Route>
      <Route path='/new'>
        <NewPage />
      </Route>
      <Route path='/projects'>
        <ProjectsPage />{' '}
      </Route>
      <Route exact path='/watch'>
        <Redirect to='/'></Redirect>
      </Route>
      <Route path='/watch/:id'>
        <WatchPageCommon />
      </Route>
      <Route path='/library'>
        <Library />
      </Route>

      <Route path='/privacy-policy'>
        <PrivacyPolicy />
      </Route>

      <Route path='/terms-of-use'>
        <TermsOfUse />
      </Route>

      <Route path='/how-to-use'>
        <HowToUse />
      </Route>
    </Switch>
  )
}

export default Routers
