import timeNumberToString from '../../../GlobalFunctions/timeNumberToString'
import { changedTime } from '../../../Store/Video/actions'
import { useAppDispatch } from '../../../Store/hooks'
import styles from './index.module.scss'
import React from 'react'

interface IChapterWatch {
  time: number
  name: string
}

const ChapterWatch: React.FC<IChapterWatch> = ({ time, name }) => {
  const dispatch = useAppDispatch()

  return (
    <div className={styles.chapter}>
      <span
        className={`${styles.chapter__time}  text-primary`}
        onClick={() => dispatch(changedTime({ time }))}
      >
        {timeNumberToString(time || 0)}
      </span>
      <p className={`${styles.chapter__title} text-base-content`}>{name}</p>
    </div>
  )
}

export default ChapterWatch
