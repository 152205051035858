import React from 'react'
import { useAppDispatch } from '../../../../Store/hooks'
import { changeDurationBlur } from '../../../../Store/Video/actions'
import styles from './index.module.scss'

interface IBlurScene {
  time: number
  isActive: boolean
  id: number
  first: any
  second: any
}
//Компонентами наполняется селектор блюра (зеленая полоса). Нужно иметь возможность менять время начал и конца блюра. Логику можно посмотреть в в2.
//сделаешь интерфейс который возвращает начало и конец времени при кликак по  временной шкале? или мне этим заняться?
const BlurScene: React.FC<IBlurScene> = ({
  time,
  isActive,
  id,
  first,
  second,
}) => {
  const dispatch = useAppDispatch()
  return (
    <div
      data-time={time}
      className={`${styles.blurScene} ${(isActive && styles.active) || ''} ${
        first && styles.firstTime
      } ${second && styles.lastTime}`}
      onClick={() => dispatch(changeDurationBlur(id, time))}
    ></div>
  )
}

export default React.memo(BlurScene)
