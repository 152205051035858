import { changeWatchPageView } from '../../Store/Common/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import React, { useCallback } from 'react'

interface IViewChangerProps {
  id: string
}

const ViewChanger: React.FC<IViewChangerProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  const watchPageView = useAppSelector((state) => state.common.watchPageView)

  const handleClickToView = (e: React.MouseEvent<HTMLButtonElement>) => {
    const pageView = (e.target as HTMLElement)?.dataset?.key

    if (pageView !== 'reset' && pageView) {
      dispatch(changeWatchPageView(pageView))
    } else {
      //Очищаем настройки сплитов для текущего видео
      localStorage.removeItem(`${id}splitOneTwoThreeFirst`)
      localStorage.removeItem(`${id}splitOneTwoThreeSecond`)
      localStorage.removeItem(`${id}splitOneTwoThreeThird`)
      localStorage.removeItem(`${id}splitFourFiveFirst`)
      localStorage.removeItem(`${id}splitFourFiveSecond`)
      localStorage.removeItem(`${id}splitFourFiveThird`)
      dispatch(changeWatchPageView('first'))
    }
  }

  const getClassesList = useCallback(
    (buttonKey: string) => {
      return `${
        watchPageView === buttonKey ? 'text-indigo-600' : 'text-gray-500'
      } ml-4 underline`
    },
    [watchPageView]
  )

  return (
    <div className='flex h-10 flex-row justify-end'>
      <div className='flex flex-row mr-8 items-center'>
        <p className='font-medium text-gray-900'>View:</p>
        <button
          className={getClassesList('first')}
          data-key='first'
          onClick={handleClickToView}
        >
          first
        </button>
        <button
          className={getClassesList('second')}
          data-key='second'
          onClick={handleClickToView}
        >
          second
        </button>
        <button
          className={getClassesList('third')}
          data-key='third'
          onClick={handleClickToView}
        >
          third
        </button>
        <button
          className={`text-gray-500 ml-4 underline`}
          data-key='reset'
          onClick={handleClickToView}
        >
          Reset to default
        </button>
      </div>
    </div>
  )
}

export default ViewChanger
