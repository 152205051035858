import Header from '../../Feature/Header'
import { useAppSelector } from '../../Store/hooks'
import IMG_1 from '../../assets/img/how-to-use/1.png'
import IMG_2 from '../../assets/img/how-to-use/2.png'
import IMG_3 from '../../assets/img/how-to-use/3.png'
import IMG_4 from '../../assets/img/how-to-use/4.png'
import IMG_5 from '../../assets/img/how-to-use/5.png'
import IMG_6 from '../../assets/img/how-to-use/6.png'
import IMG_7 from '../../assets/img/how-to-use/7.png'
import styles from './HowToUse.module.scss'
import clsx from 'clsx'
import React from 'react'

const HowToUse = () => {
  const currentTheme = useAppSelector((state) => state.common.currentTheme)

  return (
    <div data-theme={currentTheme} className={styles.howToUse}>
      <Header />

      <section className={clsx('container mx-auto px-4')}>
        <h1>How to use</h1>
      </section>

      <section className={clsx('container mx-auto px-4', styles.content)}>
        <h2>Click the "New Project" button to create a new project.</h2>
        <img src={IMG_1} alt='image' />

        <h2>Upload your pre-recorded app video.</h2>
        <img src={IMG_2} alt='image' />

        <h2>Select the necessary screenshots and annotate them.</h2>
        <img src={IMG_3} alt='image' />

        <h2>Save the project and go to "My Project" to view it.</h2>
        <img src={IMG_4} alt='image' />

        <h2>Find the project in the list of projects and open it.</h2>

        <p>
          There are two modes for opening a project: viewing the project and
          communicating with the team (this feature is currently under
          development) and editing the project.
        </p>
        <img src={IMG_5} alt='image' />

        <h2>Dashboard Viewing Mode</h2>
        <p>
          In viewing mode, you can explore each screenshot, and clicking on one
          will take you to the video moment it captures. You can also leave
          comments under each screenshot (this feature is currently under
          development).
        </p>
        <img src={IMG_6} alt='image' />

        <h2>Dashboard Editing Mode</h2>
        <p>
          In editing mode, you can add or remove screenshots and change
          annotations.
        </p>
        <img src={IMG_7} alt='image' />
      </section>
    </div>
  )
}

export default HowToUse
