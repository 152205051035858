import {
  CommonType,
  IChangeCurrentTheme,
  IChangeFunkFromLogin,
  IChangeIsCuttingLowerTimeLine,
  IChangeIsPressToCutCenterTimeLine,
  IChangeNumberSceneScrollerDie,
  IChangePrevURLFromLogin,
  IChangeWatchPageView,
  IChangeWhenTakeState,
  TakeStatePlaces,
} from './types'

export const changeCurrentTheme = (
  currentTheme: string
): IChangeCurrentTheme => ({
  type: CommonType.CHANGE_CURRENT_THEME,
  currentTheme,
})

export const changeWatchPageView = (
  watchPageView: string
): IChangeWatchPageView => ({
  type: CommonType.CHANGE_WATCH_PAGE_VIEW,
  watchPageView,
})

export const changeIsCuttingLowerTimeLine = (
  isCuttingLowerTimeLine: boolean
): IChangeIsCuttingLowerTimeLine => ({
  type: CommonType.CHANGE_IS_CUTTING_LOWER_TIME_LINE,
  isCuttingLowerTimeLine,
})

export const changePrevURLFromLogin = (
  prevURLFromLogin: string
): IChangePrevURLFromLogin => ({
  type: CommonType.CHANGE_PREV_URL_FROM_LOGIN,
  prevURLFromLogin,
})

export const changeWhenTakeState = (
  whenTakeState: TakeStatePlaces
): IChangeWhenTakeState => ({
  type: CommonType.CHANGE_WHEN_TAKE_STATE,
  whenTakeState,
})

export const changeFuncFromLogin = (
  funcFromLogin: () => void
): IChangeFunkFromLogin => ({
  type: CommonType.CHANGE_FUNC_FROM_LOGIN,
  funcFromLogin,
})

export const changeIsPressToCutCenterTimeLine = (
  isPressToCutCenterTimeLine: boolean
): IChangeIsPressToCutCenterTimeLine => ({
  type: CommonType.CHANGE_IS_PRESS_TO_CUT_CENTER_TIME_LINE,
  isPressToCutCenterTimeLine,
})

export const changeNodeSceneScroller = (
  DOMNode: HTMLElement
): IChangeNumberSceneScrollerDie => ({
  type: CommonType.CHANGE_NODE_SCENE_SCROLLER,
  nodeSceneScroller: DOMNode,
})
