import idSceneScroller from '../../Constants/idSceneScroller'
import checkInterval from '../../GlobalFunctions/checkInterval'
import cutScreenWithTime, {
  IcutScreenWithTime,
} from '../../GlobalFunctions/cutScreenWithTime'
import useMouseMoveScroll from '../../Hooks/useMouseMoveScroll'
import useMouseWheelScroll from '../../Hooks/useMouseWheelScroll'
import {
  changeIsPressToCutCenterTimeLine,
  changeNodeSceneScroller,
} from '../../Store/Common/actions'
import {
  addScreensFromScope,
  changeCanAddedCutting,
  changeCanCutting,
} from '../../Store/Screens/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import Button from '../../UI/Button'
import WholeScene from './WholeScene'
import styles from './index.module.scss'
import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'

interface ISceneScroller {
  setSceneScrollerHeight?: (setSceneScrollerHeight: number) => void
}
// Вынести большую часть логики в хуки нечитабельно
const SceneScroller: React.FC<ISceneScroller> = ({
  setSceneScrollerHeight,
}) => {
  const dispatch = useAppDispatch()

  const currentTime = useAppSelector((state) => state.video.currentTime)
  const paused = useAppSelector((state) => state.video.paused)
  const screens = useAppSelector((state) => state.screens.screens)
  const changedTarget = useAppSelector((state) => state.video.changedTime.place)
  const intervalCuttingScreens = useAppSelector(
    (state) => state.screens.intervalCuttingScreens
  )
  const duration = useAppSelector((state) => state.video.durationTime)
  const isCuttingLowerTimeLine = useAppSelector(
    (state) => state.common.isCuttingLowerTimeLine
  )
  const isPressToCutCenterTimeLine = useAppSelector(
    (state) => state.common.isPressToCutCenterTimeLine
  )

  const currentScreen = screens.findIndex((screen, index) => {
    if (index === 0)
      return checkInterval(intervalCuttingScreens, currentTime, 0)
    else return checkInterval(intervalCuttingScreens, currentTime, screen.time)
  })

  console.log('current: ', currentTime, currentScreen)

  const videoUrl = useAppSelector((state) => state.video.url)

  const ref = useRef<HTMLDivElement>(null)

  const scope = (
    idScreen: string,
    time: number,
    intervalCutting: number = intervalCuttingScreens
  ) => {
    dispatch(changeCanCutting(idScreen, false))

    const videoNoHide = document.getElementById(videoUrl)
    const newIntervalCutting = intervalCutting / 10
    const { height, width } = videoNoHide?.getBoundingClientRect() || {}
    console.log('intervalCutting: ', intervalCutting)

    const video: any = document.getElementById('videoHidden')
    let screens: IcutScreenWithTime
    if (time === 0) {
      video.currentTime = time + newIntervalCutting
      screens = cutScreenWithTime(
        idScreen,
        video || null,
        time + newIntervalCutting,
        newIntervalCutting,
        5,
        height,
        width,
        true
      )
      const int = setInterval(() => {
        console.log('tick start', screens.imgs.length)

        if (screens.imgs.length >= 5) {
          dispatch(addScreensFromScope(screens.imgs))
          screens.removeLisner && screens.removeLisner()
          dispatch(changeCanAddedCutting(true))
          clearInterval(int)
        }
      }, 200)
    } else if (time.toFixed(5) === duration.toFixed(5)) {
      video.currentTime = time - 5 * newIntervalCutting
      screens = cutScreenWithTime(
        idScreen,
        video || null,
        time - 5 * newIntervalCutting,
        newIntervalCutting,
        4,
        height,
        width,
        false,
        true
      )
      const int = setInterval(() => {
        if (screens.imgs.length >= 4) {
          dispatch(addScreensFromScope(screens.imgs))
          screens.removeLisner && screens.removeLisner()
          dispatch(changeCanAddedCutting(true))
          clearInterval(int)
        }
      }, 200)
    } else {
      video.currentTime = time - 4 * newIntervalCutting
      screens = cutScreenWithTime(
        idScreen,
        video || null,
        time - 4 * newIntervalCutting,
        newIntervalCutting,
        10,
        height,
        width
      )
      const int = setInterval(() => {
        console.log(screens.imgs.length, screens.imgs)

        if (screens.imgs.length >= 9) {
          dispatch(addScreensFromScope(screens.imgs))
          screens.removeLisner && screens.removeLisner()
          dispatch(changeCanAddedCutting(true))
          clearInterval(int)
        }
      }, 200)
    }
  }

  useEffect(() => {
    if (ref.current) {
      if (changedTarget && paused) {
      } else if (currentScreen > -1) {
        ref.current.scrollTo({
          left:
            (currentScreen * (ref.current.scrollWidth || 0)) / screens.length,
        })
      } else if (currentScreen === 0) {
        ref.current.scrollTo({
          left: 0,
        })
      }
    }
  }, [ref, currentScreen, paused, changedTarget, currentTime])

  useMouseMoveScroll(ref)

  useMouseWheelScroll(ref)

  useEffect(() => {
    if (ref.current) {
      const chapterSelectorResize = () => {
        if (setSceneScrollerHeight) {
          setSceneScrollerHeight(
            ref.current?.getBoundingClientRect().height || 0
          )
        }
      }
      chapterSelectorResize()
    }
  }, [screens.length])

  useEffect(() => {
    //@ts-ignore
    dispatch(changeNodeSceneScroller(document.getElementById(idSceneScroller)))
    return () => {
      console.log('Scene Scroller is die')
    }
  }, [])

  return (
    <div
      className={clsx(styles.sceneScroller, 'p-3')}
      ref={ref}
      id={idSceneScroller}
    >
      {!isCuttingLowerTimeLine && isPressToCutCenterTimeLine ? (
        screens.map((scene, index) => (
          <WholeScene
            canCutting={scene.canCutting}
            id={scene.id}
            addScreens={scope}
            index={index}
            key={scene.id}
            url={scene.url}
            time={index === 0 ? 0 : scene.time}
            current={index === currentScreen}
            intervalTime={scene.intervalCutting}
          />
        ))
      ) : (
        <>
          {isCuttingLowerTimeLine && isPressToCutCenterTimeLine ? (
            <>Please wait cutting lower timeline</>
          ) : (
            // <Button
            //   title='start cutting'
            //   onClick={() => dispatch(changeIsPressToCutCenterTimeLine(true))}
            // />
            <></>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(SceneScroller)
