const typenameUpdateVideoWithoutVideo = [
  'NoAccessError',
  'TooLongName',
  'UserNotAuthenticated',
  'VideoAlreadyUploaded',
  'VideoNotFound',
  'VideoUnderProcessing',
  'WrongVideoInput',
]

export default typenameUpdateVideoWithoutVideo
