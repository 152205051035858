import { IVideoState, VideoAction, VideoType } from './types'

const initialState: IVideoState = {
  id: '',
  editable: true,
  url: '',
  name: '',
  icon: '',
  description: '',
  website: '',
  blurs: [
    // {
    //   id: 5454531,
    //   startTime: 15,
    //   endTime: 20,
    //   point: {
    //     x: 200,
    //     y: 400,
    //   },
    //   width: 100,
    //   heigth: 100,
    // },
  ],
  changedTime: { time: 0 },
  durationTime: 0,
  currentTime: 0,
  paused: true,
}

export const videoReduser = (
  state = initialState,
  action: VideoAction
): IVideoState => {
  switch (action.type) {
    case VideoType.CHANGE_CURRENT_TIME_AND_PAUSED:
      return {
        ...state,
        currentTime: action.currentTime,
        paused: action.paused,
      }
    case VideoType.ADD_BLUR:
      return {
        ...state,
        blurs: state.blurs.concat(action.blur),
      }
    case VideoType.DELETE_BLUR:
      return {
        ...state,
        blurs: state.blurs.filter((blur) => blur.id !== action.id),
      }
    case VideoType.ADD_VIDEO:
      return {
        ...action.video,
      }
    case VideoType.CHANGED_TIME:
      return {
        ...state,
        changedTime: action.changedTime,
      }
    case VideoType.CHANGE_DURATION_TIME:
      return {
        ...state,
        durationTime: action.durationTime,
      }
    case VideoType.CHANGE_DURATION_BLUR:
      const newBlur = state.blurs.find((blur) => blur.id === action.id)
      const position = state.blurs.findIndex((blur) => blur.id === action.id)

      if (newBlur && position !== -1) {
        if (action.time < newBlur.startTime) newBlur.startTime = action.time
        else if (action.time > newBlur.endTime) newBlur.endTime = action.time
        else if (
          action.time > newBlur.startTime &&
          action.time < newBlur.endTime
        ) {
          if (
            newBlur.endTime - action.time <
            (newBlur.endTime - newBlur.startTime) / 2
          )
            newBlur.endTime = action.time
          else newBlur.startTime = action.time
        }

        return {
          ...state,
          blurs: [
            ...state.blurs
              .filter((blur) => blur.id !== action.id)
              .slice(0, position),
            newBlur,
            ...state.blurs
              .filter((blur) => blur.id !== action.id)
              .slice(position),
          ],
        }
      } else
        return {
          ...state,
        }
    case VideoType.CHANGE_URL_VIDEO_GQL:
      return {
        ...state,
        url: action.url,
      }
    case VideoType.CLEAR_VIDEO:
      return initialState
    case VideoType.CHANGE_ID_VIDEO:
      return {
        ...state,
        id: action.id,
      }
    case VideoType.CHANGE_VIDEO_NAME:
      return {
        ...state,
        name: action.name,
      }
    default:
      return state
  }
}
