import React from 'react'
import { useAppSelector } from '../../../../Store/hooks'
import styles from './index.module.scss'

interface AddScreensButtonProps {
  canCutting: boolean | undefined
  addScreens: () => void
}
// сделать переменную для понятия че происходит в рисовке
const AddScreensButton: React.FC<AddScreensButtonProps> = ({
  addScreens,
  canCutting,
}) => {
  const canAddScreens = useAppSelector((state) => state.screens.canAddCutting)

  if (canCutting === false) return <>Was Cutting</>

  return canAddScreens ? (
    <p className={styles.wholeScene__p} onClick={addScreens}>
      +
    </p>
  ) : (
    <>Wait</>
  )
}

export default React.memo(AddScreensButton)
