import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { DRY_RUN_CREATE_USER } from '../../Constants/build'
import ButtonAuth from '../../Feature/ButtonAuth'
import useInput from '../../Hooks/useInput'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { userToken } from '../../Store/User/actions'
import Button from '../../UI/Button'
import Input from '../../UI/Input'
import { useTokenAuthMutation } from '../LoginPage/__generated__/tokenAuth'
import styles from './index.module.scss'
import { useCreateUserMutation } from './__generated__/createUser'

const RegisterPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const history = useHistory()

  const prevURLFromLogin = useAppSelector(
    (state) => state.common.prevURLFromLogin
  )

  const client = useApolloClient()

  const [tokenAuth, { error: errorToken }] = useTokenAuthMutation({
    onCompleted: (data) => {
      if (data.tokenAuth?.token) {
        localStorage.setItem('token', data.tokenAuth.token)
        dispatch(userToken(data.tokenAuth.token))
      }
      client.resetStore()
      history.push(prevURLFromLogin || '/')
    },
  })

  const [createUser, { error: errorCreate }] = useCreateUserMutation({
    onCompleted: (data) => {
      // history.push("/");
    },
  })

  const login = useInput('', 'Введите email', true, 'email')
  const password = useInput('', 'Введите password', true)

  const submitForm = (dryRun: boolean, email: string, password: string) => {
    console.log('submit:', email, password)
    createUser({
      variables: {
        dryRun,
        email,
        password,
      },
    })
      .catch((e) => console.log(e))
      .then(() => {
        tokenAuth({
          variables: {
            email,
            password,
          },
        }).catch((e) => console.log(e))
      })
  }

  return (
    <div className={styles.registerPage}>
      <h3>Sign Up</h3>
      <form
        className={
          errorCreate || errorToken
            ? `${styles.registerForm_error} ${styles.registerForm} `
            : styles.registerForm
        }
        onSubmit={(e) => {
          e.preventDefault()
          submitForm(DRY_RUN_CREATE_USER, login.inputValue, password.inputValue)
        }}
      >
        <ButtonAuth text='Sign Up with Google' />
        <label className={styles.registerForm__label}>email: </label>
        <Input {...login.default} />
        <label className={styles.registerForm__label}>password: </label>
        <Input {...password.default} />
        <Button isError={errorCreate || errorToken} title='Sign Up' />
        {errorCreate && (
          <p className={styles.registerForm__errorText}>
            {errorCreate.message}
          </p>
        )}
        {errorToken && (
          <p className={styles.registerForm__errorText}>{errorToken.message}</p>
        )}
      </form>
    </div>
  )
}

export default RegisterPage
