import Header from '../../Feature/Header'
import { useAppSelector } from '../../Store/hooks'
import LIBRARY_SCREENSHOT_1 from '../../assets/img/library/1.png'
import LIBRARY_SCREENSHOT_2 from '../../assets/img/library/2.png'
import LIBRARY_SCREENSHOT_3 from '../../assets/img/library/3.png'
import styles from './Library.module.scss'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'

const Library = () => {
  const currentTheme = useAppSelector((state) => state.common.currentTheme)
  return (
    <div data-theme={currentTheme}>
      <Header />
      <div className={clsx('container mx-auto', styles.library)}>
        <h1>Library</h1>
        <div className='grid grid-cols-3 gap-24'>
          <Link to='/watch/qqL-TA'>
            <h2>Headway</h2>
            <img src={LIBRARY_SCREENSHOT_1} alt='' />
          </Link>
          <Link to='/watch/oKOh0A'>
            <h2>Calm</h2>
            <img src={LIBRARY_SCREENSHOT_2} alt='' />
          </Link>
          <Link to='/watch/MkoXCA'>
            <h2>Roblox</h2>
            <img src={LIBRARY_SCREENSHOT_3} alt='' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Library
