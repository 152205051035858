import FEATURES_SHORT_1 from '../../../assets/img/features-short/1.png'
import FEATURES_SHORT_2 from '../../../assets/img/features-short/2.png'
import FEATURES_SHORT_3 from '../../../assets/img/features-short/3.png'
import FEATURES_1 from '../../../assets/img/features/1.png'
import FEATURES_2 from '../../../assets/img/features/2.png'
import FEATURES_3 from '../../../assets/img/features/3.png'
import SCREENSHOTS_1 from '../../../assets/img/screenshots/1.png'
import SCREENSHOTS_2 from '../../../assets/img/screenshots/2.png'
import SCREENSHOTS_3 from '../../../assets/img/screenshots/3.png'

export const FEATURES_SHORT = {
  items: [
    {
      img: FEATURES_SHORT_1,
      title: 'Record',
      description: 'app’s video',
      screenshot: SCREENSHOTS_1,
    },
    {
      img: FEATURES_SHORT_2,
      title: 'Annotate',
      description: 'screenshots',
      screenshot: SCREENSHOTS_2,
    },
    {
      img: FEATURES_SHORT_3,
      title: 'Collaborate',
      description: 'comment and discuss',
      screenshot: SCREENSHOTS_3,
    },
  ],
}

export const CUSTOMERS = {
  title: 'customers',
  items: ['Product & Marketing', 'Designers', 'Developers'],
  style: {
    background: '#F5F5F5',
  },
}

export const FEATURES = {
  title: 'Values',
  items: [
    {
      img: FEATURES_1,
      title: 'Time-saving',
    },
    {
      img: FEATURES_2,
      title: 'Discover insights',
    },
    {
      img: FEATURES_3,
      title: 'Get inspiration',
    },
  ],
}
