import BlurRectangle from '../BlurRectangle'
import CuttingVideoContainer from '../CuttingVideoContainer'
import Blurs from './Blurs'
import VideoElement from './VideoElement'
import styles from './index.module.scss'
import React, { RefObject } from 'react'

interface VideoContainerProps {
  videoRef: RefObject<HTMLVideoElement>
  videoPath: string
  aspectVideo: number
  withBlurRectangle?: boolean
}
// Непонятные условния вынести в переменную
const VideoContainer: React.FC<VideoContainerProps> = ({
  videoRef,
  videoPath,
  aspectVideo,
  withBlurRectangle,
}) => {
  return (
    <div
      className={
        aspectVideo < 1
          ? `${styles.video}`
          : `${styles.video} ${styles.video_horizontal}`
      }
    >
      <div className={styles.videoContainer}>
        <VideoElement
          aspectVideo={aspectVideo}
          videoPath={videoPath}
          videoRef={videoRef}
          isMobile={false}
        />

        <CuttingVideoContainer url={videoPath} />
        {(withBlurRectangle && (
          <>
            <BlurRectangle videoRef={videoRef} />
            <Blurs videoPath={videoPath} />
          </>
        )) ||
          null}
      </div>
    </div>
  )
}

export default React.memo(VideoContainer)
