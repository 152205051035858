import { IScreen } from '../Store/Screens/types'

interface ICuttingVideoScreensProps {
  imgs: IScreen[]
  removeLisner: (() => void) | undefined
}

/* 
Суть: береться видео, при загрузке метаданных начинается нарезка видео, тоесть мы меняем время видео,
после этого срабатывает event 'seeked', делаем скриншот, опять меняем время и т.д. 
*/

const cuttingVideoScreens = (
  video: HTMLVideoElement | null,
  intervalTime: number,
  heightVideo?: number,
  widthVideo?: number
): ICuttingVideoScreensProps => {
  if (!video) {
    return {
      imgs: [
        {
          url: 'null',
          id: Math.random().toString(32).substr(2, 12),
          time: 0,
        },
      ],
      removeLisner: undefined,
    }
  }

  let step = 0
  const canvas = document.createElement('canvas')

  const { height, width } = video.getBoundingClientRect()

  console.log('size', height, width, video)
  console.log(heightVideo, widthVideo)

  // Для скрытого видео чтобы были скрины такого же размера как и у видимого видео
  if (heightVideo && widthVideo) {
    canvas.width = widthVideo
    canvas.height = heightVideo
  } else {
    canvas.width = width
    canvas.height = height
  }

  const imgs: IScreen[] = []

  const doScreen = (time: number) => {
    const context = canvas.getContext('2d')

    // Логгер для теста на остановку нарезания видео
    // console.log(
    //   'step: ' + step,
    //   'currentTime: ' + video.currentTime,
    //   'duration: ' + video.duration,
    //   'context: ',
    //   canvas,
    //   'imgs:',
    //   imgs
    // )

    // Для скрытого видео чтобы были скрины такого же размера как и у видимого видео
    if (heightVideo && widthVideo) {
      context?.drawImage(video, 0, 0, widthVideo, heightVideo)
    } else {
      context?.drawImage(video, 0, 0, width, height)
    }

    imgs.push({
      url: canvas.toDataURL(undefined, 0.5),
      id: Math.random().toString(32).substr(2, 12),
      time,
    })
  }

  const seeked = (e: any) => {
    doScreen(video.currentTime)
    step += intervalTime
    // console.log(
    //   'seeked',
    //   'step: ' + step,
    //   'duration: ' + video.duration,
    //   'step <= video.duration=' + (step <= video.duration)
    // )

    if (step <= video.duration) video.currentTime = step
    else {
      video.removeEventListener('seeked', seeked)
    }
  }
  video.addEventListener('loadeddata', function () {
    this.currentTime = step
  })
  video.addEventListener('seeked', seeked)
  return {
    imgs: imgs,
    removeLisner: () => {
      console.log('removed Seeked')

      video.removeEventListener('seeked', seeked)
    },
  }
}

export default cuttingVideoScreens
