import { changedTime } from '../../../Store/Video/actions'
import { useAppDispatch } from '../../../Store/hooks'
import styles from './index.module.scss'
import React, { memo } from 'react'

interface IInnacurateScene {
  title?: string
  time: number
  url: string
  width?: number
}

const InaccurateScene: React.FC<IInnacurateScene> = ({
  title,
  time,
  url,
  width,
}) => {
  //Компонент хранит в себе изначально отображаемую пользователю сцену (самый нижний ряд),
  // при клике переключает видео на свой тайм(соотв получает тайм в себя)

  const dispatch = useAppDispatch()

  return (
    <div
      className={styles.innacurateScene}
      onClick={() => dispatch(changedTime({ time }))}
    >
      <p className={styles.innacurateScene__p}>{title}</p>
      <img
        className={styles.innacurateScene__image}
        src={url}
        alt='testImage'
        data-time={time}
        style={{ maxWidth: width + 'px' }}
      />
    </div>
  )
}

export default memo(InaccurateScene)
