const checkInterval = (
  timeInterval: number,
  timeVideo: number,
  timeScreen: number
): boolean => {
  if (
    timeScreen <= timeVideo &&
    timeScreen + (9 * timeInterval) / 10 > timeVideo
  ) {
    console.log(
      'checkInterval: ',
      timeScreen,
      timeVideo,
      timeScreen <= timeVideo,
      timeInterval,
      timeScreen + timeInterval
    )

    return true
  } else return false
}

export default checkInterval
