import checkIntervalBlur from '../../../GlobalFunctions/checkIntervalBlur'
import { IBlur } from '../../../Store/Video/types'
import { useAppSelector } from '../../../Store/hooks'
import styles from './index.module.scss'
import { useEffect, useState } from 'react'

interface BlursProps {
  videoPath: string
}
// Вынести логику в хуки
const Blurs: React.FC<BlursProps> = ({ videoPath }) => {
  const blurs = useAppSelector((state) => state.video.blurs)

  const [currentBlurs, setCurrentBlurs] = useState<IBlur[] | undefined>()

  let checkCurrentBlur: NodeJS.Timeout

  useEffect(() => {
    clearInterval(checkCurrentBlur)

    const video: any = document.getElementById(videoPath)

    checkCurrentBlur = setInterval(() => {
      setCurrentBlurs(
        blurs.filter((blur) =>
          checkIntervalBlur(
            Number(video.currentTime),
            blur.startTime,
            blur.endTime
          )
        )
      )
    }, 50)
    return () => {
      clearInterval(checkCurrentBlur)
    }
  }, [blurs])
  // не, тут надо через useRef и мб через React Context
  const video: any = document.getElementById(videoPath)

  return (
    <>
      {currentBlurs?.map((blur) => (
        <div
          className={styles.blur}
          style={{
            left: blur.point.x + 'px',
            top: blur.point.y + 'px',
            height: blur.heigth + 'px',
            width: blur.width + 'px',
          }}
        ></div>
      ))}
    </>
  )
}

export default Blurs
