import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../../__generated__/types'

const defaultOptions = {}
export type IProjectsQueryVariables = Types.Exact<{ [key: string]: never }>

export type IProjectsQuery = {
  __typename?: 'Query'
  projects?:
    | {
        __typename?: 'Project'
        video?:
          | Array<
              | {
                  __typename?: 'Video'
                  id?: string | null | undefined
                  name: string
                  state: Types.IVideosVideoStateChoices
                  icon?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export const ProjectsDocument = gql`
  query projects {
    projects {
      video {
        id
        name
        state
        icon
      }
    }
  }
`

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<IProjectsQuery, IProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IProjectsQuery, IProjectsQueryVariables>(
    ProjectsDocument,
    options
  )
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IProjectsQuery,
    IProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IProjectsQuery, IProjectsQueryVariables>(
    ProjectsDocument,
    options
  )
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>
export type ProjectsLazyQueryHookResult = ReturnType<
  typeof useProjectsLazyQuery
>
export type ProjectsQueryResult = Apollo.QueryResult<
  IProjectsQuery,
  IProjectsQueryVariables
>
