import {
  ChaptersType,
  IAddChapter,
  IAddChapters,
  IChangeChapterName,
  IChapterMy,
  IClearChapters,
  IDeleteChapter,
} from './types'

export const addChapter = (chapter: IChapterMy): IAddChapter => ({
  type: ChaptersType.ADD_CHAPTER,
  chapter,
})

export const deleteChapter = (key: string): IDeleteChapter => ({
  type: ChaptersType.DELETE_CHAPTER,
  key,
})

export const changeChapterName = (
  name: string,
  key: string
): IChangeChapterName => ({
  type: ChaptersType.CHANGE_CHAPTER_NAME,
  name,
  key,
})

export const clearChapters = (): IClearChapters => ({
  type: ChaptersType.CLEAR_CHAPTERS,
})

export const addChapters = (chapters: IChapterMy[]): IAddChapters => ({
  type: ChaptersType.ADD_CHAPTERS,
  chapters,
})
