import { useEffect, useState } from 'react'

// Позиция точек при опускании и отпускании кнопки мыши

interface IPosition {
  firstPoint: {
    x: number
    y: number
  }
  secondPoint: {
    x: number
    y: number
  }
}

// Отсортированная модель 2 точек - положение вверхнего левого угла с высотой и шириной блока

interface IPositionSort {
  startPoint: {
    x: number
    y: number
  }
  height: number
  width: number
}

// Функция для недопущения выхода блока за размеры видео

const testZoneVideo = (
  blur: IPositionSort,
  video: IPositionSort
): IPositionSort => {
  let blurX, blurY, blurHeight, blurWidth
  //<--
  //Проверка и корректировка позиции, чтобы блюр не выходил за рамки видео

  if (blur.startPoint.x < video.startPoint.x) {
    blurX = video.startPoint.x
  } else blurX = blur.startPoint.x

  if (blur.startPoint.y < video.startPoint.y) {
    blurY = video.startPoint.y
  } else blurY = blur.startPoint.y

  if (blur.startPoint.x + blur.width > video.startPoint.x + video.width) {
    blurWidth = video.startPoint.x + video.width - blur.startPoint.x
  } else if (blur.startPoint.x < video.startPoint.x) {
    blurWidth = blur.startPoint.x + blur.width - video.startPoint.x
  } else blurWidth = blur.width

  if (blur.startPoint.y + blur.height > video.startPoint.y + video.height) {
    blurHeight = video.startPoint.y + video.height - blur.startPoint.y
  } else if (blur.startPoint.y < video.startPoint.y) {
    blurHeight = blur.startPoint.y + blur.height - video.startPoint.y
  } else blurHeight = blur.height

  //-->

  return {
    startPoint: {
      x: blurX,
      y: blurY,
    },
    height: blurHeight,
    width: blurWidth,
  }
}

// Превращение 2 точек в точку с шириной и высотой блока

const findTopLeftAngleAndSort = (points: IPosition): IPositionSort => {
  const minX: number =
    points.firstPoint.x < points.secondPoint.x
      ? points.firstPoint.x
      : points.secondPoint.x
  const minY: number =
    points.firstPoint.y < points.secondPoint.y
      ? points.firstPoint.y
      : points.secondPoint.y

  return {
    startPoint: {
      x: minX,
      y: minY,
    },
    height: Math.abs(points.firstPoint.y - points.secondPoint.y),
    width: Math.abs(points.firstPoint.x - points.secondPoint.x),
  }
}

const initialStatePositionBlur = {
  firstPoint: { x: 0, y: 0 },
  secondPoint: { x: 0, y: 0 },
}

// Суть: Создать прямоугольный участок мышью(удержанием и перемешением мыши/пальца) над каким-то элементом не выходя за пределы элемента что находится под.
const useCreateRectangle = (
  refBlurElement: HTMLDivElement | null,
  refVideo: HTMLVideoElement | null
) => {
  const startTimeBlur = refVideo?.currentTime

  const [positionBlur, setPositionBlur] = useState<IPosition>(
    () => initialStatePositionBlur
  )

  const {
    left: videoX,
    top: videoY,
    height: videoHeight,
    width: videoWidth,
  } = refVideo?.getBoundingClientRect() || {
    left: 0,
    top: 0,
    height: 0,
    width: 0,
  }

  // Нормализованные данные блока, которые будут отправлены в компонент

  const [normalizePositionForReturn, setNormalizePositionForReturn] =
    useState<IPositionSort>(() =>
      testZoneVideo(findTopLeftAngleAndSort(positionBlur), {
        startPoint: {
          x: videoX,
          y: videoY,
        },
        height: videoHeight,
        width: videoWidth,
      })
    )

  useEffect(() => {
    setNormalizePositionForReturn(
      testZoneVideo(findTopLeftAngleAndSort(positionBlur), {
        startPoint: {
          x: videoX,
          y: videoY,
        },
        height: videoHeight,
        width: videoWidth,
      })
    )
  }, [positionBlur])

  //
  const [isMouseUp, setIsMouseUp] = useState<boolean>(false)

  // useEffect для изменения isMouseUp для document'a(чтобы при отпускании мыши пушились данные)

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      e.preventDefault()
      if (
        normalizePositionForReturn.width > 0 &&
        normalizePositionForReturn.height > 0
      )
        setIsMouseUp(true)
    }

    document.addEventListener('mouseup', handleMouseUp)
    return () => document.removeEventListener('mouseup', handleMouseUp)
  }, [normalizePositionForReturn])

  // useEffect для листенеров нажатия, движения мыши и пальца
  useEffect(() => {
    const handleMouseDown = (eventDown: any) => {
      refVideo?.removeAttribute('controls')

      const firstPoint = {
        x: eventDown.pageX,
        y: eventDown.pageY,
      }

      const handleMouseMove = (eventMove: any) => {
        const secondPoint = {
          x: eventMove.pageX,
          y: eventMove.pageY,
        }
        setPositionBlur({ firstPoint, secondPoint })
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', handleMouseMove)
        },
        { once: true }
      )

      document.addEventListener('touchmove', handleMouseMove)
      document.addEventListener(
        'touchend',
        () => {
          console.log('touch')

          document.removeEventListener('touchmove', handleMouseMove)
        },
        { once: true }
      )
    }

    const handleMouseUp = (eventUp: any) => {
      eventUp.preventDefault()
      refVideo?.setAttribute('controls', 'true')
    }

    const handleMouseClick = (eventClick: any) => {
      eventClick.preventDefault()
    }

    refVideo?.addEventListener('click', handleMouseClick)
    document.addEventListener('mouseup', handleMouseUp)
    refVideo?.addEventListener('mousedown', handleMouseDown)

    document.addEventListener('touchend', handleMouseUp)
    refVideo?.addEventListener('touchstart', handleMouseDown)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      refVideo?.removeEventListener('mousedown', handleMouseDown)
      refVideo?.removeEventListener('click', handleMouseClick)

      document.removeEventListener('touchend', handleMouseUp)
      refVideo?.removeEventListener('touchstart', handleMouseDown)
    }
  }, [refBlurElement, refVideo])

  // Стилизация блока над видео
  useEffect(() => {
    if (refBlurElement) {
      refBlurElement.style.left = normalizePositionForReturn.startPoint.x + 'px'
      refBlurElement.style.top = normalizePositionForReturn.startPoint.y + 'px'
      refBlurElement.style.height = normalizePositionForReturn.height + 'px'
      refBlurElement.style.width = normalizePositionForReturn.width + 'px'
    }
  }, [refBlurElement, normalizePositionForReturn])

  return {
    startTimeBlur,
    size: normalizePositionForReturn,
    reset: () => {
      setNormalizePositionForReturn({
        startPoint: {
          x: 0,
          y: 0,
        },
        height: 0,
        width: 0,
      })
      setIsMouseUp(false)
    },
    isMouseUp,
  }
}

export default useCreateRectangle
