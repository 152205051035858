import { changeUserIsAuth, userToken } from '../../Store/User/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { useSocialAuthMutation } from './__generated__/googleSignIn'
import styles from './index.module.scss'
import { useApolloClient } from '@apollo/client'
import { useState } from 'react'
import GoogleLogin from 'react-google-login'
import { useHistory } from 'react-router-dom'

interface IButtonAuthProps {
  text?: string
}
// Компонент для рисовки гугл логина+логики
const ButtonAuth: React.FC<IButtonAuthProps> = ({ text }) => {
  const dispatch = useAppDispatch()

  const client = useApolloClient()

  const history = useHistory()

  const prevURLFromLogin = useAppSelector(
    (state) => state.common.prevURLFromLogin
  )

  const funkFromLogin = useAppSelector((state) => state.common.funcFromLogin)

  const [loading, setLoading] = useState<boolean>(false)

  const [googleSignIn, { error }] = useSocialAuthMutation({
    onCompleted: (data) => {
      if (data?.socialAuth?.token) {
        localStorage.setItem('token', data.socialAuth.token)
        dispatch(userToken(data?.socialAuth.token))
        dispatch(changeUserIsAuth(true))
      }
      client.resetStore()
      if (funkFromLogin) {
        setLoading(true)
        funkFromLogin()
      } else history.push(prevURLFromLogin || '/')
    },
  })

  const responseGoogle = (e: any) => {
    googleSignIn({
      variables: {
        provider: 'google-oauth2',
        accessToken: e.accessToken,
      },
    })
  }

  return (
    <>
      {
        // Вынести loading В отдельный компонент, такая же конструкция есть и у login компонента
      }
      {loading && <p>Pending video...</p>}
      {error && <p>{`Error message: ${error}`}</p>}
      <GoogleLogin
        clientId='757233940512-l802jrgh4ggqk0o8p1u5napog10d0aj0.apps.googleusercontent.com'
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            className='btn btn-primary btn-outline'
          >
            <div className={styles.container}>
              <svg
                width='18'
                height='18'
                xmlns='http://www.w3.org/2000/svg'
                className={styles.icon}
              >
                <g fill='#000' fillRule='evenodd'>
                  <path
                    d='M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z'
                    fill='#EA4335'
                  />
                  <path
                    d='M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z'
                    fill='#4285F4'
                  />
                  <path
                    d='M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z'
                    fill='#FBBC05'
                  />
                  <path
                    d='M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z'
                    fill='#34A853'
                  />
                  <path fill='none' d='M0 0h18v18H0z' />
                </g>
              </svg>
              <span className={styles.text}>{text}</span>
            </div>
          </button>
        )}
        buttonText='Login'
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </>
  )
}

export default ButtonAuth
