import {
  GraphqlType,
  IChangeDoUpdateVideo,
  IChangeErrorVideoUpdate,
  IChangeFileUpload,
} from './types'

export const changeErrorVideoUpdate = (
  errorVideoUpdate: string
): IChangeErrorVideoUpdate => ({
  type: GraphqlType.CHANGE_ERROR_VIDEO_UPDATE,
  errorVideoUpdate,
})

export const changeDoUpdateVideo = (
  doUpdateVideo: boolean
): IChangeDoUpdateVideo => ({
  type: GraphqlType.CHANGE_DO_UPDATE_VIDEO,
  doUpdateVideo,
})

export const changeFileUpload = (file: File): IChangeFileUpload => ({
  type: GraphqlType.CHANGE_FILE_UPLOAD,
  file,
})
