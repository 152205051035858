import Spinner from '../../../Components/Spinner'
import ChapterScroller from '../../../Feature/ChapterScroller'
import ChapterSelector from '../../../Feature/ChapterSelector'
import Footer from '../../../Feature/Footer'
import Header from '../../../Feature/Header'
import ShareModalButton from '../../../Feature/ShareButton/ModalButton'
import VideoContainer from '../../../Feature/VideoContainer'
import ViewChanger from '../../../Feature/ViewChanger'
import {
  getWithExpiry,
  setWithExpiry,
} from '../../../GlobalFunctions/localStorageExpired'
import {
  IVideoQuery,
  useVideoQuery,
} from '../../../GraphqlQuery/__generated__/video'
import { addChapters } from '../../../Store/Chapters/actions'
import { changeCurrentTheme } from '../../../Store/Common/actions'
import { changeScreenSize } from '../../../Store/Screens/actions'
import {
  changeCurrentTime,
  changeIDVideo,
  durationTime,
} from '../../../Store/Video/actions'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import { IVideosChapterStateChoices } from '../../../__generated__/types'
import AppInfo from './AppInfo'
import Comments from './Comments'
import styles from './index.module.scss'
import { useApolloClient } from '@apollo/client'
import Splitter, { SplitDirection } from '@devbookhq/splitter'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

interface WatchPageProps {
  id: string
}

const WatchPage: React.FC<WatchPageProps> = ({ id }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [videoReady, setVideoReady] = useState(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [dataVideo, setDataVideo] = useState<IVideoQuery['video'] | null>(null)
  const [videoPath, setVideoPath] = useState<string>('')
  const [aspectVideo, setAspectVideo] = useState<number>(1)

  const [isChapterSelectorShown, setIsChapterSelectorShown] = useState(true)

  const chapterSelectorShowHandler = () => {
    if (isChapterSelectorShown) {
      setIsChapterSelectorShown(false)
      console.log('close')
    } else {
      setIsChapterSelectorShown(true)
      console.log('open')
    }
  }

  const { data, loading, error } = useVideoQuery({
    skip,
    variables: { id },
  })
  const client = useApolloClient()

  const videoRef = useRef<HTMLVideoElement>(null)
  const [chapterSelectorHeight, setChapterSelectorHeight] = useState<number>(0)
  const watchPageView = useAppSelector((state) => state.common.watchPageView)

  const loadRefVideo = () => {
    if (videoRef.current !== null) {
      videoRef.current.load()
      console.log('видео есть')
    } else {
      console.log('видео нет')
    }
    console.log('push')
  }

  useEffect(() => {
    if (!loading && data?.video) {
      setDataVideo(data.video)
      setSkip(true)
      console.log(data)
      dispatch(changeIDVideo(data.video?.id || ''))
      dispatch(
        addChapters(
          data?.video?.chapters?.map((chapter) => ({
            state: chapter?.state || IVideosChapterStateChoices.New,
            id: chapter?.id || '',
            time: chapter?.time || '0',
            name: chapter?.name || '',
            url: chapter?.url || '',
            imgURL: '',
            key: chapter?.id || String(Date.now()),
          })) || []
        )
      )
    }
  }, [data, loading])

  useEffect(() => {
    setVideoPath(dataVideo?.url || '')
  }, [dataVideo])

  useEffect(() => {
    if (videoRef.current) {
      //loadRefVideo()
      const duration = videoRef.current
      duration.onloadeddata = () => console.log('loadeddata')
      duration.onloadedmetadata = function () {
        dispatch(durationTime(duration.duration))
        console.log('loadmetadata')
        console.log('считаем аспект')
        setAspectVideo(duration.videoWidth / duration.videoHeight)
        setVideoReady(true)
      }
    }
    console.log('aspect video' + aspectVideo)
  }, [videoRef.current])

  useEffect(() => {
    console.log('videooooo')
  }, [])

  useEffect(() => {
    if (videoPath) {
      const video: any = document.getElementById(videoPath)

      const changeTimeVideoInterval =
        video &&
        setInterval(() => {
          dispatch(changeCurrentTime(video.currentTime, video.paused))
        }, 250)
      return () => clearInterval(changeTimeVideoInterval)
    }
  }, [videoPath])
  const screenSize = useAppSelector((state) => state.screens.size)

  const changeSceenSizeUp = () => {
    dispatch(changeScreenSize(screenSize + 5))
  }

  const changeSceenSizeDown = () => {
    dispatch(changeScreenSize(screenSize - 5))
  }
  const currentTheme = useAppSelector((state) => state.common.currentTheme)
  //const currentTheme = localStorage.getItem('theme');

  useEffect(() => {
    const storageTheme = localStorage.getItem('theme')
    if (storageTheme) {
      dispatch(changeCurrentTheme(storageTheme))
    }
  }, [])

  //Архитектура сплитов:
  // Вертикальные 1, 2, 3
  // Вложенные в них горизонтальные 1(4,5); 2(6,7); 3(8,9)

  //сторы для хранения процентов отображаемого пространства внутри сплиттера
  const [oneTwoThreeSizesFirst, setOneTwoThreeSizesFirst] = useState<number[]>()
  const [fourFiveSizesFirst, setFourFiveSizesFirst] = useState<number[]>()

  const [oneTwoThreeSizesSecond, setOneTwoThreeSizesSecond] =
    useState<number[]>()
  const [fourFiveSizesSecond, setFourFiveSizesSecond] = useState<number[]>()

  const [oneTwoThreeSizesThird, setOneTwoThreeSizesThird] = useState<number[]>()
  const [fourFiveSizesThird, setFourFiveSizesThird] = useState<number[]>()

  //Стейты по дефолту для сплиттера + загружаемые из локалсторейджа
  useEffect(() => {
    setOneTwoThreeSizesFirst(aspectVideo < 1 ? [25, 50, 25] : [35, 40, 25])
    setOneTwoThreeSizesSecond(aspectVideo < 1 ? [30, 20, 50] : [25, 50, 25])
    setOneTwoThreeSizesThird(aspectVideo < 1 ? [30, 20, 50] : [35, 20, 45])

    setFourFiveSizesFirst(aspectVideo < 1 ? [40, 60] : [50, 50])
    setFourFiveSizesSecond(aspectVideo < 1 ? [50, 50] : [60, 40])
    setFourFiveSizesThird(aspectVideo < 1 ? [70, 30] : [50, 50])

    //GetWith и SetWith закидывают и берут из локалсторейджа + помечают срок в мс, при котором удалить при извлечении
    if (getWithExpiry(`${id}splitOneTwoThreeFirst`)) {
      setOneTwoThreeSizesFirst(getWithExpiry(`${id}splitOneTwoThreeFirst`))
    }
    if (getWithExpiry(`${id}splitOneTwoThreeSecond`)) {
      setOneTwoThreeSizesSecond(getWithExpiry(`${id}splitOneTwoThreeSecond`))
    }
    if (getWithExpiry(`${id}splitOneTwoThreeThird`)) {
      setOneTwoThreeSizesThird(getWithExpiry(`${id}splitOneTwoThreeThird`))
    }

    if (getWithExpiry(`${id}splitFourFiveFirst`)) {
      setFourFiveSizesFirst(getWithExpiry(`${id}splitFourFiveFirst`))
    }
    if (getWithExpiry(`${id}splitFourFiveSecond`)) {
      setFourFiveSizesSecond(getWithExpiry(`${id}splitFourFiveSecond`))
    }
    if (getWithExpiry(`${id}splitFourFiveThird`)) {
      setFourFiveSizesThird(getWithExpiry(`${id}splitFourFiveThird`))
    }
  }, [aspectVideo, watchPageView])

  //Функция для события окончания драга сплита
  const handleResizeOneTwoThree = (gutterIdx: number, allSizes: number[]) => {
    if (watchPageView === 'first') {
      setOneTwoThreeSizesFirst(allSizes)
      setWithExpiry(`${id}splitOneTwoThreeFirst`, allSizes)
      console.log(getWithExpiry(`${id}splitOneTwoThreeFirst`))
    }
    if (watchPageView === 'second') {
      setOneTwoThreeSizesSecond(allSizes)
      setWithExpiry(`${id}splitOneTwoThreeSecond`, allSizes)
      console.log(getWithExpiry(`${id}splitOneTwoThreeSecond`))
    }
    if (watchPageView === 'third') {
      setOneTwoThreeSizesThird(allSizes)
      setWithExpiry(`${id}splitOneTwoThreeThird`, allSizes)
      console.log(getWithExpiry(`${id}splitOneTwoThreeThird`))
    }
  }

  const handleResizeFourFive = (gutterIdx: number, allSizes: number[]) => {
    if (watchPageView === 'first') {
      setFourFiveSizesFirst(allSizes)
      setWithExpiry(`${id}splitFourFiveFirst`, allSizes)
      console.log(getWithExpiry(`${id}splitFourFiveFirst`))
    }
    if (watchPageView === 'second') {
      setFourFiveSizesSecond(allSizes)
      setWithExpiry(`${id}splitFourFiveSecond`, allSizes)
      console.log(getWithExpiry(`${id}splitFourFiveSecond`))
    }
    if (watchPageView === 'third') {
      setFourFiveSizesThird(allSizes)
      setWithExpiry(`${id}splitFourFiveThird`, allSizes)
      console.log(getWithExpiry(`${id}splitFourFiveThird`))
    }
  }

  if (loading) return <>Loading...</>
  if (error)
    return (
      <>
        Something wrong <br />
        Error:{error.message}
      </>
    )

  return (
    <>
      <div data-theme={currentTheme} className='{bg-base-100} '>
        <Header />
        {!videoReady && <Spinner />}
        <div className={styles.controlButtons}>
          <ViewChanger id={id} />

          <ShareModalButton />
          {dataVideo?.editable && (
            <Link
              to={`/edit/${id}`}
              className='text-center inline-flex items-center px-6 py-2 mr-5  border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Edit
            </Link>
          )}
        </div>
        <div className={styles.bg}>
          <div className={styles.pageColumn}>
            {/*Новая верстка шаблонов*/}
            {watchPageView === 'first' && (
              <>
                <Splitter
                  onResizeFinished={handleResizeOneTwoThree}
                  initialSizes={oneTwoThreeSizesFirst}
                  minWidths={[200, 400, 200]}
                  direction={SplitDirection.Horizontal}
                  gutterClassName={`${
                    currentTheme === 'light'
                      ? styles.gutter__light
                      : styles.gutter__dark
                  } ${styles.gutter} ${styles.gutter__horizontal} bg-base-200`}
                >
                  <Splitter
                    onResizeFinished={handleResizeFourFive}
                    initialSizes={fourFiveSizesFirst}
                    minHeights={[200, 200]}
                    direction={SplitDirection.Vertical}
                    gutterClassName={`${
                      currentTheme === 'light'
                        ? styles.gutter__light
                        : styles.gutter__dark
                    } ${styles.gutter} ${styles.gutter__vertical} bg-base-200`}
                  >
                    <div className={styles.inputButtons}>
                      <ChapterSelector
                        setChapterSelectorHeight={setChapterSelectorHeight}
                        isEditable={false}
                      />
                    </div>
                    <VideoContainer
                      videoRef={videoRef}
                      videoPath={videoPath}
                      aspectVideo={aspectVideo}
                    />
                  </Splitter>
                  <div className={styles.chapterScroller}>
                    <div className={styles.chapterScrollerContainer}>
                      <ChapterScroller
                        aspectVideo={aspectVideo}
                        isEditable={false}
                      />
                    </div>
                  </div>
                  <Splitter
                    initialSizes={[40, 60]}
                    minHeights={[200, 200]}
                    direction={SplitDirection.Vertical}
                    gutterClassName={`${
                      currentTheme === 'light'
                        ? styles.gutter__light
                        : styles.gutter__dark
                    } ${styles.gutter} ${styles.gutter__vertical} bg-base-200`}
                  >
                    <AppInfo
                      dataVideo={dataVideo}
                      currentTheme={currentTheme}
                    />
                    <Comments />
                  </Splitter>
                </Splitter>
              </>
            )}

            {watchPageView === 'second' && (
              <>
                <Splitter
                  onResizeFinished={handleResizeOneTwoThree}
                  initialSizes={oneTwoThreeSizesSecond}
                  minWidths={[200, 200, 400]}
                  direction={SplitDirection.Horizontal}
                  gutterClassName={`${
                    currentTheme === 'light'
                      ? styles.gutter__light
                      : styles.gutter__dark
                  } ${styles.gutter} ${styles.gutter__horizontal} bg-base-200`}
                >
                  <Splitter
                    onResizeFinished={handleResizeFourFive}
                    initialSizes={fourFiveSizesSecond}
                    minHeights={[200, 200]}
                    direction={SplitDirection.Vertical}
                    gutterClassName={`${
                      currentTheme === 'light'
                        ? styles.gutter__light
                        : styles.gutter__dark
                    } ${styles.gutter} ${styles.gutter__vertical} bg-base-200`}
                  >
                    <div className={styles.inputButtons}>
                      <ChapterSelector
                        setChapterSelectorHeight={setChapterSelectorHeight}
                        isEditable={false}
                      />
                    </div>
                    <AppInfo
                      dataVideo={dataVideo}
                      currentTheme={currentTheme}
                    />
                  </Splitter>
                  <Splitter
                    initialSizes={aspectVideo < 1 ? [70, 30] : [70, 30]}
                    minHeights={[200, 200]}
                    direction={SplitDirection.Vertical}
                    gutterClassName={`${
                      currentTheme === 'light'
                        ? styles.gutter__light
                        : styles.gutter__dark
                    } ${styles.gutter} ${styles.gutter__vertical} bg-base-200`}
                  >
                    <VideoContainer
                      videoRef={videoRef}
                      videoPath={videoPath}
                      aspectVideo={aspectVideo}
                    />
                    <Comments />
                  </Splitter>

                  <div className={styles.chapterScroller}>
                    <div className={styles.chapterScrollerContainer}>
                      <ChapterScroller
                        aspectVideo={aspectVideo}
                        isEditable={false}
                      />
                    </div>
                  </div>
                </Splitter>
              </>
            )}

            {watchPageView === 'third' && (
              <>
                <Splitter
                  onResizeFinished={handleResizeOneTwoThree}
                  initialSizes={oneTwoThreeSizesThird}
                  minWidths={[200, 200, 400]}
                  direction={SplitDirection.Horizontal}
                  gutterClassName={`${
                    currentTheme === 'light'
                      ? styles.gutter__light
                      : styles.gutter__dark
                  } ${styles.gutter} ${styles.gutter__horizontal} bg-base-200`}
                >
                  <Splitter
                    onResizeFinished={handleResizeFourFive}
                    initialSizes={fourFiveSizesThird}
                    minHeights={[200, 200]}
                    direction={SplitDirection.Vertical}
                    gutterClassName={`${
                      currentTheme === 'light'
                        ? styles.gutter__light
                        : styles.gutter__dark
                    } ${styles.gutter} ${styles.gutter__vertical} bg-base-200`}
                  >
                    <VideoContainer
                      videoRef={videoRef}
                      videoPath={videoPath}
                      aspectVideo={aspectVideo}
                    />
                    <AppInfo
                      dataVideo={dataVideo}
                      currentTheme={currentTheme}
                    />
                  </Splitter>
                  <Splitter
                    initialSizes={[50, 50]}
                    minHeights={[200, 200]}
                    direction={SplitDirection.Vertical}
                    gutterClassName={`${
                      currentTheme === 'light'
                        ? styles.gutter__light
                        : styles.gutter__dark
                    } ${styles.gutter} ${styles.gutter__vertical} bg-base-200`}
                  >
                    <div className={styles.inputButtons}>
                      <ChapterSelector
                        setChapterSelectorHeight={setChapterSelectorHeight}
                        isEditable={false}
                      />
                    </div>
                    <Comments />
                  </Splitter>
                  <div className={styles.chapterScroller}>
                    <div className={styles.chapterScrollerContainer}>
                      <ChapterScroller
                        aspectVideo={aspectVideo}
                        isEditable={false}
                      />
                    </div>
                  </div>
                </Splitter>
              </>
            )}
          </div>
        </div>

        {/*<Footer />*/}
      </div>
    </>
  )
}

export default React.memo(WatchPage)
