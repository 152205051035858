import { demoLink } from '../../Constants/links'
import useMediaQuery from '../../Hooks/useMediaQuery'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import Button from '../../UI/Button'
import avatar from '../../images/avatar.png'
import avatarB from '../../images/avatar_b.png'
import styles from './index.module.scss'
import { useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const client = useApolloClient()
  const isUserLogged = useAppSelector((state) => state.user.isAuth)
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)

  const currentTheme = useAppSelector((state) => state.common.currentTheme)

  // Функция для перехода на другие урлы + если второй параметр указать true, будет урл с разлогином.
  const handleClickToRedirect = (path: string, ...arg: any[]) => {
    return () => {
      if (arg[0] === true) {
        localStorage.removeItem('token')
        dispatch({ type: 'USER_LOG_OUT' })
        client.resetStore()
        history.push('/')
      } else history.push(path)
    }
  }

  const toggleMenu = () => {
    setIsShowMobileMenu((prev) => !prev)
  }

  const matches768 = useMediaQuery('(max-width:768px)')

  console.log(isShowMobileMenu, matches768)

  return (
    <>
      <div
        className={`navbar ${
          currentTheme === 'light' ? 'bg-base-100' : 'bg-base-200'
        } text-base-content ${styles.header}`}
      >
        <div className={clsx('flex-1 px-2 mx-2 lg:flex', styles.logoRow)}>
          <Link to='/'>
            <span className={`${styles.header__logo} text-lg font-bold`}>
              FTUE
            </span>
          </Link>
          <button
            className={`${styles.header__button} btn btn-ghost hidden`}
            onClick={handleClickToRedirect(demoLink)}
          >
            Demo
          </button>
          {matches768 && (
            <button onClick={toggleMenu} title='menu'>
              <svg width='30' height='30' viewBox='0 0 100 40' fill='#000'>
                <rect width='75' height='8'></rect>
                <rect y='20' width='75' height='8'></rect>
                <rect y='40' width='75' height='8'></rect>
              </svg>
            </button>
          )}
        </div>
        {
          <div className='flex-none'>
            {((matches768 && isShowMobileMenu) || !matches768) && (
              <>
                <button
                  className={`${styles.header__button} btn btn-ghost`}
                  onClick={handleClickToRedirect('/library')}
                >
                  Library
                </button>
                <button
                  className={`${styles.header__button} btn btn-ghost`}
                  onClick={handleClickToRedirect('/how-to-use')}
                >
                  How to use
                </button>
                <button
                  className={`${styles.header__button} btn btn-ghost`}
                  onClick={handleClickToRedirect('/new')}
                >
                  New project
                </button>
                <button
                  className={`${styles.header__button} btn btn-ghost`}
                  onClick={handleClickToRedirect('/projects')}
                >
                  My projects
                </button>
              </>
            )}
          </div>
        }

        {isUserLogged ? (
          <>
            {((matches768 && isShowMobileMenu) || !matches768) && (
              <div className='flex-none'>
                <div className={` avatar dropdown dropdown-end`}>
                  <div
                    tabIndex={0}
                    className={clsx('w-full h-10 m-1', styles.profile)}
                  >
                    <span>Profile</span>
                    <img
                      className={styles.avatar}
                      src={currentTheme === 'dark' ? avatar : avatarB}
                    />
                  </div>
                  <ul
                    tabIndex={0}
                    className={clsx(
                      'p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52',
                      styles.dropdown
                    )}
                  >
                    <li>
                      <Button
                        title='Log out'
                        onClick={handleClickToRedirect('/', true)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {((matches768 && isShowMobileMenu) || !matches768) && (
              <div className='flex-none hidden lg:flex'>
                <button
                  className={`${styles.header__button} btn btn-ghost`}
                  onClick={handleClickToRedirect('/login')}
                >
                  Login
                </button>
                <button
                  className={`${styles.header__button} btn btn-ghost`}
                  onClick={handleClickToRedirect('/signup')}
                >
                  SignUp
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {window.location.pathname !== '/' && (
        <div
          className={`${styles.header__border} ${
            currentTheme === 'light' && 'border-b'
          } mb-2`}
        ></div>
      )}
    </>
  )
}

export default Header
