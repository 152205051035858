import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { rootReduser } from './combineRedusers'

export const store = createStore(rootReduser, composeWithDevTools())

// Typing Redux hook for typescript
// <--
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
// -->
