export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any
  SocialCamelJSON: any
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any
}

export type IBlurInput = {
  endTime?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
  startTime?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  x?: Maybe<Scalars['Float']>
  y?: Maybe<Scalars['Float']>
}

export type IBlurVideoSuccess = {
  __typename?: 'BlurVideoSuccess'
  message?: Maybe<Scalars['String']>
  video?: Maybe<IVideo>
}

export type IChapter = {
  __typename?: 'Chapter'
  frame?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  name: Scalars['String']
  state: IVideosChapterStateChoices
  time: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type IChapterInput = {
  deleted?: Maybe<Scalars['Boolean']>
  file?: Maybe<Scalars['Upload']>
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  time?: Maybe<Scalars['String']>
}

export type ICreateUserExists = {
  __typename?: 'CreateUserExists'
  message?: Maybe<Scalars['String']>
}

export type ICreateUserPayload = ICreateUserExists | ICreateUserSuccess

export type ICreateUserSuccess = {
  __typename?: 'CreateUserSuccess'
  user?: Maybe<IUser>
}

export type ICreateVideoSuccess = {
  __typename?: 'CreateVideoSuccess'
  video?: Maybe<IVideo>
}

export type IMutation = {
  __typename?: 'Mutation'
  createUser?: Maybe<ICreateUserPayload>
  createVideo?: Maybe<ICreateVideoSuccess>
  refreshToken?: Maybe<IRefresh>
  socialAuth?: Maybe<ISocialAuth>
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<IObtainJsonWebToken>
  updateVideo?: Maybe<IUpdateVideoPayload>
  uploadVideo?: Maybe<IUploadVideoPayload>
  verifyToken?: Maybe<IVerify>
}

export type IMutationCreateUserArgs = {
  dryRun?: Maybe<Scalars['Boolean']>
  email: Scalars['String']
  password: Scalars['String']
}

export type IMutationCreateVideoArgs = {
  file?: Maybe<Scalars['Upload']>
  name?: Maybe<Scalars['String']>
}

export type IMutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>
}

export type IMutationSocialAuthArgs = {
  accessToken: Scalars['String']
  provider: Scalars['String']
}

export type IMutationTokenAuthArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type IMutationUpdateVideoArgs = {
  file?: Maybe<Scalars['Upload']>
  video?: Maybe<IVideoInput>
}

export type IMutationUploadVideoArgs = {
  file: Scalars['Upload']
  name?: Maybe<Scalars['String']>
}

export type IMutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>
}

export type INoAccessError = {
  __typename?: 'NoAccessError'
  message?: Maybe<Scalars['String']>
}

/** Obtain JSON Web Token mutation */
export type IObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken'
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  token: Scalars['String']
}

export type IProject = {
  __typename?: 'Project'
  video?: Maybe<Array<Maybe<IVideo>>>
}

export type IQuery = {
  __typename?: 'Query'
  me?: Maybe<IUser>
  projects?: Maybe<IProject>
  video?: Maybe<IVideo>
}

export type IQueryVideoArgs = {
  edit?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['String']>
}

export type IRefresh = {
  __typename?: 'Refresh'
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  token: Scalars['String']
}

export type ISocialAuth = {
  __typename?: 'SocialAuth'
  payload: Scalars['GenericScalar']
  refreshExpiresIn: Scalars['Int']
  social?: Maybe<ISocialType>
  token: Scalars['String']
}

export type ISocialType = {
  __typename?: 'SocialType'
  created: Scalars['DateTime']
  extraData?: Maybe<Scalars['SocialCamelJSON']>
  id: Scalars['ID']
  modified: Scalars['DateTime']
  provider: Scalars['String']
  uid: Scalars['String']
  user: IUser
}

export type ITooLongName = {
  __typename?: 'TooLongName'
  message?: Maybe<Scalars['String']>
}

export type IUpdateVideoPayload =
  | IBlurVideoSuccess
  | INoAccessError
  | ITooLongName
  | IUpdateVideoProcessing
  | IUpdateVideoSuccess
  | IUserNotAuthenticated
  | IVideoAlreadyUploaded
  | IVideoNotFound
  | IVideoUnderProcessing
  | IWrongVideoInput

export type IUpdateVideoProcessing = {
  __typename?: 'UpdateVideoProcessing'
  video?: Maybe<IVideo>
}

export type IUpdateVideoSuccess = {
  __typename?: 'UpdateVideoSuccess'
  message?: Maybe<Scalars['String']>
  video?: Maybe<IVideo>
}

export type IUploadVideoPayload = IUploadVideoSuccess | IUserNotAuthenticated

export type IUploadVideoSuccess = {
  __typename?: 'UploadVideoSuccess'
  video?: Maybe<IVideo>
}

export type IUser = {
  __typename?: 'User'
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
}

export type IUserNotAuthenticated = {
  __typename?: 'UserNotAuthenticated'
  message?: Maybe<Scalars['String']>
}

export type IVerify = {
  __typename?: 'Verify'
  payload: Scalars['GenericScalar']
}

export type IVideo = {
  __typename?: 'Video'
  chapters?: Maybe<Array<Maybe<IChapter>>>
  description?: Maybe<Scalars['String']>
  editable?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  state: IVideosVideoStateChoices
  url?: Maybe<Scalars['String']>
  user?: Maybe<IUser>
  website?: Maybe<Scalars['String']>
}

export type IVideoAlreadyUploaded = {
  __typename?: 'VideoAlreadyUploaded'
  message?: Maybe<Scalars['String']>
}

export type IVideoInput = {
  blurs?: Maybe<Array<Maybe<IBlurInput>>>
  chapters?: Maybe<Array<Maybe<IChapterInput>>>
  description?: Maybe<Scalars['String']>
  dryRun?: Maybe<Scalars['Boolean']>
  icon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  visibility?: Maybe<IVisibilityChoices>
  website?: Maybe<Scalars['String']>
}

export type IVideoNotFound = {
  __typename?: 'VideoNotFound'
  message?: Maybe<Scalars['String']>
}

export type IVideoUnderProcessing = {
  __typename?: 'VideoUnderProcessing'
  message?: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum IVideosChapterStateChoices {
  /** Active */
  Active = 'ACTIVE',
  /** New */
  New = 'NEW',
  /** Trash */
  Trash = 'TRASH',
}

/** An enumeration. */
export enum IVideosVideoStateChoices {
  /** Active */
  Active = 'ACTIVE',
  /** Error */
  Error = 'ERROR',
  /** New */
  New = 'NEW',
  /** Processing */
  Processing = 'PROCESSING',
  /** Trash */
  Trash = 'TRASH',
}

export enum IVisibilityChoices {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Unlisted = 'UNLISTED',
}

export type IWrongVideoInput = {
  __typename?: 'WrongVideoInput'
  message?: Maybe<Scalars['String']>
}
