import { RefObject, useEffect } from 'react'

const useMouseMoveScroll = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      event.preventDefault()
      const startLeft = ref.current?.scrollLeft || 0

      const handleMouseMove = (e: MouseEvent) => {
        e.preventDefault()
        // @ts-ignore
        ref.current?.scrollTo({ left: startLeft + event.clientX - e.clientX })
        // @ts-ignore
      }
      document.addEventListener('mousemove', handleMouseMove)

      document.addEventListener('mouseup', (ev) => {
        ev.preventDefault()
        document.removeEventListener('mousemove', handleMouseMove)
      })
    }

    ref.current?.addEventListener('mousedown', handleMouseDown)
    return () => {
      console.log('delete')

      ref.current?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [ref])
}

export default useMouseMoveScroll
