import React from 'react'
import { useRouteMatch } from 'react-router'
import WatchPage from './WatchPage'

const WatchPageCommon: React.FC = () => {
  const match: null | { params: { id: string } | null | undefined } =
    useRouteMatch('/watch/:id')
  return (
    <div>
      <WatchPage id={match?.params?.id || ''} />
    </div>
  )
}

export default React.memo(WatchPageCommon)
