import { changedTime } from '../../../Store/Video/actions'
import { useAppDispatch } from '../../../Store/hooks'
import styles from './index.module.scss'
import React from 'react'

interface IAccurateScene {
  time: number
  isActive: boolean
  isMobile: boolean
  interval: number
  width: number
}
// Поработать с тем чтобы вынести условие
const AccurateScene: React.FC<IAccurateScene> = ({
  time,
  isActive,
  isMobile,
  interval,
  width,
}) => {
  const dispatch = useAppDispatch()
  return (
    <>
      <div
        data-time={time}
        className={`${styles.accurateScene} ${
          (isActive && styles.accurateScene_current) || ''
        }`}
        data-interval={interval}
        style={!isMobile ? { width: `${width}px` } : undefined}
        onClick={() => {
          dispatch(changedTime({ time }))
          console.log('disp')
        }}
      >
        {time % 5 === 0 && (
          <div className={styles.accurateScene_time}>{time}s</div>
        )}
        <div className={styles.accurateScene__first}></div>
        <div className={styles.accurateScene__second}></div>
      </div>
    </>
  )
}

export default React.memo(AccurateScene)
