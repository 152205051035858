import ChapterScroller from '../../../Feature/ChapterScroller'
import ChapterSelector from '../../../Feature/ChapterSelector'
import ChaptersButtonsBlock from '../../../Feature/ChaptersButtonsBlock'
import Header from '../../../Feature/Header'
import ModalButton from '../../../Feature/ModalButton'
import NavigationAccurate from '../../../Feature/NavigationAccurate'
import NavigationBlursContainer from '../../../Feature/NavigationBlursContainer'
import NavigationInnacurate from '../../../Feature/NavigationInnacurate'
import SceneScroller from '../../../Feature/SceneScroller'
import UpdateVideoButton from '../../../Feature/UpdateVideoButton'
import VideoContainer from '../../../Feature/VideoContainer'
import { useVideoQuery } from '../../../GraphqlQuery/__generated__/video'
import { addChapters } from '../../../Store/Chapters/actions'
import {
  changeCurrentTheme,
  changeIsCuttingLowerTimeLine,
  changeIsPressToCutCenterTimeLine,
} from '../../../Store/Common/actions'
import {
  changeCurrentTime,
  changeIDVideo,
  changeURLVideo,
  changeVideoName,
  durationTime,
} from '../../../Store/Video/actions'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import Input from '../../../UI/Input'
import { IVideosChapterStateChoices } from '../../../__generated__/types'
import styles from './index.module.scss'
import { Video_Query } from './interfaces'
import { useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

interface EditPageProps {
  id: string
}

const EditPage: React.FC<EditPageProps> = ({ id }) => {
  const projectName = useAppSelector((state) => state.video.name)
  const dispatch = useAppDispatch()

  const history = useHistory()

  const [skipQuery, setSkipQuery] = useState<boolean>(false)
  const [videoPath, setVideoPath] = useState<string>('')
  const [videoName, setVideoName] = useState<string>('')
  const [videoData, setVideoData] = useState<Video_Query | null>(null)
  const [aspectVideo, setAspectVideo] = useState<number>(1)
  const [isLocalVideoUploaded, setIsLocalVideoUploaded] =
    useState<boolean>(false)

  const videoRef = useRef<HTMLVideoElement>(null)

  const [chapterSelectorHeight, setChapterSelectorHeight] = useState<number>(0)
  const [sceneScrollerHeight, setSceneScrollerHeight] = useState<number>(0)

  const client = useApolloClient()
  const currentTheme = useAppSelector((state) => state.common.currentTheme)
  //const currentTheme = localStorage.getItem('theme');

  useEffect(() => {
    return () => {
      dispatch(changeVideoName(''))
    }
  }, [])

  useEffect(() => {
    const storageTheme = localStorage.getItem('theme')
    if (storageTheme) {
      dispatch(changeCurrentTheme(storageTheme))
    }
  }, [])

  console.log(id)

  const { data, loading, error } = useVideoQuery({
    skip: skipQuery,
    variables: { id },
  })

  const loadRefVideo = () => {
    if (videoRef.current !== null) {
      videoRef.current.load()
      console.log('видео есть')
    } else {
      console.log('видео нет')
    }
    console.log('push')
  }

  console.log('chapters', data?.video?.chapters)

  useEffect(() => {
    if (!loading && data?.video) {
      setVideoData(data.video)
      setSkipQuery(true)
      console.log(data)
      dispatch(changeIDVideo(data.video?.id || ''))
      dispatch(
        addChapters(
          data?.video?.chapters?.map((chapter) => ({
            state: chapter?.state || IVideosChapterStateChoices.New,
            id: chapter?.id || '',
            time: chapter?.time || '0',
            name: chapter?.name || '',
            url: chapter?.url || '',
            imgURL: '',
            key: chapter?.id || String(Date.now()),
          })) || []
        )
      )
    }
  }, [data])

  useEffect(() => {
    // dispatch(clearVideo());
    // dispatch(clearChapters());
    // dispatch(clearScreens());
    dispatch(changeIsCuttingLowerTimeLine(true))
    dispatch(changeURLVideo(videoPath))
  }, [videoPath])

  useEffect(() => {
    if (videoRef.current) {
      loadRefVideo()
      const duration = videoRef.current
      duration.onloadeddata = () => console.log('loadeddata')
      duration.onloadedmetadata = function () {
        dispatch(durationTime(duration.duration))
        console.log('loadmetadata')
        console.log('считаем аспект')
        setAspectVideo(duration.videoWidth / duration.videoHeight)
      }
    }
    console.log('aspect video' + aspectVideo)
  }, [videoPath, videoRef.current, isLocalVideoUploaded])

  useEffect(() => {
    let changeTimeVideoInterval: any
    if (videoPath) {
      const video: any = document.getElementById(videoPath)

      if (video) {
        clearInterval(changeTimeVideoInterval)
        changeTimeVideoInterval = setInterval(() => {
          dispatch(changeCurrentTime(video.currentTime, video.paused))
        }, 1250)
      }
    }

    return () => clearInterval(changeTimeVideoInterval)
  }, [videoPath, isLocalVideoUploaded])

  useEffect(() => {
    if (id && videoData) {
      console.log(videoData)
      setVideoPath(videoData?.url || '')
    }
  }, [videoData])

  useEffect(() => {
    if (videoData && !videoData.url && videoPath) setIsLocalVideoUploaded(true)
    loadRefVideo()
  }, [videoPath, isLocalVideoUploaded])

  useEffect(() => {
    return () => {
      console.log('EditPage is delete')
      dispatch(changeIsPressToCutCenterTimeLine(false))
    }
  }, [])

  if (loading) return <>Loading...</>

  if (error)
    return (
      <>
        Somethink wrong <br /> {error.message}
      </>
    )

  if (!videoData?.editable) return <>You are not allowed to edit this video</>

  if (videoData && !videoData.url && !isLocalVideoUploaded)
    return (
      <div data-theme={currentTheme} className='bg-base-100'>
        <Header />

        <div className={clsx(styles.buttonWrapper, 'p-10')}>
          <div className={styles.watchPagHeader__uploadButton}>
            <ModalButton
              loadRefVideo={loadRefVideo}
              setVideoPath={setVideoPath}
              setVideoName={setVideoName}
            />
            <p className='text-base-content'>
              Please, add a video to get started
            </p>
          </div>
        </div>
      </div>
    )

  return (
    <div data-theme={currentTheme} className='bg-base-100'>
      <Header />
      <div className={styles.bg}>
        <div className={styles.pageColumn}>
          <div className={styles.pageContainer}>
            <VideoContainer
              withBlurRectangle={true}
              aspectVideo={aspectVideo}
              videoPath={videoPath}
              videoRef={videoRef}
            />

            <div className={`${styles.chapters}`}>
              <>
                <div
                  className={clsx(
                    styles.inputButtons, // базовый класс, который всегда применяется
                    {
                      [styles.inputButtons_column]:
                        chapterSelectorHeight <= sceneScrollerHeight - 155,
                    },
                    'p-3'
                  )}
                >
                  <div className={styles.projectName}>
                    <label className='mb-2 block' htmlFor='projectName'>
                      Project Name:
                    </label>
                    <Input
                      id='projectName'
                      value={projectName}
                      onChange={(e) =>
                        dispatch(changeVideoName(e.target.value))
                      }
                      height='input-sm'
                      isEditable={true}
                      autoFocus={false}
                    />
                  </div>
                  <ChapterSelector
                    setChapterSelectorHeight={setChapterSelectorHeight}
                    isEditable={true}
                  />
                  <div>
                    <ChaptersButtonsBlock videoRef={videoRef.current} />
                    <UpdateVideoButton />
                  </div>
                </div>

                <div className={styles.chapters__chapters}>
                  <ChapterScroller
                    isEditable={true}
                    aspectVideo={aspectVideo}
                  />
                </div>
                <div
                  className={
                    chapterSelectorHeight <= sceneScrollerHeight - 155
                      ? styles.sceneScrollerContainer_row
                      : styles.sceneScrollerContainer
                  }
                >
                  <SceneScroller
                    setSceneScrollerHeight={setSceneScrollerHeight}
                  />
                </div>
              </>
            </div>
          </div>
          <div className={styles.pageNavigation}>
            <NavigationAccurate isMobile={false} />
            <NavigationBlursContainer />
            <NavigationInnacurate videoPath={videoPath} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditPage
