import React, { useState } from 'react'

const useInput = (
  initialValue = '',
  placeholder: string,
  required: boolean,
  type?: string,
  pattern?: string
) => {
  const [inputValue, setInputValue] = useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputValue(e.target.value)

  const clear = () => {
    setInputValue('')
  }

  return {
    default: {
      onChange,
      placeholder,
      value: inputValue,
      required,
      type,
      pattern,
    },
    inputValue,
    clear,
  }
}

export default useInput
