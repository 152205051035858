import Routers from './Pages'

function App() {
  console.log('FTUE APP init')

  return (
    <div className='App'>
      <Routers />
    </div>
  )
}

export default App
