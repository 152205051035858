import { useAppSelector } from '../../Store/hooks'
import Button from '../../UI/Button'
import Modal from './Modal/Modal'
import styles from './index.module.scss'
import React, { useMemo } from 'react'

interface IModalButtonProps {
  setVideoPath?: (setVideoPath: string) => void
  setVideoName?: (setVideoName: string) => void
  loadRefVideo?: () => void
}

const ModalButton: React.FC<IModalButtonProps> = ({
  setVideoPath,
  setVideoName,
  loadRefVideo,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const openModal = (e: React.MouseEvent) => {
    setIsModalOpen(true)
  }

  const url = useAppSelector((state) => state.video.url)

  const isShowUploadButton = useMemo(
    () => url.includes('blob') || url === '',
    [url]
  )

  return (
    // условие вынести в переменную
    (isShowUploadButton && (
      <div className={styles.modalButton}>
        <Button title='Upload' onClick={openModal} />
        <Modal
          isDisplay={isModalOpen}
          loadRefVideo={loadRefVideo}
          setVideoPath={setVideoPath}
          setVideoName={setVideoName}
          setOpen={setIsModalOpen}
        />
      </div>
    )) ||
    null
  )
}

export default React.memo(ModalButton)
