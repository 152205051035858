import {
  IAddBlur,
  IAddVideo,
  IBlur,
  IChangeCurrentTimeAndPaused,
  IChangedTime,
  IChangeDurationBlur,
  IChangeIDVideo,
  IChangeURLVideo,
  IChangeVideoName,
  IClearVideo,
  IDeleteBlur,
  IDurationTime,
  IVideoState,
  VideoType,
} from './types'

export const addBlur = (blur: IBlur): IAddBlur => ({
  type: VideoType.ADD_BLUR,
  blur,
})

export const deleteBlur = (id: number): IDeleteBlur => ({
  type: VideoType.DELETE_BLUR,
  id,
})

export const addVideo = (video: IVideoState): IAddVideo => ({
  type: VideoType.ADD_VIDEO,
  video,
})

export const clearVideo = (): IClearVideo => ({ type: VideoType.CLEAR_VIDEO })

export const changedTime = (changedTime: {
  time: number
  place?: 'WholeScene'
}): IChangedTime => ({
  type: VideoType.CHANGED_TIME,
  changedTime,
})

export const durationTime = (durationTime: number): IDurationTime => ({
  type: VideoType.CHANGE_DURATION_TIME,
  durationTime,
})

export const changeCurrentTime = (
  currentTime: number,
  paused: boolean
): IChangeCurrentTimeAndPaused => ({
  type: VideoType.CHANGE_CURRENT_TIME_AND_PAUSED,
  currentTime,
  paused,
})

export const changeDurationBlur = (
  id: number,
  time: number
): IChangeDurationBlur => ({
  type: VideoType.CHANGE_DURATION_BLUR,
  id,
  time,
})

export const changeURLVideo = (url: string): IChangeURLVideo => ({
  type: VideoType.CHANGE_URL_VIDEO_GQL,
  url,
})

export const changeIDVideo = (id: string): IChangeIDVideo => ({
  type: VideoType.CHANGE_ID_VIDEO,
  id,
})

export const changeVideoName = (name: string): IChangeVideoName => ({
  type: VideoType.CHANGE_VIDEO_NAME,
  name,
})
