import Header from '../../Feature/Header'
import ProductHuntBanner from '../../Feature/ProductHuntBanner'
import { useAppSelector } from '../../Store/hooks'
import styles from './HomePage.module.scss'
import { CUSTOMERS, FEATURES, FEATURES_SHORT } from './constants/constants'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const HomePage = () => {
  const currentTheme = useAppSelector((state) => state.common.currentTheme)

  const history = useHistory()
  const isAuth = useAppSelector((state) => state.user.isAuth)

  return (
    <div data-theme={currentTheme} className={styles.homePage}>
      <Header />
      <section className={styles.mainSection}>
        <ProductHuntBanner />
        <div className={styles.wrapper}>
          <div className={styles.textWrapper}>
            <h1>
              <span>FTUE Video</span> sharing made simple
            </h1>
            <p>
              Annotate and share onboarding and gameplay videos in minutes, not
              hours
            </p>
            <Link
              to='/watch/oKOh0A'
              className={clsx('btn-primary', styles.buttonUploadVideo)}
            >
              Demo
            </Link>
          </div>
        </div>
      </section>
      <section className={styles.featuresShortSection}>
        <div className={styles.wrapper}>
          <div className={styles.featuresShortWrapper}>
            {FEATURES_SHORT.items.map((item, index) => (
              <div
                key={'FEATURES_SHORT-' + index}
                className={styles.featureShort}
              >
                <div className={styles.content}>
                  <img src={item.img} alt='feature' />
                  <div className={styles.text}>
                    <span className={styles.title}>{item.title}</span>
                    <span className={styles.description}>
                      {item.description}
                    </span>
                  </div>
                </div>

                {/*<img*/}
                {/*  className={styles.screenshot}*/}
                {/*  src={item.screenshot}*/}
                {/*  alt='screenshot'*/}
                {/*/>*/}
              </div>
            ))}
          </div>

          <div className='flex justify-center mt-8'>
            <Link
              to={!isAuth ? '/login' : '/new'}
              className={clsx('btn-primary', styles.buttonUploadVideo)}
            >
              Upload video
            </Link>
          </div>
        </div>
      </section>
      <section className={styles.customersSection}>
        <div className={styles.wrapper}>
          <h2>{CUSTOMERS.title}</h2>
          <div className={styles.customers}>
            {CUSTOMERS.items.map((item, index) => (
              <div key={'FEATURES_SHORT-' + index}>{item}</div>
            ))}
          </div>
        </div>
      </section>
      <section className={styles.featuresSection}>
        <div className={styles.wrapper}>
          <h2>{FEATURES.title}</h2>
          <div className={styles.items}>
            {FEATURES.items.map((item, index) => (
              <div
                key={'FEATURES_SHORT-' + index}
                className={styles.featureShortItem}
              >
                <img src={item.img} alt='feature description' />
                <div className={styles.text}>
                  <b className={styles.title}>{item.title}</b>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <footer className={styles.footer}>
        <div className={styles.wrapper}>
          <div className={styles.footerWrapper}>
            <div className={styles.copyright}>© 2023 Appstorespy</div>
            <div className={styles.links}>
              <a href='mailto:info@ftue.video'>info@ftue.video</a>
              <Link to='/terms-of-use'>Terms of service</Link>
              <Link to='/privacy-policy'>Privacy policy</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default HomePage
