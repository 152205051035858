import percentVisibleIntoView from '../Constants/percentVisibleIntoView'

const checkIntoView = (
  intoHTMLElement: HTMLElement,
  containerHTMLElement: HTMLElement
) => {
  const { left, right, width } = intoHTMLElement.getBoundingClientRect()
  let overhang = width * (1 - percentVisibleIntoView)
  let isVisible =
    left >= -overhang &&
    right <= containerHTMLElement.clientWidth + overhang * 5
  return isVisible
}

export default checkIntoView
