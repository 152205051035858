import {
  IChangeUserIsAuth,
  IPayloadUser,
  // IUserLogOut,
  IUserSignIn,
  IUserToken,
  UserType,
} from './types'

export const userSignIn = (payload: IPayloadUser): IUserSignIn => ({
  type: UserType.USER_SIGN_IN,
  user: payload,
})

// export const userLogOut = (): IUserLogOut => ({ type: UserType.USER_LOG_OUT })

export const userToken = (token: string): IUserToken => ({
  type: UserType.USER_TOKEN,
  token,
})

export const changeUserIsAuth = (isAuth: boolean): IChangeUserIsAuth => ({
  type: UserType.CHANGE_USER_IS_AUTH,
  isAuth,
})
