import { changeFileUpload } from '../../Store/Graphql/actions'
import { useAppDispatch } from '../../Store/hooks'
import ProgressBar from '../ProgressBar'
import styles from './index.module.scss'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router'

//Документация: https://github.com/react-dropzone/react-dropzone/

interface IDropZoneProps {
  setVideoPath?: (setVideoPath: string) => void
  setVideoName?: (setVideoName: string) => void
  setOpen?: (setOpen: boolean) => void
  loadRefVideo?: () => void
}
// Вынести большинство логики в хук для читабельности
const DropZone: React.FC<IDropZoneProps> = ({
  setVideoPath,
  setVideoName,
  setOpen,
  loadRefVideo,
}) => {
  const dispatch = useAppDispatch()

  const [progress, setProgress] = useState<number | null>(null)

  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        console.log('file uploaded')
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(file)
        //console.log(binaryStr);

        if (setOpen) {
          setOpen(false)
        }
        //В файле есть path и название
        //console.log(URL.createObjectURL(file));
        if (setVideoPath) {
          setVideoPath(URL.createObjectURL(file))
          // const video = document.querySelector('video');
          // if (video) {
          //   video.load();
          // }
          if (loadRefVideo) {
            loadRefVideo()
          }
          if (setVideoName) {
            setVideoName(file.name)
          }

          //console.log(setVideoPath(file.path))
        }
      }
      //Прогресс
      reader.onprogress = function (e) {
        console.log('progress: ', Math.round((e.loaded * 100) / e.total))
        setProgress((e.loaded * 100) / e.total)
      }
      //Возможно не нужно читать как массив?
      reader.readAsArrayBuffer(file)
    })
  }, [])
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: 'video/mp4',
  })

  useEffect(() => {
    return () => console.log('DropZone is delete')
  }, [])

  useEffect(() => {
    if (acceptedFiles[0]) dispatch(changeFileUpload(acceptedFiles[0]))
  }, [acceptedFiles])

  return (
    <>
      <div className={styles.dropZone} {...getRootProps()}>
        <input {...getInputProps()} />
        <p className={clsx(styles.dropZone__p, 'p-3')}>
          Drag 'n' drop some files here, or click to select files
        </p>
      </div>
      {progress !== null && <ProgressBar persent={progress?.toFixed(2)} />}
    </>
  )
}

export default React.memo(DropZone)
