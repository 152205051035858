import styles from './index.module.scss'
import React, { useEffect, useRef } from 'react'

interface IInputProps {
  placeholder?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  type?: string
  pattern?: string
  ref?: any
  height?: string
  isEditable?: boolean
  autoFocus?: boolean
  id?: string
}

const Input: React.FC<IInputProps> = (props) => {
  const inputField = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputField.current && props.autoFocus) {
      inputField.current.focus()
    }
  })

  return (
    <input
      ref={inputField}
      readOnly={
        props.isEditable !== undefined || null ? !props.isEditable : false
      }
      //autoFocus={props.autoFocus ? true : false}
      className={
        props.height
          ? `input ${
              props.isEditable
                ? 'input-bordered'
                : `${styles.input__cursorInitial}`
            } w-full ${props.height}`
          : `input ${
              props.isEditable
                ? 'input-bordered'
                : `${styles.input__cursorInitial}`
            } w-full`
      }
      {...props}
    />
  )
}

export default React.memo(Input)
