import Header from '../../Feature/Header'
import { useAppSelector } from '../../Store/hooks'
import styles from './TermsOfUse.module.scss'
import clsx from 'clsx'
import React from 'react'

const TermsOfUse = () => {
  const currentTheme = useAppSelector((state) => state.common.currentTheme)

  return (
    <div data-theme={currentTheme} className={styles.termsOfUse}>
      <Header />

      <section className={clsx('container mx-auto px-4')}>
        <h1>Terms of Use for FTUE.video</h1>
        <p>
          Welcome to FTUE.video. By accessing or using our website, services,
          and tools, you agree to comply with and be bound by the following
          terms and conditions of use, which together with our Privacy Policy
          govern FTUE.video's relationship with you in relation to this website.
        </p>
      </section>

      <section className={clsx('container mx-auto px-4', styles.content)}>
        <h2>Acceptance of Terms</h2>
        <p>
          By using FTUE.video, you confirm that you accept these terms of use
          and that you agree to comply with them. If you do not agree to these
          terms, you must not use our service.
        </p>

        <h2>Changes to Terms</h2>
        <p>
          We reserve the right to update or change our Terms of Use at any time
          and without prior notice. Your continued use of the service after any
          changes indicates your acceptance of the new Terms.
        </p>

        <h2>Access and Use of the Service</h2>
        <ul>
          <li>
            FTUE.video grants you a limited, non-exclusive, non-transferable
            license to access and use the service strictly in accordance with
            these terms.
          </li>
          <li>
            You agree not to use the service for any purpose that is unlawful or
            prohibited by these Terms.
          </li>
          <li>
            You may not use the service in any manner that could damage,
            disable, overburden, or impair the service.
          </li>
        </ul>

        <h2>Intellectual Property Rights</h2>

        <ul>
          <li>
            All content included on the site, such as text, graphics, logos,
            images, as well as the compilation thereof, and any software used on
            the site, is the property of FTUE.video or its suppliers and
            protected by copyright and other laws.
          </li>
          <li>
            You agree to respect all copyright and other proprietary notices,
            labels, or other restrictions contained within any content accessed
            through the service and not to modify, copy, publish, distribute, or
            sell any such content.
          </li>
        </ul>

        <h2>User Conduct</h2>
        <ul>
          <li>
            You agree to use FTUE.video only for lawful purposes and in a way
            that does not infringe the rights of, restrict or inhibit anyone
            else's use and enjoyment of the service.
          </li>
          <li>
            Prohibited behavior includes harassing or causing distress or
            inconvenience to any other user, transmitting obscene or offensive
            content, or disrupting the normal flow of dialogue within our
            service.
          </li>
        </ul>

        <h2>Termination</h2>
        <p>
          We may terminate or suspend access to our service immediately, without
          prior notice or liability, for any reason whatsoever, including,
          without limitation, if you breach the Terms.
        </p>

        <h2>Disclaimer</h2>
        <p>
          The service and all content, products, and services provided on the
          service are provided on an "as is" and "as available" basis.
        </p>

        <h2>Governing Law</h2>
        <p>
          These terms shall be governed by and construed in accordance with the
          laws of Cyprus.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          <a className='ml-1' href='mailto:info@ftue.video'>
            info@ftue.video
          </a>
          .
        </p>
      </section>
    </div>
  )
}

export default TermsOfUse
