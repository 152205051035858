import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import EditPage from './EditPage'
import EditPageMobile from './EditPageMobile'

const EditPageCommon: React.FC = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const match: null | { params: { id: string } | null | undefined } =
    useRouteMatch('/edit/:id')

  useEffect(() => {
    const windowResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [screenWidth])
  console.log('разрешение в вотчпейджкоммон' + screenWidth)
  return (
    <div>
      {screenWidth > 426 ? (
        <EditPage id={match?.params?.id || ''} />
      ) : (
        <EditPageMobile id={match?.params?.id || ''} />
      )}
    </div>
  )
}

export default React.memo(EditPageCommon)
