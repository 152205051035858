import React from 'react'
import AddNewChapter from '../AddNewChapter'
import styles from './index.module.scss'

interface IButtonsBlock {
  videoRef: HTMLVideoElement | null
  setShowChapterModal?: (openChapterModal: boolean) => void
}
// Это должно находится явно не в Feature + если используется в одном месте, то просто там и оставить.
const ButtonsBlock: React.FC<IButtonsBlock> = ({
  setShowChapterModal,
  videoRef,
}) => {
  return (
    <div className={styles.buttonsBlock}>
      <AddNewChapter
        videoRef={videoRef}
        setShowChapterModal={setShowChapterModal}
        isChapter={false}
        isMobile={true}
      />
    </div>
  )
}

export default React.memo(ButtonsBlock)
