import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../__generated__/types'

const defaultOptions = {}
export type IMeQueryVariables = Types.Exact<{ [key: string]: never }>

export type IMeQuery = {
  __typename?: 'Query'
  me?:
    | {
        __typename?: 'User'
        plan?: string | null | undefined
        uid?: string | null | undefined
        name?: string | null | undefined
        email?: string | null | undefined
      }
    | null
    | undefined
}

export const MeDocument = gql`
  query me {
    me {
      plan
      uid
      name
      email
    }
  }
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<IMeQuery, IMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IMeQuery, IMeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMeQuery, IMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IMeQuery, IMeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<IMeQuery, IMeQueryVariables>
