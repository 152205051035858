import styles from './ProductHuntBanner.module.scss'

const ProductHuntBanner = () => {
  return (
    <div className={styles.wrapper}>
      {/*<a*/}
      {/*  href='https://www.producthunt.com/posts/appstorespy-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-appstorespy&#0045;2'*/}
      {/*  target='_blank'*/}
      {/*  rel='noreferrer'*/}
      {/*>*/}
      {/*  <img*/}
      {/*    src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=415697&theme=light&period=daily'*/}
      {/*    alt='AppstoreSpy - Find&#0032;insights&#0032;about&#0032;apps&#0044;&#0032;niches&#0032;and&#0032;keywords | Product Hunt'*/}
      {/*    width={200}*/}
      {/*  />*/}
      {/*</a>*/}
      <a
        href='https://www.producthunt.com/posts/ftue-video?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ftue&#0045;video'
        target='_blank'
        rel='noreferrer'
      >
        <img
          src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=450985&theme=light'
          alt='FTUE&#0046;Video - Video&#0032;sharing&#0032;made&#0032;simple | Product Hunt'
          // style='width: 250px; height: 54px;'
          width={200}
        />
      </a>
    </div>
  )
}

export default ProductHuntBanner
