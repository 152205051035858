import checkIntervalBlur from '../../../GlobalFunctions/checkIntervalBlur'
import createAndPushArray from '../../../GlobalFunctions/createAndPushArray'
import { deleteBlur } from '../../../Store/Video/actions'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import DeleteButton from '../../DeleteButton'
import BlurScene from './BlurScene'
import styles from './index.module.scss'
import React, { useEffect, useState } from 'react'

interface NavigationBlurProps {
  id: number
  startTime: number
  endTime: number
}
// Вынести логику в кастомный хук
const NavigationBlur: React.FC<NavigationBlurProps> = ({
  id,
  startTime,
  endTime,
}) => {
  const dispatch = useAppDispatch()

  const durationVideo = useAppSelector((state) => state.video.durationTime)

  const [arr, setArr] = useState(() =>
    createAndPushArray(200, durationVideo / 200)
  )

  useEffect(
    () => setArr(createAndPushArray(200, durationVideo / 200)),
    [durationVideo]
  )

  const [first, setFirst] = useState<number>(0)
  const [second, setSecond] = useState<number>(0)

  useEffect(() => {
    setFirst(arr.findIndex((el) => checkIntervalBlur(el, startTime, endTime)))
    setSecond(
      200 -
        [...arr]
          .reverse()
          .findIndex((el) => checkIntervalBlur(el, startTime, endTime))
    )
  }, [startTime, endTime, arr])

  console.log(first, second)

  return (
    <div
      className={styles.navigationBlur}
      onClick={() => console.log(id, startTime, endTime)}
    >
      <DeleteButton onClick={() => dispatch(deleteBlur(id))} />
      <>
        {arr.map((el, index) => (
          <BlurScene
            id={id}
            key={index}
            time={el}
            isActive={checkIntervalBlur(el, startTime, endTime)}
            first={index === first ? first : ''}
            second={index + 1 === second ? second : ''}
          />
        ))}
      </>
    </div>
  )
}

export default React.memo(NavigationBlur)
