import { deleteChapter } from '../../../Store/Chapters/actions'
import { IChapterMy } from '../../../Store/Chapters/types'
import { changeScreenSize } from '../../../Store/Screens/actions'
import { changedTime } from '../../../Store/Video/actions'
import { useAppDispatch, useAppSelector } from '../../../Store/hooks'
import DeleteButton from '../../DeleteButton'
import styles from './index.module.scss'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface IChapterSaved extends IChapterMy {
  setShowChapterModal?: (openChapterModal: boolean) => void
  aspectVideo: number
  isEditable?: boolean
  chapterKey?: string
}

const ChapterSaved: React.FC<IChapterSaved> = ({
  name,
  imgURL,
  time,
  url,
  id,
  chapterKey,
  aspectVideo,
  isEditable,
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const watchPageView = useAppSelector((state) => state.common.watchPageView)

  useEffect(() => {
    if (aspectVideo > 1) {
      dispatch(changeScreenSize(50))
    }
  }, [aspectVideo])

  return (
    <div
      // className={
      //   aspectVideo && aspectVideo < 1
      //     ? (location.pathname.match('watch') ? `${styles.chapter} ${styles.chapter_watch}` : styles.chapter)
      //     : `${styles.chapter} ${styles.chapter_h}`
      // }
      className={
        location.pathname.match('watch')
          ? aspectVideo < 1
            ? `${styles.chapter} ${styles.chapter_watchV}`
            : watchPageView !== 'second'
            ? `${styles.chapter} ${styles.chapter_watchH}`
            : `${styles.chapter} ${styles.chapter_watchHFull}`
          : styles.chapter
      }
      //style={aspectVideo < 1 ? { width: `20%` } : { width: `50%` }}
    >
      <div className={styles.chapter__imageContainer}>
        <img
          onClick={() => {
            dispatch(changedTime({ time: Number(time) }))
          }}
          className={styles.chapter__image}
          src={url ? url : imgURL}
          alt='testImage'
        />
      </div>
      <p className={`${styles.chapter__p} text-gray-900 mt-4`}>
        {name}
        {isEditable && (
          <DeleteButton
            onClick={() => chapterKey && dispatch(deleteChapter(chapterKey))}
          />
        )}
      </p>
    </div>
  )
}

export default React.memo(ChapterSaved)
