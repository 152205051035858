import appLogoD from '../../../../images/appB.png'
import appLogoL from '../../../../images/appW.png'
import styles from '../index.module.scss'
import React from 'react'

interface IAppInfoProps {
  dataVideo: any
  currentTheme: string
}

const AppInfo: React.FC<IAppInfoProps> = ({ dataVideo, currentTheme }) => {
  return (
    <div className={styles.appInfo}>
      {/*<a target='_blank' href={dataVideo?.website || '/'}>*/}
      <div className={`${styles.appNameContainer}`}>
        <div className={styles.appNameContainer__imgContainer}>
          <img
            className={styles.appNameContainer__img}
            src={
              dataVideo?.icon ||
              (currentTheme === 'light' ? appLogoL : appLogoD)
            }
            alt={'logo app'}
          />
        </div>
        <h2 className={`${styles.appNameContainer__title} text-base-content`}>
          {dataVideo?.name || 'Name not found'}
        </h2>
      </div>
      {/*</a>*/}
      <div className={`${styles.appTextContainer} text-base-content`}>
        {dataVideo?.description || 'Not found description'}
      </div>
    </div>
  )
}

export default AppInfo
