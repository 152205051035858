import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../__generated__/types'

const defaultOptions = {}
export type IVideoDataFragment = {
  __typename?: 'Video'
  id?: string | null | undefined
  name: string
  state: Types.IVideosVideoStateChoices
  url?: string | null | undefined
  user?:
    | {
        __typename?: 'User'
        email?: string | null | undefined
        name?: string | null | undefined
        plan?: string | null | undefined
        uid?: string | null | undefined
      }
    | null
    | undefined
  chapters?:
    | Array<
        | {
            __typename?: 'Chapter'
            id: string
            time: string
            name: string
            url?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type IUpdateVideoMutationVariables = Types.Exact<{
  file?: Types.Maybe<Types.Scalars['Upload']>
  video?: Types.Maybe<Types.IVideoInput>
}>

export type IUpdateVideoMutation = {
  __typename?: 'Mutation'
  updateVideo?:
    | {
        __typename?: 'BlurVideoSuccess'
        message?: string | null | undefined
        video?:
          | {
              __typename?: 'Video'
              id?: string | null | undefined
              name: string
              state: Types.IVideosVideoStateChoices
              url?: string | null | undefined
              user?:
                | {
                    __typename?: 'User'
                    email?: string | null | undefined
                    name?: string | null | undefined
                    plan?: string | null | undefined
                    uid?: string | null | undefined
                  }
                | null
                | undefined
              chapters?:
                | Array<
                    | {
                        __typename?: 'Chapter'
                        id: string
                        time: string
                        name: string
                        url?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | { __typename?: 'NoAccessError'; message?: string | null | undefined }
    | { __typename?: 'TooLongName'; message?: string | null | undefined }
    | {
        __typename?: 'UpdateVideoProcessing'
        video?:
          | {
              __typename?: 'Video'
              id?: string | null | undefined
              name: string
              state: Types.IVideosVideoStateChoices
              url?: string | null | undefined
              user?:
                | {
                    __typename?: 'User'
                    email?: string | null | undefined
                    name?: string | null | undefined
                    plan?: string | null | undefined
                    uid?: string | null | undefined
                  }
                | null
                | undefined
              chapters?:
                | Array<
                    | {
                        __typename?: 'Chapter'
                        id: string
                        time: string
                        name: string
                        url?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | {
        __typename?: 'UpdateVideoSuccess'
        message?: string | null | undefined
        video?:
          | {
              __typename?: 'Video'
              id?: string | null | undefined
              name: string
              state: Types.IVideosVideoStateChoices
              url?: string | null | undefined
              user?:
                | {
                    __typename?: 'User'
                    email?: string | null | undefined
                    name?: string | null | undefined
                    plan?: string | null | undefined
                    uid?: string | null | undefined
                  }
                | null
                | undefined
              chapters?:
                | Array<
                    | {
                        __typename?: 'Chapter'
                        id: string
                        time: string
                        name: string
                        url?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | {
        __typename?: 'UserNotAuthenticated'
        message?: string | null | undefined
      }
    | {
        __typename?: 'VideoAlreadyUploaded'
        message?: string | null | undefined
      }
    | { __typename?: 'VideoNotFound'; message?: string | null | undefined }
    | {
        __typename?: 'VideoUnderProcessing'
        message?: string | null | undefined
      }
    | { __typename?: 'WrongVideoInput'; message?: string | null | undefined }
    | null
    | undefined
}

export const VideoDataFragmentDoc = gql`
  fragment VideoData on Video {
    id
    name
    state
    user {
      email
      name
      plan
      uid
    }
    chapters {
      id
      time
      name
      url
    }
    url
  }
`
export const UpdateVideoDocument = gql`
  mutation updateVideo($file: Upload, $video: VideoInput) {
    updateVideo(file: $file, video: $video) {
      ... on VideoUnderProcessing {
        message
      }
      ... on VideoNotFound {
        message
      }
      ... on UpdateVideoSuccess {
        video {
          ...VideoData
        }
        message
      }
      ... on WrongVideoInput {
        message
      }
      ... on UpdateVideoProcessing {
        video {
          ...VideoData
        }
      }
      ... on UserNotAuthenticated {
        message
      }
      ... on NoAccessError {
        message
      }
      ... on TooLongName {
        message
      }
      ... on BlurVideoSuccess {
        video {
          ...VideoData
        }
        message
      }
      ... on VideoAlreadyUploaded {
        message
      }
    }
  }
  ${VideoDataFragmentDoc}
`
export type IUpdateVideoMutationFn = Apollo.MutationFunction<
  IUpdateVideoMutation,
  IUpdateVideoMutationVariables
>

/**
 * __useUpdateVideoMutation__
 *
 * To run a mutation, you first call `useUpdateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoMutation, { data, loading, error }] = useUpdateVideoMutation({
 *   variables: {
 *      file: // value for 'file'
 *      video: // value for 'video'
 *   },
 * });
 */
export function useUpdateVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateVideoMutation,
    IUpdateVideoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IUpdateVideoMutation,
    IUpdateVideoMutationVariables
  >(UpdateVideoDocument, options)
}
export type UpdateVideoMutationHookResult = ReturnType<
  typeof useUpdateVideoMutation
>
export type UpdateVideoMutationResult =
  Apollo.MutationResult<IUpdateVideoMutation>
export type UpdateVideoMutationOptions = Apollo.BaseMutationOptions<
  IUpdateVideoMutation,
  IUpdateVideoMutationVariables
>
