import React, { useMemo } from 'react'
import cutSceneOutVideo from '../../GlobalFunctions/cutSceneOutVideo'
import timeNumberToString from '../../GlobalFunctions/timeNumberToString'
import useInput from '../../Hooks/useInput'
import { addChapter } from '../../Store/Chapters/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import Button from '../../UI/Button'
import Input from '../../UI/Input'
import { IVideosChapterStateChoices } from '../../__generated__/types'
import chapterStyles from './parentChapters.module.scss'
import regularStyles from './regular.module.scss'

interface IAddNewChapter {
  videoRef: HTMLVideoElement | null
  setShowChapterModal?: (openChapterModal: boolean) => void
  isChapter: boolean
  isMobile?: boolean
}

const AddNewChapter: React.FC<IAddNewChapter> = ({
  setShowChapterModal,
  videoRef,
  isChapter,
  isMobile,
}) => {
  const currentTime = useAppSelector((state) => state.video.currentTime)

  const chapters = useAppSelector((state) => state.chapters.chapters)

  const isActive: boolean = useMemo(
    () =>
      chapters.findIndex(
        (el) => Number(el?.time) === Number(currentTime.toFixed(2))
      ) === -1,
    [currentTime, chapters]
  )

  const dispatch = useAppDispatch()

  const input = useInput('', 'Type chapter name', true, 'name', undefined)

  return (
    <>
      {isActive && (
        <form
          onSubmit={(e) => e.preventDefault()}
          className={
            isChapter ? chapterStyles.newChapter : regularStyles.newChapter
          }
        >
          {!setShowChapterModal && (
            <div
              className={
                isChapter
                  ? chapterStyles.newChapter__inputContainer
                  : regularStyles.newChapter__inputContainer
              }
            >
              <p className={regularStyles.newChapter__p}>
                {timeNumberToString(currentTime)}
              </p>
              <div className={regularStyles.newChapter__buttonsInputContainer}>
                <Input
                  {...input.default}
                  height='input-sm'
                  isEditable={true}
                  autoFocus={false}
                />
              </div>
            </div>
          )}

          <div className={regularStyles.newChapter__buttonsContainer}>
            <Button
              isMobile={isMobile}
              isActive={isActive}
              isHeight='btn-sm'
              onClick={() => {
                //<--
                // Надеюсь всем понятно что это говно код, и надо разбираться как его упростить, с моего взгляда это логика
                // разная для различного применения этого компонента. Вынести функцию наверн и сделать норм переменные 
                // типа "Одна фича использования", "Вторая фича использования".
                setShowChapterModal && setShowChapterModal(true)
                !setShowChapterModal &&
                  isActive &&
                  // -->
                  dispatch(
                    addChapter({
                      state: IVideosChapterStateChoices.New,
                      id: '',
                      name: input.inputValue,
                      imgURL: cutSceneOutVideo(videoRef),
                      time: currentTime.toFixed(2),
                      key: String(Date.now()),
                    })
                  )
                input.clear()
              }}
              title='Add'
            />
          </div>
        </form>
      )}
    </>
  )
}

export default AddNewChapter
