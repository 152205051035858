import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'
import * as Types from '../../../__generated__/types'

const defaultOptions = {}
export type ITokenAuthMutationVariables = Types.Exact<{
  email: Types.Scalars['String']
  password: Types.Scalars['String']
}>

export type ITokenAuthMutation = {
  __typename?: 'Mutation'
  tokenAuth?:
    | {
        __typename?: 'ObtainJSONWebToken'
        payload: any
        refreshExpiresIn: number
        token: string
      }
    | null
    | undefined
}

export const TokenAuthDocument = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      payload
      refreshExpiresIn
      token
    }
  }
`
export type ITokenAuthMutationFn = Apollo.MutationFunction<
  ITokenAuthMutation,
  ITokenAuthMutationVariables
>

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ITokenAuthMutation,
    ITokenAuthMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ITokenAuthMutation, ITokenAuthMutationVariables>(
    TokenAuthDocument,
    options
  )
}
export type TokenAuthMutationHookResult = ReturnType<
  typeof useTokenAuthMutation
>
export type TokenAuthMutationResult = Apollo.MutationResult<ITokenAuthMutation>
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  ITokenAuthMutation,
  ITokenAuthMutationVariables
>
