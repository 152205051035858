import { RefObject, useEffect } from 'react'

const useMouseWheelScroll = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleMouseScroll = (event: MouseEvent) => {
      event.preventDefault()
      const startLeft = ref.current?.scrollLeft || 0
      //@ts-ignore
      ref.current?.scrollTo({ left: startLeft + event.deltaY * 2 })
    }
    //@ts-ignore
    ref.current?.addEventListener('mousewheel', handleMouseScroll)

    return () => {
      //@ts-ignore
      ref.current?.removeEventListener('mousewheel', handleMouseScroll)
    }
  }, [ref])
}

export default useMouseWheelScroll
