import React, { RefObject, useEffect } from 'react'
import BlurRectangle from '../../../../Feature/BlurRectangle'
import ButtonsBlock from '../../../../Feature/ButtonsBlock'
import NavigationAccurate from '../../../../Feature/NavigationAccurate'
import NavigationBlursContainer from '../../../../Feature/NavigationBlursContainer'
import NavigationInnacurate from '../../../../Feature/NavigationInnacurate'
import Ruler from '../../../../Feature/Ruler'
import VideoElement from '../../../../Feature/VideoContainer/VideoElement'
import { useAppDispatch } from '../../../../Store/hooks'
import { changeCurrentTime } from '../../../../Store/Video/actions'
import Button from '../../../../UI/Button/Button'
import styles from './index.module.scss'

interface IFirstMainScreenProps {
  videoPath: string
  SetShowSecondPage: (SetShowSecondPage: boolean) => void
  setShowChapterModal: (openChapterModal: boolean) => void
  videoRef: RefObject<HTMLVideoElement>
  aspectVideo: number
}

const FirstMainScreen: React.FC<IFirstMainScreenProps> = ({
  videoPath,
  SetShowSecondPage,
  setShowChapterModal,
  videoRef,
  aspectVideo,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    videoRef?.current?.load()
  }, [videoPath])

  useEffect(() => {
    if (videoPath) {
      const video: any = document.getElementById(videoPath)

      const changeTimeVideoInterval = setInterval(() => {
        dispatch(changeCurrentTime(video.currentTime, video.paused))
      }, 250)
      return () => clearInterval(changeTimeVideoInterval)
    }
  }, [videoPath])

  return (
    <div className={styles.firstMainScreen}>
      <div className={styles.smallButtonContainer}>
        {/*<SmallButton onClick={() => SetShowSecondPage(true)} title='Chapters' />*/}
        <Button
          onClick={() => SetShowSecondPage(true)}
          title='Chapters'
          isHeight='btn-sm'
        />
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.video}>
          <VideoElement
            aspectVideo={aspectVideo}
            videoPath={videoPath}
            videoRef={videoRef}
            isMobile={true}
          />
          <BlurRectangle videoRef={videoRef} />
        </div>
        <ButtonsBlock
          videoRef={videoRef.current}
          setShowChapterModal={setShowChapterModal}
        />
      </div>
      <div className={styles.navigation}>
        <NavigationAccurate isMobile={true} />
        <Ruler />
        <NavigationBlursContainer />
        <NavigationInnacurate isMobile={true} videoPath={videoPath} />
      </div>
    </div>
  )
}

export default React.memo(FirstMainScreen)
