import React, { useEffect, useRef, useState } from 'react'
import ModalButton from '../../../Feature/ModalButton'
import UpdateVideoButton from '../../../Feature/UpdateVideoButton'
import { useVideoQuery } from '../../../GraphqlQuery/__generated__/video'
import { clearChapters } from '../../../Store/Chapters/actions'
import { useAppDispatch } from '../../../Store/hooks'
import { deleteMobileScreens } from '../../../Store/Mobile/actions'
import { clearScreens } from '../../../Store/Screens/actions'
import {
  changeURLVideo,
  clearVideo,
  durationTime,
} from '../../../Store/Video/actions'
import ChapterModal from './ChapterModal'
import FirstMainScreen from './FirstMainScreen'
import styles from './index.module.scss'
import { Video_Query } from './interfaces'
import SecondMainScreen from './SecondMainScreen'

interface EditPageMobileProps {
  id: string
}

const EditPageMobile: React.FC<EditPageMobileProps> = ({ id }) => {
  const dispatch = useAppDispatch()

  const [skipQuery, setSkipQuery] = useState<boolean>(false)
  const [videoPath, setVideoPath] = useState<string>('')
  const [videoName, setVideoName] = useState<string>('')
  const [showSecondPage, SetShowSecondPage] = useState<boolean>(false)
  const [showChapterModal, setShowChapterModal] = useState<boolean>(false)
  const [videoData, setVideoData] = useState<Video_Query | null>(null)
  const [aspectVideo, setAspectVideo] = useState<number>(1)

  const videoRef = useRef<HTMLVideoElement>(null)

  const { data, loading, error } = useVideoQuery({
    skip: skipQuery,
    variables: { id },
  })

  const loadRefVideo = () => {
    if (videoRef.current !== null) {
      videoRef.current.load()
      console.log('видео есть')
    } else {
      console.log('видео нет')
    }
    console.log('push')
  }

  // useEffect(() => {
  //   if (!loading && data) {
  //     setVideoData(data.video);
  //     setSkipQuery(true);
  //     console.log(data);
  //   }
  // }, [data]);

  useEffect(() => {
    dispatch(clearVideo())
    dispatch(clearChapters())
    dispatch(clearScreens())
    dispatch(deleteMobileScreens())
    dispatch(changeURLVideo(videoPath))
  }, [videoPath])

  useEffect(() => {
    if (id && videoData) {
      console.log(videoData)
      setVideoPath(videoData?.url || '')
    }
  }, [videoData])

  useEffect(() => {
    if (videoRef.current) {
      loadRefVideo()
      const videoDOM = videoRef.current
      videoDOM.onloadeddata = () => console.log('loadeddata')
      videoDOM.onloadedmetadata = () => {
        dispatch(durationTime(videoDOM.duration))
        console.log('loadmetadata')
        console.log('считаем аспект')
        setAspectVideo(videoDOM.videoWidth / videoDOM.videoHeight)
      }
    }
    console.log('aspect video' + aspectVideo)
  }, [videoPath, videoRef.current])

  // if (error) return <>Something went wrong: {error.message}</>;

  return (
    <>
      <div className={styles.bg}>
        {showChapterModal && (
          <ChapterModal
            childVideoRef={videoRef}
            setShowChapterModal={setShowChapterModal}
          />
        )}

        <div className={styles.watchPagHeader}>
          <div className={styles.watchPagHeader__uploadButton}>
            <ModalButton
              loadRefVideo={loadRefVideo}
              setVideoPath={setVideoPath}
              setVideoName={setVideoName}
            />
          </div>
          <p className={styles.watchPagHeader__title}>{videoName}</p>
        </div>
        {showSecondPage ? (
          <SecondMainScreen SetShowSecondPage={SetShowSecondPage} />
        ) : (
          <>
            <FirstMainScreen
              aspectVideo={aspectVideo}
              setShowChapterModal={setShowChapterModal}
              SetShowSecondPage={SetShowSecondPage}
              videoPath={videoPath}
              videoRef={videoRef}
            />
            <UpdateVideoButton />
          </>
        )}
      </div>
    </>
  )
}

export default EditPageMobile
