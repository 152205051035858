import { useApolloClient } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonAuth from '../../Feature/ButtonAuth'
import useInput from '../../Hooks/useInput'
import { changeCurrentTheme } from '../../Store/Common/actions'
import { useAppDispatch, useAppSelector } from '../../Store/hooks'
import { changeUserIsAuth, userToken } from '../../Store/User/actions'
import Button from '../../UI/Button'
import Input from '../../UI/Input'
import styles from './index.module.scss'
import { useTokenAuthMutation } from './__generated__/tokenAuth'

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch()

  const history = useHistory()

  const prevURLFromLogin = useAppSelector(
    (state) => state.common.prevURLFromLogin
  )

  const funkFromLogin = useAppSelector((state) => state.common.funcFromLogin)

  const [loading, setLoading] = useState<boolean>(false)

  const client = useApolloClient()

  const currentTheme = useAppSelector((state) => state.common.currentTheme)
  //const currentTheme = localStorage.getItem('theme');

  useEffect(() => {
    const storageTheme = localStorage.getItem('theme')
    if (storageTheme) {
      dispatch(changeCurrentTheme(storageTheme))
    }
  }, [])

  console.log(prevURLFromLogin)

  const [tokenAuth, { error }] = useTokenAuthMutation({
    onCompleted: (data) => {
      if (data.tokenAuth?.token) {
        localStorage.setItem('token', data.tokenAuth.token)
        dispatch(userToken(data.tokenAuth.token))
        dispatch(changeUserIsAuth(true))
      }
      client.resetStore()
      if (funkFromLogin) {
        setLoading(true)
        funkFromLogin()
      } else history.push(prevURLFromLogin || '/')
    },
  })

  const submitForm = (email: string, password: string) => {
    console.log('submit:', email, password)
    tokenAuth({
      variables: {
        email,
        password,
      },
    }).catch((e) => {
      console.log(e)
    })
  }

  const login = useInput('', 'Введите login', true, 'email')
  const password = useInput('', 'Введите password', true, 'password')

  return (
    <div data-theme={currentTheme} className={styles.loginPage}>
      <h3>Sign In</h3>
      <form
        className={error ? `${styles.loginForm} ` : styles.loginForm}
        onSubmit={(e) => {
          e.preventDefault()
          submitForm(login.inputValue, password.inputValue)
        }}
      >
        <ButtonAuth text='Authorise with Google' />
        <label className='label'>email: </label>
        <Input {...login.default} />
        <label className='label'>password:</label>
        <Input {...password.default} />
        <div className={styles.loginForm__buttonSpacer}></div>
        <Button isError={error} title='Sign in' />
        {error && (
          <p className={styles.loginForm__errorText}>{error.message}</p>
        )}
      </form>
      {loading && <p>Pending video...</p>}
    </div>
  )
}

export default LoginPage
