import { IScreen } from '../Screens/types'
import { IAddMobileScreens, IDeleteMobileScreens, MobileType } from './types'

export const addMobileScreens = (screens: IScreen[]): IAddMobileScreens => ({
  type: MobileType.ADD_MOBILE_SCREENS,
  screens,
})

export const deleteMobileScreens = (): IDeleteMobileScreens => ({
  type: MobileType.DELETE_MOBILE_SCREENS,
})
